import { CLEAR_EMPLOYEE_LIST, GET_EMPLOYEE_LIST } from "../../../enums/MyDivision/EmployeeList/EmployeeListTypes";

export const getEmployeeListAction = (list) => {
    return {
        type: GET_EMPLOYEE_LIST,
        payload: list,
    };
};
export const clearEmployeeListAction = () => {
    return {
        type: CLEAR_EMPLOYEE_LIST,
        payload: null,
    };
};

import { $api } from "../../../../services/request.config";
import { getMaintenanceNotificationAction } from "../getMaintenanceNotification.action";

export const getMaintenanceNotificationThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v1/maintenance/messages");
        console.log("response: getMaintenanceNotificationThunkAction ", response);
        if (response.status === 200) {
            if (response?.data?.message) {
                response.data.message && dispatch(getMaintenanceNotificationAction(response.data.message));
                localStorage.setItem("message", response.data.message);
            }
        }

    } catch (e) {
        // throw new Error("Ошибка получения данных");
    }
};

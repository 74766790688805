import triangle from "./Triangle.svg";

import { isTerminal } from "../../../../constants";

import React, { memo, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useSearchParams } from "react-router-dom";

isTerminal ? import("../DropdownMenu/DropdownMenuTerminal.scss") : import("../DropdownMenu/DropdownMenu.scss");

const DropdownMenuTest = ({
    styleName,
    label,
    listOfOptions,
    selectOptionClick,
    stateProperty,
    currentRequest,
    initialMenuMessage,
    isValidKey,
    optionsLoader,
    dropdownDescription,
    helper,
}) => {
    const dropdownRef = useRef();
    const [searchParams] = useSearchParams();
    const queryString = searchParams.get("id");

    const [isMenuActive, setIsMenuActive] = useState(false);
    const [selectedOption, setSelectedOption] = useState(
        initialMenuMessage ? initialMenuMessage : null,
    );

    function toggleMenuHandler(e) {
        setIsMenuActive(!isMenuActive);
    }

    function clickOutside(e) {
        if (!dropdownRef.current.contains(e.target)) {
            setIsMenuActive(false);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", clickOutside);
        return () => document.removeEventListener("mousedown", clickOutside);
    }, []);

    return (
        <>
            <div className={`dropdown-${styleName}-container`}>
                <label
                    className={`dropdown-${styleName}__label`}
                    htmlFor={`${styleName}-selected`}
                >
                    {label}
                </label>
                <div
                    style={{ zIndex: "10" }}
                    className={`dropdown-${styleName}`}
                    ref={dropdownRef}
                >
                    {/*CONTAINER*/}
                    <div
                        className={`dropdown-${styleName}__select-${
                            isMenuActive ? "active" : "hidden"
                        }
                            ${
        isValidKey === false
            ? `dropdown-${styleName}__select-danger`
            : ""
        }`}
                        onClick={toggleMenuHandler}
                        // name={`${styleName}-selected`}
                    >
                        <div
                            className={`dropdown-${styleName}__select__selected`}
                        >
                            {
                                // currentRequest && currentRequest.uid === queryString && requestState[`${stateProperty}`]
                                currentRequest &&
                                currentRequest.uid === queryString
                                    ? listOfOptions &&
                                    listOfOptions.filter(
                                        (e) =>
                                            e.id ===
                                            currentRequest[
                                                `${stateProperty}`
                                            ],
                                    ).length &&
                                    listOfOptions.find(
                                        (e) =>
                                            e.id ===
                                            currentRequest[
                                                `${stateProperty}`
                                            ],
                                    ).name
                                        ? listOfOptions.find(
                                            (e) =>
                                                e.id ===
                                                currentRequest[
                                                    `${stateProperty}`
                                                ],
                                        ).name
                                        : ""
                                    : // ? listOfOptions && listOfOptions.filter(e => e.id === requestState[`${stateProperty}`]).length &&
                                    //     listOfOptions.find(e => e.id === requestState[`${stateProperty}`]).name
                                    // : selectedOption
                                    selectedOption
                            }
                        </div>
                        <div className={`dropdown-${styleName}__select__arrow`}>
                            <img
                                className={`dropdown-${styleName}__select__arrow-${
                                    isMenuActive ? "up" : "down"
                                }`}
                                src={triangle}
                                alt="arrow"
                            />
                        </div>
                    </div>
                    {/*DROPDOWN*/}
                    {optionsLoader ? (
                        <ul
                            className={`dropdown-${styleName}__options-${
                                isMenuActive ? "active" : "hidden"
                            }`}
                        >
                            <li
                                className={`dropdown-${styleName}__empty-item`}
                            ></li>
                            <li
                                className={`dropdown-${styleName}__empty-item`}
                            ></li>
                            <li
                                className={`dropdown-${styleName}__empty-item`}
                            ></li>
                            <li
                                className={`dropdown-${styleName}__empty-item`}
                            ></li>
                        </ul>
                    ) : (
                        <ul
                            className={`dropdown-${styleName}__options-${
                                isMenuActive ? "active" : "hidden"
                            }`}
                        >
                            {listOfOptions &&
                                listOfOptions.map((element) => {
                                    return (
                                        // DROPDOWN ITEM
                                        <li
                                            key={uuidv4()}
                                            id={element.id}
                                            className={`dropdown-${styleName}__item`}
                                            onClick={(event) => {
                                                setSelectedOption(element.name);
                                                setIsMenuActive(false);
                                                selectOptionClick &&
                                                selectOptionClick(
                                                    event,
                                                    element,
                                                    stateProperty,
                                                );
                                            }}
                                        >
                                            <div
                                                className={`dropdown-${styleName}__item__text`}
                                            >
                                                {element.name}
                                            </div>
                                        </li>
                                    );
                                })}
                        </ul>
                    )}
                </div>
                {dropdownDescription ? (
                    <div className={`dropdown-${styleName}__description`}>
                        {dropdownDescription}
                    </div>
                ) : null}
                {/*typeof isValidKey !== 'undefined'*/}
                {isValidKey === false ? (
                    <div className={`dropdown-${styleName}__item__underline`}>
                        Обязательное поле
                    </div>
                ) : null}
                {helper && (
                    <p className={`dropdown-${styleName}-comment__underline`}>
                        {helper}
                    </p>
                )}
            </div>
        </>
    );
};

export default memo(DropdownMenuTest);

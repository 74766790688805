import { CLEAR_MAINTENANCENOTIFICATION, GET_MAINTENANCENOTIFICATION } from "../../../enums/Common/Common";

export const getMaintenanceNotificationAction = (data) => {
    return {
        type: GET_MAINTENANCENOTIFICATION,
        payload: data,
    };
};
export const clearMaintenanceNotificationAction = (data) => {
    return {
        type: CLEAR_MAINTENANCENOTIFICATION,

    };
};

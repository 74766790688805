import triangle from "../DropdownMenu/Triangle.svg";
import {
    changeCheckedStateInDropDownWith_checkbox,
} from "../../../../redux/actions/Requests/currentRequestControls/thunk/changeCheckedStateInDropDownWith_checkbox";

import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import "./DropdownMenuWithCheckbox.scss";
import { useSearchParams } from "react-router-dom";


function DropdownMenuWithCheckbox(
    {
        styleName,
        label,
        listOfOptions,
        selectOptionClick,
        stateProperty,
        currentRequest,
        isValidKey,
        optionsLoader,
        option,
    },
) {

    const CUSTOM_INDEX = 7;

    const [searchParams] = useSearchParams();
    const queryString = searchParams.get("id");

    const dropdownRef = useRef();

    const dispatch = useDispatch();

    const [isMenuActive, setIsMenuActive] = useState(false);
    const [isFirstLoad, setFirstLoad] = useState(true);
    const [input, setInput] = useState("");

    function toggleMenuHandler() {
        setIsMenuActive(!isMenuActive);
    }

    function clickOutside(e) {
        if (!dropdownRef.current.contains(e.target)) {
            setIsMenuActive(false);
        }
    }

    function changeCheckedState(element) {
        dispatch(changeCheckedStateInDropDownWith_checkbox(element));
    }

    function checkBoxHandler(event, element, stateProperty) {
        const temp = element.filter((el) => el.checked === true).map((e) => {
            if (e.id === CUSTOM_INDEX + 1) {
                return { ...e, name: input && input };
            } else {
                return e;
            }
        });
        selectOptionClick && selectOptionClick(event, temp, stateProperty);
        setIsMenuActive(false);
    }

    useEffect(() => {
        document.addEventListener("mousedown", clickOutside);
        return () => document.removeEventListener("mousedown", clickOutside);
    }, []);

    useEffect(() => {
        if (!isMenuActive || !isFirstLoad || !currentRequest[stateProperty]) return;

        listOfOptions && listOfOptions.map((element) => (
            currentRequest[stateProperty].find((item) => item.id === element.id)
            && changeCheckedState(element)
        ));
        setInput(currentRequest[stateProperty].find((item) => item.id === CUSTOM_INDEX + 1)?.name);

        setFirstLoad(false);
    }, [isMenuActive]);

    return (
        <>
            <div className={`dropdown-${styleName}-container`}>
                <label
                    className={`dropdown-${styleName}__label`}
                    htmlFor={`${styleName}-selected`}>
                    {label}
                </label>
                <div
                    className={`dropdown-${styleName}`}
                    ref={dropdownRef}>
                    {/*CONTAINER*/}
                    <div
                        className={`dropdown-${styleName}__select-${isMenuActive ? "active" : "hidden"}
                            ${isValidKey === false ? `dropdown-${styleName}__select-danger` : ""}`}
                        onClick={toggleMenuHandler}
                        /* name={`${styleName}-selected`}*/
                    >
                        <div className={`dropdown-${styleName}__select__selected`}>
                            {
                                currentRequest && currentRequest.uid === queryString
                                    ? currentRequest && currentRequest[`${option}`]
                                        ? currentRequest[`${option}`]
                                            .map(el => el.name)
                                            .join(" ,")
                                        : ""
                                    : listOfOptions
                                        ? listOfOptions
                                            .filter((el) => el.checked === true)
                                            .map(el => el.name).join(" ,")
                                        : " "
                            }
                        </div>
                        <div className={`dropdown-${styleName}__select__arrow`}>
                            <img
                                className={`dropdown-${styleName}__select__arrow-${isMenuActive ? "up" : "down"}`}
                                src={triangle}
                                alt="arrow" />
                        </div>
                    </div>
                    {/*DROPDOWN*/}
                    {optionsLoader
                        ? <ul className={`dropdown-${styleName}__options-${isMenuActive ? "active" : "hidden"}`}>
                            <li className={`dropdown-${styleName}__empty-item`}></li>
                            <li className={`dropdown-${styleName}__empty-item`}></li>
                            <li className={`dropdown-${styleName}__empty-item`}></li>
                            <li className={`dropdown-${styleName}__empty-item`}></li>
                        </ul>
                        : <>
                            <ul className={`dropdown-${styleName}__options-${isMenuActive ? "active" : "hidden"}`}>
                                {listOfOptions && listOfOptions.map((element, index) => {
                                    return (
                                        // DROPDOWN ITEM
                                        <>
                                            <li
                                                key={uuidv4()}
                                                id={element.id}
                                                className={`dropdown-${styleName}__item`}
                                                onClick={() => {
                                                    changeCheckedState(element);
                                                }}
                                            >
                                                <input
                                                    className={`dropdown-${styleName}__item__checkbox`}
                                                    type={"checkbox"}
                                                    id={`custom-checkbox-${index}`}
                                                    name={element}
                                                    value={element}
                                                    checked={element.checked}
                                                    onChange={() => {
                                                        changeCheckedState(element);
                                                    }}

                                                />
                                                <div className={`dropdown-${styleName}__item__text`}>
                                                    {element.name}
                                                </div>
                                            </li>
                                        </>
                                    );
                                })}
                                {listOfOptions && listOfOptions[CUSTOM_INDEX].checked === true &&
                                    (
                                        <input
                                            onChange={event => setInput(event.target.value)}
                                            value={input && input}
                                            className={`dropdown-${styleName}__comment`}
                                            placeholder={"Укажите свою причину"}
                                            maxLength={50}
                                            type="text" />
                                    )
                                }
                                <div className={`dropdown-${styleName}__buttons`}>
                                    <div onClick={() => setIsMenuActive(false)}>отмена</div>
                                    <div onClick={(event) => {
                                        checkBoxHandler(event, listOfOptions, stateProperty);
                                    }}>выбрать
                                    </div>
                                </div>

                            </ul>
                        </>
                    }
                </div>
                {isValidKey === false
                    ? <div className={`dropdown-${styleName}__item__underline`}>
                        Обязательное поле
                    </div>
                    : null}
            </div>
        </>
    );
}

export default DropdownMenuWithCheckbox;

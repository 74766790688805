import stylesTerminal from "./PhoneVerifyTerminal.module.css";

import logo from "../../../../assets/icons/authIcons/alrosaMainLogo/Logo.svg";
import { isTerminal } from "../../../../constants";

import UButton from "../../Button/UButton/UButton";
import { setToNullStateAction } from "../../../../redux/actions/AuthNumber/aunteficationAction";

import { ButtonTerminal } from "../../componentsTerminal/Button/ButtonTerminal";

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import VerificationInput from "react-verification-input";

import "./PhoneVerify.scss";
import "../../../../styles/default/_variables.scss";

const PhoneVerify = ({
    clickHandlerSupport,
    input,
    setInput,
    submitHandler,
    authNumber,
    setToNullAuthNumber,
    setCounter,
    counter,
    active,
    setActive,
    phone,
    unep,
    styleParent,
    approveIdentityWith,
    repeatCode,
    disableSubmit,
}) => {
    const dispatch = useDispatch();

    function counterHandler() {
        setCounter(60);
        setActive(false);
        setInput("");
        if (unep) {
            dispatch(approveIdentityWith(2));
        }
        if (repeatCode) {
            repeatCode();
        }
    }

    function setStateHandler(e) {
        dispatch(setToNullStateAction());
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (counter) {
                setCounter(counter - 1);
            }
        }, 1020);

        if (counter <= 0) {
            setActive(true);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [counter, setActive, setCounter]);

    useEffect(() => {
        setStateHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isTerminal) {
        return (
            <form
                className={`${styleParent}`}
                onSubmit={(e) => submitHandler(e)}>
                <img
                    className={`${styleParent}__logo`}
                    src={logo}
                    alt="alrosa"
                />
                {!authNumber ? (
                    <div className={`${styleParent}__message`}>
                        <p>Введите код из СМС отправленный на номер</p>
                        <p className={`${styleParent}__bold`}>{phone}</p>
                    </div>
                ) : (
                    <label className={`${styleParent}__label-invalid`}>
                        {authNumber}
                    </label>
                )}
                <div
                    onClick={
                        unep
                            ? () => setToNullAuthNumber("")
                            : () => setStateHandler()
                    }>
                    <VerificationInput
                        value={input}
                        onChange={setInput}
                        autoFocus={true}
                        placeholder={""}
                        removeDefaultStyles
                        validChars={"0-9"}
                        classNames={{
                            container: "character__container",
                            character: !authNumber
                                ? "character"
                                : "character-invalid",
                            characterInactive: "character--inactive",
                            characterSelected: "character--selected",
                        }}
                    />
                </div>
                <div className={`${styleParent}__refresh`}>
                    <p>
                        Повторная отправка СМС через
                        {counter >= 0 ? (
                            <>
                                <span className={`${styleParent}__support`}>
                                    {" "}
                                    {counter}{" "}
                                </span>
                            </>
                        ) : (
                            <>
                                <span className="colored-text"> 0 </span>
                            </>
                        )}
                        секунд
                    </p>
                    <span
                        onClick={active ? counterHandler : null}
                        className={
                            active ? `${styleParent}__support` : "colored-text"
                        }>
                        Отправить повторно
                    </span>
                </div>
                <UButton
                    disabled={disableSubmit}
                    loading={disableSubmit}
                >{unep ? "продолжить" : "войти"}</UButton>

                {!unep ? (
                    <span
                        style={{ textAlign: "left" }}
                        onClick={
                            clickHandlerSupport ? clickHandlerSupport : null
                        }
                        className={`${styleParent}__support`}>
                        Запрос в поддержку
                    </span>
                ) : null}
            </form>
        );
    }

    if (isTerminal) {
        return (
            <>
                <form
                    className={stylesTerminal.form}
                    onSubmit={(e) => submitHandler(e)}>
                    <img
                        className={stylesTerminal.logo}
                        src={logo}
                        alt="alrosa"
                    />
                    {!authNumber ? (
                        <div className={stylesTerminal.titleWrapper}>
                            <p>Введите код из СМС отправленный на номер</p>
                            <p className={stylesTerminal.textBold}>{phone}</p>
                        </div>
                    ) : (
                        <label className={stylesTerminal.errorText}>
                            {authNumber}
                        </label>
                    )}
                    <div
                        onClick={
                            unep
                                ? () => setToNullAuthNumber("")
                                : () => setStateHandler()
                        }>
                        <VerificationInput
                            value={input}
                            onChange={setInput}
                            autoFocus={true}
                            placeholder={""}
                            removeDefaultStyles
                            validChars={"0-9"}
                            classNames={{
                                container: "character__container",
                                character: !authNumber
                                    ? "character"
                                    : "character-invalid",
                                characterInactive: "character--inactive",
                                characterSelected: "character--selected",
                            }}
                        />
                    </div>
                    <div className={stylesTerminal.timer}>
                        <p>
                            Повторная отправка СМС через
                            {counter >= 0 ? (
                                <>
                                    <span className={stylesTerminal.counter}>
                                        {" "}
                                        {counter}{" "}
                                    </span>
                                </>
                            ) : (
                                <>
                                    <span className={stylesTerminal.counter}>
                                        {" "}
                                        0{" "}
                                    </span>
                                </>
                            )}
                            секунд
                        </p>
                        <ButtonTerminal
                            label="Отправить повторно"
                            variant="text"
                            textAlign="text-start"
                            onClick={active ? counterHandler : null}
                            disabled={!active}
                        />
                    </div>
                    <ButtonTerminal type="submit" label={unep ? "Продолжить" : "Войти"} />
                </form>
                {!unep && (
                    <ButtonTerminal
                        label="Запрос в поддержку"
                        variant="text"
                        textAlign="text-start"
                        onClick={clickHandlerSupport}
                    />
                )}
            </>
        );
    }
};

export default PhoneVerify;

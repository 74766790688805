import { AUTH_TYPE_LOGIN } from "./loginForm.const";

import { mode } from "../../../../app/mode";

export class LoginFromTerminalService {

    static getAuthType(str) {
        str = str.trim();

        if (str.length < 5) return AUTH_TYPE_LOGIN.EMPTY.type;
        if (str.indexOf("@") >= 0) return AUTH_TYPE_LOGIN.EMAIL.type;
        if (str.indexOf("+") >= 0 || str[0] === "8") return AUTH_TYPE_LOGIN.PHONE.type;
        str = str.replace(/(\D)/g, "");
        if (mode === "PROD") {
            if (str.length === 11) {
                // eslint-disable-next-line max-len
                // https://ru.wikipedia.org/wiki/%D0%9A%D0%BE%D0%BD%D1%82%D1%80%D0%BE%D0%BB%D1%8C%D0%BD%D0%BE%D0%B5_%D1%87%D0%B8%D1%81%D0%BB%D0%BE#%D0%A1%D1%82%D1%80%D0%B0%D1%85%D0%BE%D0%B2%D0%BE%D0%B9_%D0%BD%D0%BE%D0%BC%D0%B5%D1%80_%D0%B8%D0%BD%D0%B4%D0%B8%D0%B2%D0%B8%D0%B4%D1%83%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE_%D0%BB%D0%B8%D1%86%D0%B5%D0%B2%D0%BE%D0%B3%D0%BE_%D1%81%D1%87%D1%91%D1%82%D0%B0_(%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D1%8F)
                const num = str.substring(0, 9);
                const sum = Number(str.substring(9, 11));

                let control = num.split("")
                    .reduce((sum, cur, idx) =>
                        sum + Number(cur) * (9 - idx), 0);

                control = control % 101;

                if (control === 100) control = 0;

                if (control === sum) return AUTH_TYPE_LOGIN.SNILS.type;
            }
            return AUTH_TYPE_LOGIN.PHONE.type;
        } else {
            return AUTH_TYPE_LOGIN.SNILS.type;
        }


    }

}

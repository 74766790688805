import { tableHeader } from "./table.data";

import Table from "../../Table/Table";
import TableHead from "../../Table/TableHead";
import TableHeadRow from "../../Table/TableHeadRow";
import TableWeekHelper from "../../../../frontend.services/TableServices/TableWeek.helper";

import {
    getGantRepresentationThunkAction,
} from "../../../../redux/actions/Employees/Gant/thunk/getGantRepresentation.thunk.action";

import Portal from "../../Portal/Portal";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";


import React, { useEffect, useState } from "react";

const HolidaysTableWeek = ({ tableStyle }) => {

    const dispatch = useDispatch();

    const gantData = useSelector(state => state.employees.gant);
    const user = useSelector(state => state.user);

    const [tooltip, setTooltip] = useState(null);

    useEffect(() => {
        dispatch(getGantRepresentationThunkAction({
            // THIS PERIOD HAS VACATIONS
            // from: moment('2022-11-08').startOf('week').format("YYYY-MM-DD"),
            // to: moment('2022-11-08').endOf('week').format("YYYY-MM-DD")
            from: moment().startOf("week").format("YYYY-MM-DD"),
            to: moment().endOf("week").format("YYYY-MM-DD"),
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <>
            <Table tableStyle={tableStyle}>
                <TableHead parentStyle={tableStyle}>
                    <TableHeadRow
                        parentStyle={tableStyle}
                        data={tableHeader} />
                </TableHead>

                <tbody>
                    {gantData && TableWeekHelper.getRows(gantData, tableStyle, setTooltip)}
                </tbody>
            </Table>
            {
                tooltip &&
                <Portal>
                    {tooltip}
                </Portal>
            }
        </>

    );
};

export default HolidaysTableWeek;

import { ImportantTasksDropdownTreeSelector } from "./ImportantTasksDropdownTreeSelector";

import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

const ImportantTasksDropdownTree = ({ currentRequest, isValid, setWorkersState }) => {
    const dropdownRef = useRef();

    const structTree = useSelector(state => state.requests.structTree);
    const structItem = useSelector(state => state.requests.selectedStructItem);
    const [isMenuActive, setIsMenuActive] = useState(false);

    function toggleMenuHandler(e) {
        setIsMenuActive(!isMenuActive);
    }

    function clickOutside(e) {
        if (!dropdownRef.current.contains(e.target)) {
            setIsMenuActive(false);
        }
    }


    useEffect(() => {
        document.addEventListener("mousedown", clickOutside);
        return () => document.removeEventListener("mousedown", clickOutside);
    }, []);




    return (
        <>
            <div style={{ fontSize: "12px", lineHeight: "19px", marginTop: "15px" }}>Подразделение *</div>
            <div
                ref={dropdownRef}
                className={`important-tasks__struct-dropdown${isValid === false ? "-error" : ""}`}
            >
                <div
                    onClick={toggleMenuHandler}
                    className={"important-tasks__struct-dropdown__selected"}
                >
                    {currentRequest && currentRequest.structName ? currentRequest.structName : structItem && structItem.name}
                    <span className={`important-tasks__struct-dropdown__arrow${isMenuActive ? "-up" : "-down"}`}></span>
                </div>
                <div className={`important-tasks__struct-dropdown__dropdown${isMenuActive ? "-active" : "-hidden"}`}>
                    {
                        <ImportantTasksDropdownTreeSelector
                            setIsMenuActive={setIsMenuActive}
                            tree={structTree}
                            setWorkersState={setWorkersState} />
                    }
                </div>
                {
                    isValid === false
                        ? <div className={"important-tasks__error"}>Обязательное поле</div>
                        : <div className={"important-tasks__error-buffer"}></div>
                }
            </div>
        </>

    );
};

export default ImportantTasksDropdownTree;
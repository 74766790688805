import { workAppontmentsAction } from "./workAppontments.action";

import { $api } from "../../../../services/request.config";

export const workAppontmentsThunkAction = () => async (dispatch) => {
    try {
        const allAppointments = await $api.get("v1/u/p/work/appointments");
        console.log("ALLLAPPOINTMENTS: ", allAppointments);
        const temp = allAppointments.data.map((element) => {
            return {
                id: element.pid,
                name: `${element.position} ${element.firmName}`,
                isMain: element.isMain,
                tabNumber: element.tabNumber,
            };
        });
        console.log(temp);
        dispatch(workAppontmentsAction(temp));
    } catch (e) {

    }
};

import { AUTH_TYPE_LOGIN } from "../../LoginForm/loginForm.const";

import React from "react";

export const LoginValidateResultSection = ({ onError, valid, selectedOption }) => {
    return (
        onError ? (
            <>
                <label
                    className="login__wrapper__label-invalid"
                    htmlFor="socNumber">
                    {onError}
                </label>
            </>
        ) : (
            <label
                className={
                    valid
                        ? "login__wrapper__label"
                        : "login__wrapper__label-invalid"
                }
                htmlFor="socNumber">
                {valid
                    ? AUTH_TYPE_LOGIN[selectedOption].name
                    : `Введите корректный ${AUTH_TYPE_LOGIN[selectedOption].name}`}
            </label>
        )
    );
};
const RWDLockPage = () => {
    return (<>
        <div className={"sub-header"}>
            Заявки руководителя
        </div>
        <div style={{
            margin: "60px auto",
            fontSize: "24px",
            lineHeight: "32px",
            color: "gray",
            width: "1160px",
            minHeight: "300px",
        }}>
            На данный момент заявка находится в разработке
        </div>
    </>);
}
 
export default RWDLockPage;
import ModalBanOverlay from "../../common/Modal/ModalBanOverlay/ModalBanOverlay";

import WorkerSignType from "../../common/Modal/ModalConditions/UserSignType/WorkerSignType/WorkerSignType";
import { Modal } from "../../common/Modal/Modal";
import { isTerminal } from "../../../constants";

import {
    workAppontmentsThunkAction,
} from "../../../redux/actions/User/profile/WorkAppontments/workAppontments.thunk.action";

import { Navigate, Outlet, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";

import React from "react";
import { useDispatch } from "react-redux";

const ProtectedAuth = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const [errorModal, setErrorModal] = useState(false);
    const [errDescription, setErrDescription] = useState("work");
    const dispatch = useDispatch();

    function closeModal() {
        setErrorModal(false);
    }

    function check(e) {
        if (localStorage.getItem("restricted") === "true") {
            setErrorModal(true);
            setErrDescription(
                localStorage.getItem("reason"),
            );
        }
    }

    useEffect(() => {
        dispatch(workAppontmentsThunkAction());
    }, []);

    useEffect(() => {
        if (localStorage.getItem("restricted") === "true") {
            setErrorModal(true);
            setErrDescription(
                localStorage.getItem("reason"),
            );
        }
    }, [localStorage.getItem("restricted")]);


    useEffect(() => {
        if (localStorage.getItem("restricted") === "true") {
            setErrorModal(true);
            setErrDescription(
                localStorage.getItem("reason"),
            );
            navigate("/main");
        }
    }, [window.location.pathname]);

    const pageStyle = isTerminal ?
        { display: "flex", height: "100%", flexDirection: "column"} :
        { display: "flex", minHeight: "100vh", flexDirection: "column" };

    return token
        ?
        <div
            onClick={(e) => check(e)}
            style={pageStyle}>

            <Outlet />

            <Modal shown={errorModal} close={setErrorModal}>
                <WorkerSignType
                    closeButton={true}
                    close={closeModal}
                    path={"/main"}
                    description={errDescription}
                    setErrorModal={setErrorModal}
                    // block={true}
                />
            </Modal>
        </div>
        : <Navigate to="/start" />;
};

export default ProtectedAuth;

export default class RecruitmentToWorkOnHolidaysApplication {
    constructor({
        uid,
        consumer = 1,
        reason,
        leaveTypeId,
        employees = [],
    }) {
        this.uid = uid || null;
        this.consumer = consumer;
        this.leaveTypeId = leaveTypeId;
        this.reason = reason;
        this.employees = employees;
        this.isValid = {
            uid: true,
            reason: true,
            workers: true,
        };
    }
}

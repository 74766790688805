export const jobHours = [
    { name: 0.5, id: 0.5 },
    { name: 1, id: 1 },
    { name: 1.5, id: 1.5 }, 
    { name: 2, id: 2 },
    { name: 2.5, id: 2.5 },
    { name: 3, id: 3 },
    { name: 3.5, id: 3.5 }, 
    { name: 4, id: 4 }, 
    { name: 4.5, id: 4.5 }, 
    { name: 5, id: 5 }, 
    { name: 5.5, id: 5.5 },
    { name: 6, id: 6 }, 
    { name: 6.5, id: 6.5 }, 
    { name: 7, id: 7 }, 
    { name: 7.5, id: 7.5 },
    { name: 8, id: 8 }, 
    { name: 8.5, id: 8.5 }, 
    { name: 9, id: 9 }, 
    { name: 9.5, id: 9.5 },
    { name: 10, id: 10 }, 
    { name: 10.5, id: 10.5 }, 
    { name: 11, id: 11 }, 
    { name: 11.5, id: 11.5 }, 
    { name: 12, id: 12 },
];

import left from "../../../../assets/documents/leftVector.svg";
import right from "../../../../assets/documents/rightVector.svg";

import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "./AnnualCalendar.scss";


const AnnualCalendar = (props) => {

    const [startDate] = useState(null);

    const year = new Array(12).fill(null);
    const month = year.map((e, i) => {
        const tempMonth = e + i + 1;
        return `${props.year}/` + tempMonth + "/01";
    });

    const highlightWithRanges = [
        {
            "react-datepicker__day--highlighted-custom-1": props.type.data?.noPlan,//["20.01.23", ]
        },
        {
            "react-datepicker__day--highlighted-custom-2": props.type.data?.allPlan,
        },
    ];

    return (
        <div className="annual-calendar">
            <div className="annual-calendar__header">
                <img
                    src={left}
                    alt="left-vector" />
                <span className="annual-calendar__header__span">
                    {props.type.type}
                </span>
                <img
                    src={right}
                    alt="right-vector" />
            </div>
            <div className="annual-calendar__month-container">
                {year.map((e, i) => {
                    return (
                        <div className="annual-calendar__month" key={i}>
                            <DatePicker
                                calendarClassName="TEST"
                                locale={"ru"}
                                readOnly
                                selected={startDate}
                                fixedHeight
                                disabled
                                highlightDates={highlightWithRanges}
                                disabledKeyboardNavigation
                                openToDate={new Date(month[i])}
                                inline
                                minDate={
                                    props.disableOtherMonth
                                    && props.year
                                    && new Date(props.year, i, 1)
                                }
                                maxDate={
                                    props.disableOtherMonth
                                    && props.year
                                    && new Date(props.year, i + 1, 0)
                                }
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default AnnualCalendar;

import {
    getGeneralDataThunkAction,
} from "../../../../../redux/actions/User/profile/ServiceData/thunk/getGeneralDataThunkAction";
import BasicLine from "../../../../common/BasicLine/BasicLine";
import { isTerminal } from "../../../../../constants";
import { ScrollButton } from "../../../../common/componentsTerminal/ScrollButton/ScrollButton";

import CustomLoader from "../../../../common/CustomLoader/CustomLoader";

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";

const GeneralData = () => {
    const dispatch = useDispatch();

    const generalData = useSelector(state => state.personalData.serviceData);
    const [generalDataInfo, setGeneralDataInfo] = useState(generalData);

    useEffect(() => {
        !generalDataInfo && dispatch(getGeneralDataThunkAction());
    }, [dispatch]);

    useEffect(() => {
        setGeneralDataInfo(generalData);
    }, [generalData]);

    if (!generalDataInfo) return (
        <CustomLoader />

    );

    return (
        <div className="fio">
            {isTerminal && <ScrollButton />}
            {generalDataInfo && generalDataInfo?.map((el, index) => {
                return (
                    <BasicLine
                        key={index}
                        type={el.type}
                        description={el.description}
                        styleParent={"fio"}
                    />
                );
            })}

        </div>
    );
};

export default GeneralData;

import { $api } from "../../../../../services/request.config";
import { getSubordinatesAction } from "../getSubordinates.action";

export const getSubordinatesThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v1/managers/requests/rvd/subordinates");
        console.log(response.data);
        //     [
        //         {
        //             fullName: "Болкунова Анна Олеговна"
        //             fullStructName: "Цифровая лаборатория (г. Москва)"
        //             jobTitle: "Эксперт"
        //             pid: 156276
        //             tabNumber: "D156276"
        //         },
        // ]
        if (response.data) {
            dispatch(getSubordinatesAction(response.data));
        }

    } catch (error) {
        throw new Error(error?.response);
    }
};

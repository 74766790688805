import { AUTH_TYPE_ITEMS, AUTH_TYPE_LOGIN } from "./loginForm.const";

import logo from "../../../../assets/icons/authIcons/alrosaMainLogo/Logo.svg";
import UButton from "../../Button/UButton/UButton";
import { mode } from "../../../../app/mode";
import { resetErrorLoginMessageAction } from "../../../../redux/actions/Auth/wrongAuthAction";
import { signInWithESIAThunkAction } from "../../../../redux/actions/Auth/Thunk/signInWithESIA.thunk.action";
import { userLoginThunkAction } from "../../../../redux/actions/Auth/Thunk/userLoginThunkAction";
import { LoginPasswordField } from "../LoginFormSection/LoginPasswordField/LoginPasswordField";
import { LoginForgotPasswordSection } from "../LoginFormSection/LoginForgotPasswordSection/LoginForgotPasswordSection";
import { LoginPhoneMaskInput } from "../LoginFormSection/LoginPhoneMaskInput/LoginPhoneMaskInput";
import { LoginTypeSelectInput } from "../LoginFormSection/LoginTypeSelectInput/LoginTypeSelectInput";
import { LoginEmailMaskInput } from "../LoginFormSection/LoginEmailMaskInput/LoginEmailMaskInput";
import { LoginSnilsMaskInput } from "../LoginFormSection/LoginSnilsMaskInput/LoginSnilsMaskInput";
import { LoginEmptySelectInput } from "../LoginFormSection/LoginEmptySelectInput/LoginEmptySelectInput";
import { LoginValidateResultSection } from "../LoginFormSection/LoginValidateResultSection/LoginValidateResultSection";
import { LoginInputTypeSelectTriangle } from "../LoginFormSection/LoginInputTypeSelectTriangle/LoginInputTypeSelectTriangle";
import LoginFormValidationService from "../../../../frontend.services/Auth/LoginFormValidation.service";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import "./LoginForm.scss";

const LoginForm = ({ modalShownSupport, toggleModalSupport, modalShown, toggleModal }) => {
    const ref = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const inputSnils = useRef();
    const inputEmail = useRef();
    const inputPhone = useRef();
    const user = useSelector((state) => state.user);
    const [onError, setOnError] = useState(false);
    const [selectedOption, setSelectedOption] = useState(AUTH_TYPE_LOGIN.EMPTY.type);
    const [visible, setVisible] = useState(false);
    const [, setSelectedOptionTriangle] = useState(true);
    const [snils, setSnils] = useState("");
    const [changedSnils, setChangedSnils] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [valid, setValid] = useState(true);

    function visibleSelect() {
        setVisible(!visible);
        setEmail(""); // надо ли очищать почту при показе списка типов логина???
    }

    function optionHandler(e) {
        setVisible(false);
        setSelectedOptionTriangle(true);
        setSelectedOption(e.target.id ? e.target.id : AUTH_TYPE_LOGIN.EMPTY.type);
        setSnils("");
        setPhone("");
        setEmail("");
    }

    function clickOutside(e) {
        if (!ref.current?.contains(e.target)) {
            setVisible(false);
        }
    }

    const setToValidLabelAndInput = () => {
        setValid(true);
        dispatch(resetErrorLoginMessageAction());
    };

    function navigateToEsia() {
        dispatch(signInWithESIAThunkAction());
    }

    const submitHandler = (e) => {
        e.preventDefault();
        setValid(true);
        const validator = LoginFormValidationService;
        let result = false;
        let userId = null;

        switch (selectedOption) {
        case AUTH_TYPE_LOGIN.EMAIL.type:
            result = validator.validate(email,
                [
                    { rule: validator.LENGTH.MIN, param: 1 },
                    { rule: validator.TYPE.EMAIL },
                ]);
            userId = email;
            break;

        case AUTH_TYPE_LOGIN.PHONE.type:
            result = validator.validate(phone,
                [
                    { rule: validator.LENGTH.MIN, param: 1 },
                ]);
            userId = "+" + phone;
            break;

        case AUTH_TYPE_LOGIN.SNILS.type:
            result = validator.validate(snils,
                [
                    { rule: validator.LENGTH.EQ, param: 11 },
                ]);
            userId = snils;
            break;
        default:
            result = false;
        }

        if (result === true && userId) {
            dispatch(
                userLoginThunkAction({ userId: userId, password: password }, (message) => setOnError(message)),
            );
            if (user) {
                navigate("/authentication");
            }
        } else {
            setValid(false);
        }
    };


    const handleChangePhone = () => {
        // const cardValue = inputPhone.current?.value;
        const numbers = inputPhone.current?.value.replace(/(\D)/g, "");
        setPhone(numbers);
    };

    const handleChangeSnils = () => {
        const cardValue = inputSnils.current?.value
            .replace(/\D/g, "")
            .match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
        inputSnils.current.value = !cardValue[2]
            ? cardValue[1]
            : `${cardValue[1]}-${cardValue[2]}${`${
                cardValue[3] ? `-${cardValue[3]}` : ""
            }`}${`${cardValue[4] ? ` ${cardValue[4]}` : ""}`}`;
        setChangedSnils(inputSnils.current.value);
        const numbers = inputSnils.current?.value.replace(/(\D)/g, "");
        setSnils(numbers);
    };

    function clickHandlerForgotPassword() {
        toggleModal(!modalShown);
    }

    function clickHandlerSupport() {
        toggleModalSupport(!modalShownSupport);
    }

    useEffect(() => {
        // localStorage.removeItem('userId')
        localStorage.removeItem("resetPassword");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        if (user) {
            navigate("/authentication");
        }

        setValid(onError !== "Пользователь не найден");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, onError]);

    useEffect(() => {
        document.addEventListener("mousedown", clickOutside);
        return () => document.removeEventListener("mousedown", clickOutside);
    }, [selectedOption]);



    return (
        <>
            <form
                onSubmit={submitHandler}
                onClick={setToValidLabelAndInput}
                className="login">

                <img className="login__logo" src={logo} alt="login-logo" />
                <div>
                    <div ref={ref} className="login__wrapper">

                        <LoginValidateResultSection
                            onError={onError}
                            valid={valid}
                            selectedOption={selectedOption}
                        />

                        {
                            selectedOption === AUTH_TYPE_LOGIN.PHONE.type ? (
                                <LoginPhoneMaskInput
                                    onError={onError}
                                    inputPhone={inputPhone}
                                    handleChangePhone={handleChangePhone}
                                    setToValidLabelAndInput={setToValidLabelAndInput}
                                    valid={valid} />
                            ) : null
                        }

                        {
                            selectedOption === AUTH_TYPE_LOGIN.EMAIL.type ? (
                                <LoginEmailMaskInput
                                    onError={onError}
                                    valid={valid}
                                    setToValidLabelAndInput={setToValidLabelAndInput}
                                    email={email}
                                    inputEmail={inputEmail}
                                    setEmail={setEmail}
                                    disabled={false}

                                />
                            ) : null
                        }

                        {
                            selectedOption === AUTH_TYPE_LOGIN.SNILS.type ? (
                                <LoginSnilsMaskInput
                                    onError={onError}
                                    valid={valid}
                                    setToValidLabelAndInput={setToValidLabelAndInput}
                                    changedSnils={changedSnils}
                                    inputSnils={inputSnils}
                                    handleChangeSnils={handleChangeSnils}
                                />
                            ) : null
                        }

                        {
                            (AUTH_TYPE_ITEMS.indexOf(selectedOption) + 1) ? null : (
                                <LoginEmptySelectInput onClick={visibleSelect} />
                            )
                        }


                        <LoginInputTypeSelectTriangle visibleSelect={visibleSelect} visible={visible} />

                        <LoginTypeSelectInput
                            className={"login__wrapper__options"}
                            visible={visible}
                            selectedOption={selectedOption}
                            onSelectOption={optionHandler}
                        />
                    </div>
                </div>
                <div className="login__wrapper">
                    <LoginPasswordField onChange={(e) => setPassword(e)} />
                </div>

                <UButton disabled={!password || (!snils && !email && !phone)}>Войти</UButton>

                <div className="login__wrapper">
                    <LoginForgotPasswordSection clickHandlerForgotPassword={clickHandlerForgotPassword} />
                </div>
                {/*TODO MODE SELECTOR*/}
                {mode === "TEST" ? (
                    <div className="login__wrapper">
                        <div className="login__wrapper__checkbox-text">
                            <div>
                                Войти через{" "}
                                <span
                                    onClick={navigateToEsia}
                                    className="colored-texts">
                                        Госуслуги(ЕСИА)
                                </span>
                            </div>
                        </div>
                    </div>
                ) : null}
            </form>
            <div className="login__support">
                <span
                    className="colored-texts"
                    onClick={clickHandlerSupport}>
                        Запрос в поддержку
                </span>
            </div>
        </>
    );

};

export default LoginForm;

import styledTerminal from "./ResetNewTerminal.module.css";

import passwordEye from "../../../../assets/icons/authIcons/eye.svg";
import logo from "../../../../assets/icons/authIcons/alrosaMainLogo/Logo.svg";

import openEyes from "../../../../assets/icons/authIcons/ic_eyes_open.svg";
import { newPasswordThunkAction } from "../../../../redux/actions/Auth/Thunk/newPasswordThunkAction";
import { setStateToResetPasswordAction } from "../../../../redux/actions/Auth/resetStatetePasswordAction";
import { resetStateNullAction } from "../../../../redux/actions/Auth/resetPasswordAction";
import UButton from "../../Button/UButton/UButton";
import { isTerminal } from "../../../../constants";
import { InputTerminal } from "../../componentsTerminal/Input/InputTerminal";
import { ButtonTerminal } from "../../componentsTerminal/Button/ButtonTerminal";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import "./ResetNewPassword.scss";

const ResetNewPassword = ({ clickHandlerSupport }) => {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [onError, setOnError] = useState("");

    const [input, setInput] = useState({
        newPassword: "",
        confirmPassword: "",
    });
    const [validNewPassword, setValidNewPassword] = useState(true);
    const [passwordShown, setPasswordShown] = useState(false);
    const [openEye, setOpenEye] = useState(false);
    const [openEyeRepeat, setOpenEyeRepeat] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const togglePasswordVisiblity = () => {
        setOpenEye(!openEye);
        setPasswordShown(!passwordShown);
    };
    const [passwordShownRepeat, setPasswordShownRepeat] = useState(false);
    const togglePasswordVisiblityRepeat = () => {
        setOpenEyeRepeat(!openEyeRepeat);
        setPasswordShownRepeat(!passwordShownRepeat);
    };
    const checkValidPassword = (e) => {
        if (input.password !== input.confirmPassword) {
            setValidNewPassword(false);
        }
    };
    const checkPassword = (e) => {
        if (input.password?.length < 8) {
            // setInvalidLength(true)
        }
    };
    const inputHandler = (e) => {
        setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };
    const submitHandler = (e) => {
        e.preventDefault();
        setValidNewPassword(true);
        if (input.password === input.confirmPassword) {
            setIsLoading(true);
            dispatch(
                newPasswordThunkAction(
                    {
                        userId: localStorage.getItem("userId"),
                        password: input.password,
                    },
                    { navigate },
                    (message)=>setOnError(message)
                ),
            ).then(()=>setIsLoading(false));
            setInput({ newPassword: "", confirmPassword: "" });
        } else {
            checkValidPassword(e);
            checkPassword(e);
        }
    };
    useEffect(() => {
        dispatch(setStateToResetPasswordAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isTerminal) {
        return (
            <>
                <form onSubmit={submitHandler} className="resetPassword">
                    <img
                        className="resetPassword__logo"
                        src={logo}
                        alt="reset-pwd"
                    />
                    <div className="resetPassword__wrapper">
                        <label
                            htmlFor="password"
                            className={
                                onError
                                    ? "resetPassword__wrapper__label-invalid"
                                    : "resetPassword__wrapper__label"
                            }>
                            {onError
                                ? onError
                                : "Новый пароль"}
                        </label>
                        <input
                            name="password"
                            maxLength={32}
                            className={
                                onError
                                    ? "resetPassword__wrapper__input-invalid"
                                    : "resetPassword__wrapper__input"
                            }
                            onChange={inputHandler}
                            onClick={() => dispatch(resetStateNullAction())}
                            type={passwordShown ? "text" : "password"}
                        />
                        <i
                            className={"resetPassword__wrapper__icon"}
                            onClick={togglePasswordVisiblity}>
                            <img
                                src={openEye ? openEyes : passwordEye}
                                alt="openEye"
                            />
                        </i>
                    </div>
                    <div className="resetPassword__wrapper">
                        <label
                            htmlFor="confirmPassword"
                            className={
                                !validNewPassword
                                    ? "resetPassword__wrapper__label-invalid"
                                    : "resetPassword__wrapper__label"
                            }>
                            {validNewPassword
                                ? "Повторите пароль"
                                : "Пароли должны совпадать"}
                        </label>
                        <input
                            name="confirmPassword"
                            maxLength={32}
                            className={
                                !validNewPassword
                                    ? "resetPassword__wrapper__input-invalid"
                                    : "resetPassword__wrapper__input"
                            }
                            onChange={inputHandler}
                            onClick={() => setValidNewPassword(true)}
                            type={passwordShownRepeat ? "text" : "password"}
                        />
                        <i
                            className={"resetPassword__wrapper__icon"}
                            onClick={togglePasswordVisiblityRepeat}>
                            <img
                                src={openEyeRepeat ? openEyes : passwordEye}
                                alt="openEye"
                            />
                        </i>
                    </div>
                    <UButton disabled={!input.password||!input.confirmPassword} loading={isLoading}>Обновить пароль</UButton>
                    <span
                        className="resetPassword__colored-texts"
                        onClick={clickHandlerSupport}>
                        Запрос в поддержку
                    </span>
                </form>
            </>
        );
    }

    if (isTerminal) {

        return (
            <>
                <form
                    className={styledTerminal.containerForm}
                    onSubmit={submitHandler}>
                    <img style={{ width: "50%" }} src={logo} alt="reset-pwd" />
                    <InputTerminal
                        name="password"
                        label="Новый пароль"
                        placeholder="Введите пароль"
                        onChange={inputHandler}
                        onClick={() => dispatch(resetStateNullAction())}
                        errorView={onError}
                        type={passwordShown ? "text" : "password"}
                        maxLength={32}
                        trailingIcon={
                            <i
                                className={styledTerminal.wrapperEye}
                                onClick={togglePasswordVisiblity}>
                                <img
                                    src={openEye ? openEyes : passwordEye}
                                    alt="openEye"
                                />
                            </i>
                        }
                    />
                    <InputTerminal
                        name="confirmPassword"
                        label="Повторить пароль"
                        placeholder="Введите пароль"
                        onChange={inputHandler}
                        onClick={() => setValidNewPassword(true)}
                        errorView={!validNewPassword && "Пароли должны совпадать"}
                        type={passwordShownRepeat ? "text" : "password"}
                        maxLength={32}
                        trailingIcon={
                            <i
                                className={styledTerminal.wrapperEye}
                                onClick={togglePasswordVisiblityRepeat}>
                                <img
                                    src={openEyeRepeat ? openEyes : passwordEye}
                                    alt="openEye"
                                />
                            </i>
                        }
                    />
                    <ButtonTerminal 
                        label="Обновить"
                        type="submit"
                        disabled={!input.password || !input.confirmPassword}
                    />
                    <div className={styledTerminal.loginContainerHelpers}>
                        <div />
                        <ButtonTerminal 
                            variant="text"
                            label="Запрос в поддержку"
                            onClick={clickHandlerSupport}
                        />
                    </div>
                </form>
                
            </>
        );
    }
};

export default ResetNewPassword;

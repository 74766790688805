export const requestData = {
    "reason": " Arbeit macht frei",
    "employees": [
        {
            "pid": 199352,
            "days": [
                {
                    "jobDate": "2024-05-01",
                    "jobHours": 8.5,
                    "compensationType": 2,
                    "restDate": "2024-05-04",
                },
                {
                    "jobDate": "2024-06-06",
                    "jobHours": 4,
                    "compensationType": 1,
                },
            ],
        },
        {
            "pid": 166876,
            "days": [
                {
                    "jobDate": "2024-05-01",
                    "jobHours": 11.5,
                    "compensationType": 1,
                },
            ],
        },
    ],

};
import $api from "../../../../../services/axiosService";
import { createCertificateRequestInfoAction } from "../createCertificateRequestInfo.action";

export const createCertificateRequestInfoThunkAction = (data, props) => async (dispatch) => {
    try {
        const response = await $api.post("v1/c/certificates", data);
        console.log(response.data);
        if (response.status) {
            dispatch(createCertificateRequestInfoAction(response.data));
            return props.setLoader(false);
        }
    } catch (e) {
        props.setLoader(false);
        // console.log('createCertificateRequestInfoThunkAction: ',e?.response)
        // if (e.response.data.detail || e.response.data.status === '403' || e.response.data.status === 403) {
        // if(e.response.status == 4){
        //
        //     console.log(1111)
        //   return   props.setDescription(e.response.data.detail)
        // }
        return props.setDescription(" Что-то пошло не так, повторите попытку позже ");
    }
};

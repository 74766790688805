import { $api } from "../../../../services/request.config";
import { getStructureTreeAction } from "../getStructureTree.action";

export const getStructureHeadThunkAction = (id= 1) => async (dispatch) => {
    try {
        const response = await $api("v1/structs/tree?id=" + id);
        if (response.data.length) {
            dispatch(getStructureTreeAction(response.data));
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log("ERROR WHILE GET TOP STRUCTURE: ", error);
    }
};
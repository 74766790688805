import CommentInput from "../../../../../../common/Inputs/CommentInput/CommentInput";
import DropdownMenu from "../../../../../../common/Menu/DropdownMenu/DropdownMenu";
import CustomUploader from "../../../../../../common/CustomUploader/CustomUploader";

import NotificationWindowRequests from "../../../../../../common/NotificationWindowRequests/NotificationWindowRequests";
import MaternityLeaveApplication from "../../../../../../../frontend.classes/Requests/MaternityLeave.application";
import {
    getMaterinityDisDocsThunkAction,
} from "../../../../../../../redux/actions/Requests/Materinity/thunk/getMaterinityDisDocs.thunk.action";
import {
    dropdownSelectionCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/dropdownSelection.currentRequest";
import DatePopupSelector from "../../../../../../common/Inputs/Calendar/DatePopupSelector/DatePopupSelector";

import {
    getReasonBelongsTypeThunkAction,
} from "../../../../../../../redux/actions/Requests/LeaveMetadata/thunk/getReasonBelongsType.thunk.action";
import {
    emptyCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/emptyCurrentRequest";
import {
    clearAttachmentsAction,
} from "../../../../../../../redux/actions/Requests/Attachments/clearAttachments.action";
import {
    addCommentHandlerCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/addCommentHandler.currentRequest";
import {
    addBasicInputHandlerCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/addBasicInputHandler.currentRequest";
import {
    validationControlCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/validationControl.currentRequest";
import {
    calendarDatePickCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/calendarDatePick.currentRequest";

import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import "./MaternityLeave.scss";

function MaternityLeave({ setRequestStatus, requestStatus, requestOption }) {
    const dispatch = useDispatch();
    const currentRequest = useSelector(
        (state) => state.requests.currentRequest,
    );
    const disabilityDocs = useSelector(
        (state) => state.requests.disabilityDocs,
    );

    const [searchParams] = useSearchParams();
    const queryId = searchParams.get("id");

    const [notificationWindow, setNotificationWindow] = useState(false);

    function selectDropdown(event, element, stateProperty) {
        dispatch(
            dropdownSelectionCurrentRequest({ event, element, stateProperty }),
        );
    }

    function commentHandler(e) {
        dispatch(addCommentHandlerCurrentRequest(e.target.value));
    }

    function inputHandler(e, field) {
        let input = e.target.value.replace(/\D/g, "");
        if (input.length === 0) {
            input = null;
        }
        dispatch(
            addBasicInputHandlerCurrentRequest({
                field,
                value: input,
            }),
        );
        dispatch(validationControlCurrentRequest(field, true));
    }

    function changeDateHandler(field, date) {
        const value = moment(date).format("YYYY-MM-DD");
        if (field === "disabilityFrom" || field === "disabilityTo") {
            // dispatch(validationControlCurrentRequest(field, true));
            dispatch(calendarDatePickCurrentRequest({ field: "dateTo", date: null }));
            dispatch(calendarDatePickCurrentRequest({ field: "dateFrom", date: null }));

        }
        dispatch(validationControlCurrentRequest(field, true));
        dispatch(calendarDatePickCurrentRequest({ field: field, date: value }));
    }

    useEffect(() => {
        setRequestStatus(false);
        dispatch(clearAttachmentsAction());
        dispatch(getReasonBelongsTypeThunkAction(requestOption));
        dispatch(getMaterinityDisDocsThunkAction());
        if (!queryId) {
            dispatch(
                emptyCurrentRequest(
                    new MaternityLeaveApplication({
                        leaveTypeId: 6,
                        disabilityDocumentId: "",
                        disabilityDocumentName: null,
                        disabilityFrom: null,
                        disabilityTo: null,
                        dateFrom: null,
                        dateTo: null,
                    }),
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryId]);

    useEffect(() => {
        if (requestStatus && !requestStatus.isSuccess) {
            setNotificationWindow(true);
        }
    }, [requestStatus]);

    return (
        <section className={"maternity-leave"}>
            {disabilityDocs && disabilityDocs.length > 0 ? (
                <>
                    <DropdownMenu
                        currentRequest={currentRequest}
                        styleName={"reason"}
                        helper={
                            "Заявка оформляется в случае, если период отпуска не совпадает с периодом листка нетрудоспособности"
                        }
                        label={"Номер листка нетрудоспособности *"}
                        listOfOptions={disabilityDocs}
                        stateProperty={"disabilityDocumentId"}
                        isValidKey={
                            currentRequest &&
                            currentRequest.isValid &&
                            currentRequest.isValid.disabilityDocumentName
                                ? currentRequest.isValid.disabilityDocumentName
                                : false
                        }
                        selectOptionClick={selectDropdown}
                    />
                    {currentRequest && currentRequest.disabilityFrom ? (
                        <div className={"maternity-leave__dateControls"}>
                            <DatePopupSelector
                                field={"disabilityFrom"}
                                label={
                                    "Дата начала периода нетрудоспособности *"
                                }
                                errorMessage={
                                    currentRequest &&
                                    currentRequest.isValid &&
                                    currentRequest.isValid.disabilityFrom
                                        ? ""
                                        : "Обязательное поле"
                                }
                                propsDate={
                                    currentRequest &&
                                    currentRequest.disabilityFrom
                                }
                            />
                            <DatePopupSelector
                                field={"disabilityTo"}
                                label={
                                    "Дата конца периода нетрудоспособности *"
                                }
                                errorMessage={
                                    currentRequest &&
                                    currentRequest.isValid &&
                                    currentRequest.isValid.disabilityTo
                                        ? ""
                                        : "Обязательное поле"
                                }
                                propsDate={
                                    currentRequest &&
                                    currentRequest.disabilityTo
                                }
                            />
                        </div>
                    ) : null}
                </>
            ) : (
                <>
                    <CommentInput
                        value={
                            currentRequest &&
                            currentRequest.disabilityDocumentName
                        }
                        basicInput={true}
                        helper={
                            "Заявка оформляется в случае," +
                            " если период отпуска не совпадает с периодом листка нетрудоспособности"
                        }
                        maxLength={100}
                        stateProperty={"disabilityDocumentName"}
                        changeHandler={(e) =>
                            inputHandler(e, "disabilityDocumentName")
                        }
                        label={"Номер листка нетрудоспособности *"}
                        errorMessage={
                            currentRequest &&
                            currentRequest.isValid &&
                            currentRequest.isValid.disabilityDocumentName
                                ? ""
                                : "Обязательное поле"
                        }
                        parentStyle={"extra-vacation-days"}
                    />
                    <div
                        style={{
                            display: "flex",
                            width: "1160px",
                            margin: "auto",
                            gap: "20px",
                        }}
                    >
                        <DatePopupSelector
                            field={"disabilityFrom"}
                            label={"Дата начала периода нетрудоспособности *"}
                            errorMessage={
                                currentRequest &&
                                currentRequest.isValid &&
                                currentRequest.isValid.disabilityFrom
                                    ? ""
                                    : "Обязательное поле"
                            }
                            changeHandler={changeDateHandler}
                            propsDate={
                                currentRequest && currentRequest.disabilityFrom
                            }
                            minDate={moment(new Date()).subtract(36, "months")}
                            maxDate={
                                currentRequest && currentRequest.disabilityTo
                                    ? currentRequest.disabilityTo === "Invalid date"
                                        ? new Date()
                                        : new Date(currentRequest.disabilityTo)
                                    : new Date()
                            }
                        />
                        <DatePopupSelector
                            field={"disabilityTo"}
                            label={"Дата конца периода нетрудоспособности *"}
                            errorMessage={
                                currentRequest &&
                                currentRequest.isValid &&
                                currentRequest.isValid.disabilityTo
                                    ? ""
                                    : "Обязательное поле"
                            }
                            changeHandler={changeDateHandler}
                            propsDate={currentRequest && currentRequest.disabilityTo}
                            minDate={
                                currentRequest && currentRequest.disabilityFrom
                                    ? currentRequest.disabilityFrom === "Invalid date"
                                        ? new Date()
                                        : new Date(currentRequest.disabilityFrom)
                                    : new Date()
                            }
                        />
                    </div>
                    {/*<SinglePairDatesTest*/}
                    {/*    fieldFrom={"disabilityFrom"}*/}
                    {/*    labelFrom={"Дата начала периода нетрудоспособности *"}*/}
                    {/*    fieldTo={"disabilityTo"}*/}
                    {/*    labelTo={"Дата конца периода нетрудоспособности *"}*/}
                    {/*/>*/}
                </>
            )}
            {currentRequest &&
                currentRequest.disabilityFrom &&
                currentRequest.disabilityTo && (
                <div
                    style={{
                        display: "flex",
                        width: "1160px",
                        margin: "auto",
                        gap: "20px",
                    }}
                >
                    <DatePopupSelector
                        field={"dateFrom"}
                        label={"Дата начала отпуска *"}
                        errorMessage={
                            currentRequest &&
                                currentRequest.isValid &&
                                currentRequest.isValid.dateFrom
                                ? ""
                                : "Обязательное поле"
                        }
                        changeHandler={changeDateHandler}
                        propsDate={
                            currentRequest && currentRequest.dateFrom
                        }
                        minDate={
                            currentRequest && currentRequest.disabilityFrom
                                ? new Date(currentRequest.disabilityFrom)
                                : new Date()
                        }
                        //         maxDate={
                        //             currentRequest &&
                        // currentRequest.disabilityTo &&
                        // currentRequest.dateTo
                        //                 ? currentRequest.dateTo === "Invalid date"
                        //                     ? new Date(currentRequest.disabilityFrom)
                        //                     : new Date(currentRequest.dateTo)
                        //                 : null
                        //         }
                        maxDate={
                            currentRequest && currentRequest.disabilityTo
                                ? currentRequest.dateTo ? new Date(currentRequest.dateTo) : new Date(currentRequest.disabilityTo)
                                : new Date()
                        }
                    />
                    <DatePopupSelector
                        field={"dateTo"}
                        label={"Дата окончания отпуска *"}
                        errorMessage={
                            currentRequest &&
                                currentRequest.isValid &&
                                currentRequest.isValid.dateTo
                                ? ""
                                : "Обязательное поле"
                        }
                        changeHandler={changeDateHandler}
                        propsDate={currentRequest && currentRequest.dateTo}
                        minDate={
                            currentRequest && currentRequest.dateFrom
                                ? currentRequest.dateFrom === "Invalid date"
                                    ? new Date()
                                    : new Date(currentRequest.dateFrom)
                                : new Date()
                        }
                        maxDate={
                            currentRequest && currentRequest.disabilityTo
                                ? new Date(currentRequest.disabilityTo)
                                : new Date()
                        }
                    />
                </div>
            )}

            <CommentInput
                value={currentRequest && currentRequest.employeeComment}
                maxLength={255}
                stateProperty={"attachmentDocumentsCodes"}
                changeHandler={commentHandler}
                label={"Комментарий"}
                type
                parentStyle={"extra-vacation-days"}
            />
            <CustomUploader />
            <NotificationWindowRequests
                notificationWindowStatus={requestStatus}
                setNotificationWindowStatus={setRequestStatus}
                notificationWindowStyle={notificationWindow}
                blockingMessages={true}
            />
        </section>
    );
}

export default MaternityLeave;

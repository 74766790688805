import {referencesData} from "./references.data";

import RequestServices from "../../../../frontend.services/RequestServices/RequestServices";
import {Modal} from "../../Modal/Modal";
import {unsetUserRequestAction} from "../../../../redux/actions/Requests/CurrentRequest/unsetUserRequest.action";
import {setCurrentRequestAction} from "../../../../redux/actions/Requests/CurrentRequest/setCurrentRequest.action";
import HrReferencesCard from "../ModalAssets/HrReferencesCard/HrReferencesCard";
import { isTerminal } from "../../../../constants";

import { LeftArrow }  from "../../../../assets/icons/LeftArrow";
import { RightArrow }  from "../../../../assets/icons/RightArrow";

import {useDispatch} from "react-redux";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

isTerminal ? import("./ReferencesTerminal.scss") : import("./References.scss");

const References = (
    {
        currentRows,
        currentRowsHandler,
        rowsPerPage,
        userReferences,
    }

) => {

    const dispatch = useDispatch();

    const [modalShownReferences, toggleModalShownReferences] = useState(false);
    const [description] = useState("");
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + rowsPerPage;
    const currentItems = userReferences &&userReferences.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(userReferences?.length / rowsPerPage);

    function clickHandlerReferences(id) {
        toggleModalShownReferences(!modalShownReferences);
        dispatch(setCurrentRequestAction(RequestServices.getOneReference(userReferences,id)));
    }
    function closeHandlerReferences(e) {
        toggleModalShownReferences(false);
        dispatch(unsetUserRequestAction());
    }

    function handlePageClick (event) {
        const newOffset = (event.selected * rowsPerPage) % userReferences.length;
        setItemOffset(newOffset);
    }

    useEffect(() => {
        if (itemOffset < rowsPerPage) setItemOffset(0);
        setItemOffset(Math.floor(itemOffset/rowsPerPage)*rowsPerPage);
    }, [itemOffset, rowsPerPage]);

    return (
        <div>
            <div className='table-container'>
                <table className='table'>
                    <thead>
                        <tr className='table__row'>
                            {referencesData && referencesData.map((el) => {
                                return (
                                    <th
                                        key={el.id}
                                        style={{width: `${el.width}`}}
                                        className='table__row__item'
                                    >
                                        {el.title}
                                        {el.icon ?
                                            <img
                                                src={el.icon}
                                                alt="triangle"
                                                className="header-triangle"
                                            />
                                            : null
                                        }
                                    </th>
                                );
                            })

                            }
                        </tr>
                    </thead>
                    {/*<tr>*/}
                    <tbody>
                        {currentItems && currentItems.map((el, i) => (
                            <tr
                                onClick={() => (clickHandlerReferences(el.workorderid))}
                                key={i}
                                className={ "row-request" }
                            >
                                <td style={{width: isTerminal ? "111px" : "66px"}}>{el.typeText}</td>
                                <td style={{width: isTerminal ? "137px" : "163px"}}>{el.workorderid}</td>
                                <td style={{width: isTerminal ? "230px" : "391px"}}>{el.type}</td>
                                <td style={{width: isTerminal ? "211px" : "150px"}}>{el.status}</td>
                                <td
                                    onClick={(e) => e.stopPropagation()}
                                    style={{width: isTerminal ? "191px" : "218px"}}>
                                    {
                                        el.hasFile === true
                                            // TODO REFACTOR CHANGE IF LINK IS NOT NAVIGATING KEEP DOWNLOADABLE!
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            ? <a
                                                // href="/#"
                                                download
                                                target="_blank"
                                                onClick={
                                                    () => RequestServices.downloadReferenceFile(el.workorderid, el.type)
                                                }
                                                className="request-colored-link">
                                                {el.fileName}
                                            </a>
                                            : el.fileName
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {
                    !userReferences.length
                        ? <div>

                        </div>
                        : <div className={ isTerminal && "pagination-wrapper"}>
                            {!isTerminal && <div className='pagination-container'>
                                <div className='pagination-container__rows'>
                                    <div
                                        onClick={() => currentRowsHandler(5)}
                                        className={rowsPerPage === 5 ? "rowsPerPage-number-active" : "rowsPerPage-number-inactive"}>
                                        5
                                    </div>
                                    <div
                                        onClick={() => currentRowsHandler(10)}
                                        className={rowsPerPage === 10 ? "rowsPerPage-number-active" : "rowsPerPage-number-inactive"}>
                                        10
                                    </div>
                                    <div
                                        onClick={() => currentRowsHandler(20)}
                                        className={rowsPerPage === 20 ? "rowsPerPage-number-active" : "rowsPerPage-number-inactive"}>

                                        20
                                    </div>

                                    <div
                                        onClick={() => currentRowsHandler(50)}
                                        className={rowsPerPage === 50 ? "rowsPerPage-number-active" : "rowsPerPage-number-inactive"}>
                                        50
                                    </div>
                                </div>
                            </div>}
                            <ReactPaginate
                                className={"pagination"}
                                breakLabel=".."
                                nextLabel={isTerminal ? <RightArrow /> : ">"}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                pageCount={pageCount}
                                previousLabel={isTerminal ? <LeftArrow /> : "<"}
                                renderOnZeroPageCount={null}
                                forcePage={Math.floor(itemOffset/rowsPerPage)}
                            />
                        </div>


                }
            </div>

            <Modal
                shown={modalShownReferences}
                close={closeHandlerReferences}>
                <HrReferencesCard
                    description={description}
                    userRequests={userReferences}
                    close={closeHandlerReferences}
                />
            </Modal>

        </div>
    );
};

export default References;

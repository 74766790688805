import PagesPaginate from "../../components/common/Pagination/CustomPagination/PagesPaginate/PagesPaginate";
// import { PaginateItems } from "../../components/common/Pagination/CustomPagination/PaginateItems/PaginateItems";

import React, { useEffect, useState } from "react";

import "./TablePagination.scss";


export  const UsePagination = (
    arrayOfItems,
    pages,
    defaultClass
) => {

    // let currentItems, pageCount;

    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;

    // if (arrayOfItems && arrayOfItems.length) {
    //     currentItems = arrayOfItems && arrayOfItems.slice(itemOffset, endOffset);
    //     pageCount = arrayOfItems && Math.ceil(arrayOfItems.length / itemsPerPage);
    // }
    const currentItems = arrayOfItems && arrayOfItems.slice(itemOffset, endOffset);
    const pageCount = arrayOfItems && Math.ceil(arrayOfItems.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % arrayOfItems.length;
        setItemOffset(newOffset);
    };

    useEffect(() => {
        if (itemOffset < itemsPerPage) setItemOffset(0);
        setItemOffset(Math.floor(itemOffset/itemsPerPage)*itemsPerPage);
    }, [itemOffset, itemsPerPage]);

    return [
        currentItems,
        {
            // display when there are more than 1 page
            paging:
                pageCount > 0
                    ? <ul className={
                        defaultClass
                            ? `${defaultClass}__pages-paginate`
                            : "pagination-control__pages-paginate"
                    }>
                        {
                            <PagesPaginate
                                defaultClass={defaultClass}
                                pages={new Array(pageCount).fill(null).map((e, i) => i + 1)}
                                itemsPerPage={itemsPerPage}
                                pageHandler={handlePageClick}
                                currentPage={Math.ceil(itemOffset / itemsPerPage) + 1}
                                pageCount={pageCount}/>
                        }
                    </ul>
                    : <ul className={
                        defaultClass
                            ? `${defaultClass}__pages-paginate`
                            : "pagination-control__pages-paginate"}>
                    </ul>,
            // how many items should be visible in pagination
            // pages - props, if you won't provide this, then will display default
            perPage:
                pages && arrayOfItems && arrayOfItems.length > 5
                    // MAPPED PROPS
                    ? <ul className={
                        defaultClass
                            ? `${defaultClass}__per-page-paginate`
                            : "pagination-control__per-page-paginate"
                    }>
                        {
                            pages
                                .map(listItem => (
                                    <li
                                        className={
                                            defaultClass
                                                ? `${defaultClass}__per-page-paginate__item${
                                                    itemsPerPage === listItem 
                                                        ? "-selected" 
                                                        : ""
                                                }`
                                                : `pagination-control__per-page-paginate__item${
                                                    itemsPerPage === listItem 
                                                        ? "-selected" 
                                                        : ""
                                                }`
                                        }
                                        onClick={() => setItemsPerPage(listItem)}>{listItem}</li>
                                ))
                        }
                    </ul>
                    // DEFAULT
                    : <ul
                        className={
                            defaultClass
                                ? `${defaultClass}__per-page-paginate`
                                : "pagination-control__per-page-paginate"
                        }>
                        <li className={
                            defaultClass
                                ? `${defaultClass}__per-page-paginate__item${itemsPerPage === 5 ? "-selected" : ""}`
                                : `pagination-control__per-page-paginate__item${itemsPerPage === 5 ? "-selected" : ""}`
                        }
                        onClick={() => setItemsPerPage(5)}>
                            5
                        </li>
                        <li className={
                            defaultClass
                                ? `${defaultClass}__per-page-paginate__item${itemsPerPage === 10 ? "-selected" : ""}`
                                : `pagination-control__per-page-paginate__item${itemsPerPage === 10 ? "-selected" : ""}`
                        }
                        onClick={() => setItemsPerPage(10)}>
                            10
                        </li>
                        <li className={
                            defaultClass
                                ? `${defaultClass}__per-page-paginate__item${itemsPerPage === 20 ? "-selected" : ""}`
                                : `pagination-control__per-page-paginate__item${itemsPerPage === 20 ? "-selected" : ""}`
                        }
                        onClick={() => setItemsPerPage(20)}>
                            20
                        </li>
                        <li className={
                            defaultClass
                                ? `${defaultClass}__per-page-paginate__item${itemsPerPage === 50 ? "-selected" : ""}`
                                : `pagination-control__per-page-paginate__item${itemsPerPage === 50 ? "-selected" : ""}`
                        }
                        onClick={() => setItemsPerPage(50)}>
                            50
                        </li>
                    </ul>,
        },
    ];
};
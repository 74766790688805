import {
    getAdditionalInformationThunkAction,
} from "../../../../../redux/actions/User/profile/ServiceData/thunk/getAdditionalInformationThunkAction";
import BasicLine from "../../../../common/BasicLine/BasicLine";

import CustomLoader from "../../../../common/CustomLoader/CustomLoader";

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";

const AdditionalInformation = () => {
    const dispatch = useDispatch();

    const additionalInformation = useSelector(state => state.personalData.additionalInformation);
    const [additional, setAdditional] = useState(additionalInformation);

    useEffect(() => {
        !additional && dispatch(getAdditionalInformationThunkAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setAdditional(additionalInformation);
    }, [additionalInformation]);

    if (!additional) return (
        <CustomLoader />

    );

    return (
        <div className="fio">
            {additional && additional.map((el, index) => {
                return (
                    <div
                        key={index}
                    >
                        <BasicLine
                            type={el.type}
                            description={el.description}
                            styleParent={"fio"}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default AdditionalInformation;

import DropdownMenuTest from "../../../../../../common/Menu/DropdownMenuTest/DropdownMenuTest";
import { jobHours } from "../Employe/jobHours.data";
import DropdownMenu from "../../../../../../common/Menu/DropdownMenu/DropdownMenu";
import DatePopupSelector from "../../../../../../common/Inputs/Calendar/DatePopupSelector/DatePopupSelector";

import {
    addObjKeyYoArrayAction,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/addObjKeyYoArray.action";

import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";

const DayItem = ({ parentStyle, elementIndex, index, setVisible }) => {
    const dispatch = useDispatch();
    const currentRequest = useSelector(
        (state) => state.requests.currentRequest,
    );
    const subordinates = useSelector(state => state.requests.subordinates);
    const compensationTypes = useSelector(state => state.requests.compensationTypes);

    // const addItemHandler = useCallback((element) => {
    //     console.log(element);
    //     dispatch(addObjKeyYoArrayAction({
    //         arrayKey: "days",
    //         objKeyName: "jobDate",
    //         objKey: moment(date).format("YYYY-MM-DD"),
    //         indexInArray: element.days.length,
    //         elementIndex: elementIndex,
    //         fieldArray: "employees",
    //     }));
    //     // setDatesArray(prev => {
    //     //     const temp = [...prev];
    //     //     temp.push(date);
    //     //     return temp;
    //     // });
    // }, []);
    function selectDropdown(event, element, stateProperty) {
        // console.log(index);

        // setVisible(index);
        console.log(event, element, stateProperty);
        dispatch(addObjKeyYoArrayAction({
            arrayKey: "days",
            objKeyName: stateProperty,
            objKey: element.id,
            indexInArray: index,
            elementIndex: elementIndex,
            fieldArray: "employees",
        }));

    }

    function changeDateHandler(field, date) {
        // console.log(index);
        // setVisible(index);
        const value = moment(date).format("YYYY-MM-DD");
        dispatch(addObjKeyYoArrayAction({
            arrayKey: "days",
            objKeyName: field,
            objKey: value,
            indexInArray: index,
            elementIndex: elementIndex,
            fieldArray: "employees",
        }));
    }

    return (
        <div className={`${parentStyle}__employe__details`}>
            <DropdownMenuTest
                helper={
                    "Рекомендуем привлечение работника(-ов) в выходные, праздничные дни не менее, чем на 4 часа"
                }
                currentRequest={
                    currentRequest &&
                    currentRequest["employees"] &&
                    currentRequest["employees"][elementIndex] &&
                    currentRequest["employees"][elementIndex] &&
                    currentRequest["employees"][elementIndex]["days"][index]}
                styleName={"reason"}
                label={"Количество часов привлечения *"}
                listOfOptions={jobHours}
                stateProperty={"jobHours"}
                // isValidKey={
                //     currentRequest &&
                //     currentRequest.isValid &&
                //     currentRequest.isValid.structId
                //         ? currentRequest.isValid.structId
                //         : false
                // }
                selectOptionClick={selectDropdown}
            />
            <DropdownMenu

                currentRequest={currentRequest &&
                    currentRequest["employees"] &&
                    currentRequest["employees"][elementIndex] &&
                    currentRequest["employees"][elementIndex] &&
                    currentRequest["employees"][elementIndex]["days"] &&
                    currentRequest["employees"][elementIndex]["days"][index]}
                styleName={"reason"}
                label={"Порядок компенсации *"}
                listOfOptions={compensationTypes}
                stateProperty={"compensationType"}
                // isValidKey={
                //     currentRequest &&
                //     currentRequest.isValid &&
                //     currentRequest.isValid.headPid
                //         ? currentRequest.isValid.headPid
                //         : false
                // }
                selectOptionClick={selectDropdown}
            />
            {
                currentRequest && currentRequest["employees"] &&
                currentRequest["employees"][elementIndex] &&
                currentRequest["employees"][elementIndex] &&
                currentRequest["employees"][elementIndex]["days"][index] &&
                currentRequest["employees"][elementIndex]["days"][index]["compensationType"] &&
                currentRequest["employees"][elementIndex]["days"][index]["compensationType"] == 2 &&

                <DatePopupSelector
                    field={"restDate"}
                    label={<>Выберите дату отдыха<span className="required_text"> *</span></>}
                    // errorMessage={
                    //     currentRequest &&
                    //     currentRequest.isValid &&
                    //     currentRequest.isValid.dismissalDate
                    //         ? ""
                    //         : "Обязательное поле"
                    // }
                    changeHandler={changeDateHandler}
                    propsDate={
                        currentRequest &&
                        currentRequest["employees"] &&
                        currentRequest["employees"][elementIndex] &&
                        currentRequest["employees"][elementIndex] &&
                        currentRequest["employees"][elementIndex]["days"] &&
                        currentRequest["employees"][elementIndex]["days"][index] &&
                        currentRequest["employees"][elementIndex]["days"][index].restDate
                    }
                    minDate={new Date()}
                />
            }

        </div>
    );
};

export default DayItem;

import { $api } from "../../../../../services/request.config";
import { getCompensationTypesAction } from "../getCompensationTypes.action";

export const getCompensationTypesThunkAction = () => async (dispatch) => {
    try {
        const response = await $api("v1/managers/requests/rvd/compensation-types");
        console.log(response.data);
        dispatch(getCompensationTypesAction(response.data));
    } catch (e) {

    }
};

import stylesWeb from "./KnowledgeBase.module.css";
import stylesTerminal from "./KnowledgeBaseTerminal.module.css";

import Header from "../../layout/Header/Header";
import Footer from "../../layout/Footer/Footer";
import { isTerminal } from "../../../constants";

import React from "react";

const styles = isTerminal ? stylesTerminal : stylesWeb;
// const styles = stylesWeb;


const KnowledgeBase = () => {

    return (
        <>
            <Header />
            <>
                <section className={styles.container}>
                    <div className={styles.wrapper}>
                        <h1 className={styles.header}>База знаний
                            <span style={{ color: "#EEEEEE" }} className={styles.description}>(в разработке)</span>
                        </h1>
                    </div>
                </section>
            </>
            <Footer />
        </>
    );
};

export default KnowledgeBase;

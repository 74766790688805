import "./MaintenanceNotification.scss";
import {
    getMaintenanceNotificationThunkAction,
} from "../../redux/actions/User/Notification/thunk/getMaintenanceNotification.thunk.action";

import {
    clearMaintenanceNotificationAction,
} from "../../redux/actions/User/Notification/getMaintenanceNotification.action";

import { useDispatch, useSelector } from "react-redux";

import React, { useEffect, useState } from "react";

const MaintenanceNotification = () => {
    const dispatch = useDispatch();
    const visibility = useSelector((state) => state?.user?.message);
    useEffect(() => {
        dispatch(getMaintenanceNotificationThunkAction());
    }, []);


    return visibility ? (
        <div className={"maintenance-notification"}>
            <div style={{ margin: "8px" }}>
                {visibility}
            </div>
            <div
                className={"maintenance-notification__cross"}
                onClick={() => dispatch(clearMaintenanceNotificationAction())}
            >
                {/*close*/}
            </div>
        </div>
    ) : <div></div>;
};

export default MaintenanceNotification;

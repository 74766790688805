import { initialState } from "../../init/initialState";
import {
    ADD_ERR_DESCRIPTION_EMPLOYEE_LIST,
    ADD_SUBSTITUTION, CLEAR_EMPLOYEE_DETAIL_INFORMATION, CLEAR_EMPLOYEE_LIST, CLEAR_ERROR_DESCRIPTION_EMPLOYEE_LIST,
    DELETE_SUBSTITUTION, GET_EMPLOYEE_DETAIL_INFORMATION,
    GET_EMPLOYEE_LIST,
} from "../../enums/MyDivision/EmployeeList/EmployeeListTypes";
import {
    CLEAR_AVAILABLE_EMPLOYEES_DELEGATION, CLEAR_ERROR_NOTIFICATION_CONTENT,
    GET_AVAILABLE_DELEGATION_TYPES,
    GET_AVAILABLE_EMPLOYEES_DELEGATION, GET_AVAILABLE_SUBSTITUTION_TYPES, GET_ERROR_NOTIFICATION_CONTENT,
} from "../../enums/MyDivision/Delegation/AuthorityDelegation.types";
import {
    CLEAR_DELEGATIONS_IN_PROGRESS,
    GET_DELEGATIONS_IN_PROGRESS,
} from "../../enums/MyDivision/Delegation/AuthorityRevocation";

export const myDivisionReducer = (state = initialState, action) => {

    switch (action.type) {
    case GET_AVAILABLE_SUBSTITUTION_TYPES:
        return {
            ...state, availableSubstitutionTypes: action.payload,
        };
    case GET_AVAILABLE_DELEGATION_TYPES:
        return {
            ...state, availableDelegationTypes: action.payload,
        };
    case GET_AVAILABLE_EMPLOYEES_DELEGATION:
        return {
            ...state, availableEmployees: action.payload,
        };
    case CLEAR_AVAILABLE_EMPLOYEES_DELEGATION:
        const { availableEmployees } = state;
        if (availableEmployees) {
            const temp = state;
            delete temp.availableEmployees;
            return temp;
        }
        return state;
    case GET_ERROR_NOTIFICATION_CONTENT:
        return {
            ...state,
            errorNotificationContent: action.payload,
        };
    case CLEAR_ERROR_NOTIFICATION_CONTENT:
        const { errorNotificationContent } = state;
        if (errorNotificationContent) {
            const temp = state;
            delete temp.errorNotificationContent;
            return temp;
        }
        return state;
    case GET_DELEGATIONS_IN_PROGRESS:
        return {
            ...state,
            delegationsInProgress: action.payload,
        };
    case CLEAR_DELEGATIONS_IN_PROGRESS:
        const { delegationsInProgress } = state;
        if (delegationsInProgress) {
            const temp = state;
            delete temp.delegationsInProgress;
            return temp;
        }
        return state;
    case GET_EMPLOYEE_LIST:
        return { ...state, employeeList: action.payload };
    case CLEAR_EMPLOYEE_LIST:
        return { ...state, employeeList: null };
    case ADD_SUBSTITUTION:
        return {
            ...state, employeeList: state.employeeList.map(employee => {
                if (employee.pid === action.payload.pid) {
                    return {
                        ...employee, substitutions: [
                            {
                                id: action.payload.id,
                                from: action.payload.from,
                                to: action.payload.to,
                            },
                        ],
                    };
                } else {
                    return employee;
                }
            }),
        };
    case DELETE_SUBSTITUTION:
        return {
            ...state,
            employeeList: [...state.employeeList]
                .map(employee => {
                    if (employee.pid === action.payload) {
                        return {
                            ...employee,
                            substitutions: [],
                        };
                    } else {
                        return employee;
                    }
                }),
        };
    case ADD_ERR_DESCRIPTION_EMPLOYEE_LIST:
        return {
            ...state,
            processingError: action.payload,
        };
    case CLEAR_ERROR_DESCRIPTION_EMPLOYEE_LIST:
        if (state.processingError) {
            const temp = { ...state };
            delete temp.processingError;
            return temp;
        }
        return state;

    case GET_EMPLOYEE_DETAIL_INFORMATION:
        return {
            ...state,
            employeeInformation: action.payload,
        };
    case CLEAR_EMPLOYEE_DETAIL_INFORMATION:
        const temp = { ...state };
        delete temp.employeeInformation;
        return temp;

    default:
        return state;
    }
};

import left from "../../../assets/documents/leftVector.svg";
import right from "../../../assets/documents/rightVector.svg";
import { unsetUserRequestAction } from "../../../redux/actions/Requests/CurrentRequest/unsetUserRequest.action";
import {
    setCurrentRequestThunkAction,
} from "../../../redux/actions/Requests/CurrentRequest/thunk/setCurrentRequest.thunk.action";

import { setCurrentRequestAction } from "../../../redux/actions/Requests/CurrentRequest/setCurrentRequest.action";
import RequestServices from "../../../frontend.services/RequestServices/RequestServices";
import {
    getCurrentLeaderRequestThunkAction,
} from "../../../redux/actions/Requests/LeaderRequests/thunk/getCurrentLeaderRequest.thunk.action";

import { unsetCurrentDocumentAction } from "../../../redux/actions/Requests/CurrentRequest/setCurrentDocument.action";

import { useDispatch } from "react-redux";

const SwitchRequests = (props) => {
    const dispatch = useDispatch();

    function switchHandler(side, uid) {
        let index;
        if (props.isReferense) {
            index = props.userRequests.findIndex(el => el.workorderid === props.number);
        } else {
            dispatch(unsetCurrentDocumentAction());
            index = props.userRequests.findIndex(el => el.uid === uid);
        }
        if (index || index === 0 || index !== props.userRequests.length - 1) {
            if (side === "left" && index !== 0) {
                if (props.isReferense) {
                    const id = props.userRequests[index - 1];
                    dispatch(setCurrentRequestAction(RequestServices.getOneReference(props.userRequests, id.workorderid)));
                } else {
                    dispatch(unsetUserRequestAction());
                    if (props.isLeader) {
                        dispatch(getCurrentLeaderRequestThunkAction(props.userRequests[index - 1].uid, false, { setDescription: props.setDescription }));
                    } else {
                        dispatch(setCurrentRequestThunkAction(props.userRequests[index - 1].uid, true, { setDescription: props.setDescription }));
                    }
                }
            } else if ((side === "right") && (index !== props.userRequests.length - 1)) {
                if (props.isReferense) {
                    const id = props.userRequests[index + 1];
                    dispatch(setCurrentRequestAction(RequestServices.getOneReference(props.userRequests, id.workorderid)));
                } else {
                    // console.log(index !== props.userRequests.length - 1)
                    dispatch(unsetUserRequestAction());
                    if (props.isLeader) {
                        // console.log(123)
                        dispatch(getCurrentLeaderRequestThunkAction(props.userRequests[index + 1].uid, false, { setDescription: props.setDescription }));
                    } else {
                        // console.log(321)

                        dispatch(setCurrentRequestThunkAction(props.userRequests[index + 1].uid, true, { setDescription: props.setDescription }));
                    }
                }
            }
        }
    }

    return (
        <>
            {props.isLeader
                ? <div className="request-modal__leader ">
                    <div className="request-modal__leader__name">
                        {
                            props.reject
                                ? "отклонение заявки"
                                : props.leaveReasonTypeId
                        }
                    </div>
                    {
                        props.reject ?
                            <>
                                <span>
                                    {
                                        props.currentRequest.initiatorFullname
                                    }
                                </span>
                                <br />
                                <div className="request-modal__leader__orderNumber">
                                    Заявка №
                                    {
                                        props.currentRequest.orderNumber
                                    }
                                </div>
                            </>
                            :
                            <div className="request-modal__leader__worker">
                                {props.userRequests && props.userRequests[0].uid !== props.currentRequest.uid &&
                                    <img onClick={() => switchHandler("left", props.currentRequest.uid)}
                                        src={left}
                                        alt="left-vector" />
                                }
                                <span>
                                    {
                                        props.currentRequest.initiatorFullname
                                    }
                                </span>
                                {props.userRequests && props.userRequests[props.userRequests.length - 1].uid !== props.currentRequest.uid &&

                                    <img onClick={() => switchHandler("right", props.currentRequest.uid)}
                                        src={right}
                                        alt="right-vector" />
                                }
                                <div>
                                    Заявка №
                                    {
                                        props.currentRequest.orderNumber
                                    }
                                </div>
                            </div>
                    }
                </div>
                : <div className="request-modal__header">
                    {props.userRequests && props.userRequests[0]?.uid !== props.currentRequest?.uid &&
                        <img onClick={() => switchHandler("left", props.currentRequest.uid)}
                            src={left}
                            alt="left-vector" />
                    }

                    <span>
                        {
                            props.typeText
                        } №
                        {
                            props.number
                        }
                        {" "}
                        {
                            props.currentRequest.leaveTypeName
                        }
                    </span>
                    {props.userRequests && props.userRequests[props.userRequests.length - 1]?.uid !== props.currentRequest?.uid &&
                        <img onClick={() => switchHandler("right", props.currentRequest.uid)}
                            src={right}
                            alt="right-vector" />
                    }
                </div>
            }
        </>
    );
};

export default SwitchRequests;

import { isTerminal } from "../../../constants";

import UButton from "../Button/UButton/UButton";

import React from "react";

isTerminal ? import("./EmptyBodyTerminal.scss") : import("./EmptyBody.scss");

const EmptyBody = (props) => {
    return (
        <div className="empty-body">
            <UButton
                onClick={props.backHandler}
                className="empty-body__button">
                назад
            </UButton>
        </div>
    );
};

export default EmptyBody;
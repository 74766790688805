import TooltipText from "../../components/common/TooltipText/TooltipText";

import {v4 as uuidv4} from "uuid";
import moment from "moment";

class TableWeekHelper {
    static getRows(data, parentStyle, setTooltip) {
        return data.map(rawGantElement => {
            return (
                <tr key={uuidv4()}>
                    <td className={`${parentStyle}__body__department`}>
                        {rawGantElement.struct}
                    </td>
                    <td className={`${parentStyle}__body__worker`}>
                        {rawGantElement.name}
                    </td>
                    <td className={`${parentStyle}__body__vacation-days`}>
                        {rawGantElement.totalDays}
                    </td>
                    {
                        this.getWeekGantView(rawGantElement.leaves, parentStyle, setTooltip)
                    }
                </tr>
            );
        });
    }
    static getWeekGantView (data, parentStyle, setTooltip) {
        return data.length
            ? new Array(7).fill(null).map((day, dayIndex) => {
                const tooltipData = [];
                const output = [];
                // calculate week number
                // WEEK WITH VACATIONS
                // let week = moment('2022-11-08').week()
                const week = moment().week();
                // calculate date of monday of the current week
                const startOfWeek = moment().week(week).startOf("week").format("YYYY-MM-DD");
                // calculate current day in view
                const currentDay = moment(startOfWeek).add(dayIndex, "day").format("YYYY-MM-DD");
                data.forEach(period => {
                    if (moment(currentDay).isBetween(period.from, period.to)) {
                        tooltipData.push(period);
                    }
                });
                data.forEach(period => {
                    if (moment(currentDay).isBetween(period.from, period.to)
                    || moment(currentDay).isSame(period.from)
                    || moment(currentDay).isSame(period.to)) {
                        output.push(
                            <td key={uuidv4()}
                                className={`${parentStyle}__body__day-${
                                    moment().day() - 1 > dayIndex
                                        ? "regular"
                                        : moment().day() === 0 && dayIndex === 6 ? "regular" : "past" }`}>
                                <div name={"filled"}
                                    id = {uuidv4()}
                                    className={`day-${moment().day() - 1 < dayIndex
                                        ? "regular"
                                        : moment().day() === 0 && dayIndex === 6 ? "regular" : "past" }`}
                                    onMouseLeave={(e) => {
                                        setTimeout(() => setTooltip(null), 1);
                                    }}
                                    onMouseEnter={(e) => {
                                        const elem = document.getElementById(e.target.id);
                                        if (elem) {
                                            const top = Number(elem.getBoundingClientRect().y);
                                            const left = Number(elem.getBoundingClientRect().x);
                                            setTooltip(this.tooltipComponentWeek(period, Math.ceil(top), Math.ceil(left)));
                                        }
                                    }}>
                                </div>
                            </td>
                        );
                    }
                });
                return output.length ? output : <td key={uuidv4()}>{}</td>;
            })
            : new Array(7).fill(null).map((day, dayIndex) => {
                return <td key={uuidv4()}>
                    {}
                </td>;
            });
    }
    static tooltipComponentWeek (plannedMonth, top, left) {
        const data = {
            from: moment(plannedMonth.from).format("DD.MM"),
            to: moment(plannedMonth.to).format("DD.MM"),
            days: plannedMonth.days,
        };
        return (
            <div
                className={"day-regular__pop-up"}
                style={
                    {
                        top: top,
                        left: left,
                        display: "block",
                    }
                }>
                <ul key={uuidv4()}
                    className={"day-regular__pop-up__list"}>
                    <TooltipText data={data} parentStyle={"day-regular"} />
                </ul>
            </div>
        );
    }
}

export default TableWeekHelper;

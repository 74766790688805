export const AUTH_TYPE_LOGIN = {
    "SNILS": {
        type:"SNILS",
        name: "СНИЛС",
        id: "СНИЛС",
    },
    "PHONE": {
        type:"PHONE",
        name: "Телефон",
        id: "Телефон",
    },
    "EMAIL": {
        type:"EMAIL",
        name: "E-mail",
        id: "E-mail",
    },

    "EMPTY": {
        type:"EMPTY",
        name: "Выберите ID",
        id: "Выберите ID",
    },
};

export const AUTH_TYPE_ITEMS = ["SNILS", "PHONE", "EMAIL"];

import { isTerminal } from "../../../constants";
import { Modal } from "../../common/Modal/Modal";
import ModalSupport from "../../common/Auth/ModalSupport/ModalSupport";
import LoginForm from "../../common/Auth/LoginForm/LoginForm";
import ForgotPasswordModal from "../../common/Auth/ForgotPasswordModal/ForgotPasswordModal";
import { resetStateNullAction } from "../../../redux/actions/Auth/resetPasswordAction";

import LoginFormTerminal from "../../common/Auth/LoginForm/LoginFormTerminal";

import { useDispatch } from "react-redux";
import { useState } from "react";

import "./LoginPage.scss";
import "./LoginPageTerminal.scss";


const LoginPage = () => {
    const dispatch = useDispatch();

    const [modalShownSupport, toggleModalSupport] = useState(false);
    const [modalShown, toggleModal] = useState(false);

    function closeHandlerForgotPassword() {
        dispatch(resetStateNullAction());
        toggleModal(false);
    }

    function closeHandlerSupport() {
        toggleModalSupport(false);
    }

    return (
        <div className={isTerminal ? "login-container-terminal" : "login-container"}>
            <div className={isTerminal ? "login-wrapper-terminal" : "login-wrapper"}>
                {!isTerminal
                    ? <LoginForm
                        modalShownSupport={modalShownSupport}
                        toggleModalSupport={toggleModalSupport}
                        modalShown={modalShown}
                        toggleModal={toggleModal}
                    />
                    : <LoginFormTerminal
                        modalShownSupport={modalShownSupport}
                        toggleModalSupport={toggleModalSupport}
                        modalShown={modalShown}
                        toggleModal={toggleModal}
                    />
                }


            </div>
            {!isTerminal && <div className={"login-img-auth"}></div>}
            {isTerminal &&
                <img style={{ height: "100vh" }} src={require("../../../assets/authPhoto/photo.png")} alt="" />}
            <Modal showCloseButton={!isTerminal} shown={modalShown} close={closeHandlerForgotPassword}>
                <ForgotPasswordModal close={closeHandlerForgotPassword} />
            </Modal>
            <Modal showCloseButton={!isTerminal} shown={modalShownSupport} close={closeHandlerSupport}>
                <ModalSupport close={closeHandlerSupport} />
            </Modal>
        </div>
    );
};

export default LoginPage;

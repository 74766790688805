import CustomButton from "../../../../Button/CustomButton/CustomButton";
import { isTerminal } from "../../../../../../constants";

isTerminal ? import("./Error500ModalTerminal.scss") : import("./Error500Modal.scss");


const Error500Modal = (props) => {


    return (
        <div className="error500-modal">
            <div className="error500-modal__head">
                {props.modalError.sign && "Заявку не удалось направить на согласование"}
                {props.modalError.validation && "Заявку не удалось направить на валидацию"}
            </div>

            <div className="error500-modal__description">
                {
                    props.modalError.sign && (
                        <div>
                            <p>
                                Заявка сохранена в разделе «Документы»-«Заявки» как Черновик.
                                Вы можете повторно отправить ее на согласование.
                            </p>
                            <p>
                                Приносим извинения за доставленные неудобства.
                            </p>
                        </div>
                    )
                }
                {
                    props.modalError.validation && (
                        <div>
                            <p>
                                Заявка не сохранена.
                                Вы сможете позже повторно отправить ее на валидацию.
                            </p>
                            <p>
                                Приносим извинения за доставленные неудобства.
                            </p>
                        </div>
                    )
                }
            </div>
            <div style={{display:"flex", justifyContent: "space-around"}}>
                {
                    props.modalError.sign &&
                <CustomButton
                    errorModal={props.setModalError}
                    route={"/documents"}
                    title={"Перейти в документы"}
                    regular={true}/>
                }
                {
                    props.modalError.validation &&
                <CustomButton
                    errorModal={props.setModalError}
                    title={"Вернуться к заполнению"}
                    regular={true}/>
                }
            </div>
        </div>
    );
};

export default Error500Modal;

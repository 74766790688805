import Breadcrumbs from "../../../../common/Breadcrumbs/Breadcrumbs";
import HolidaysTabs from "../../../../common/Holidays/HolidaysTabs/HolidaysTabs";
import HolidaysTableWeek from "../../../../common/Holidays/HolidaysTable/HolidaysTableWeek";

import HolidaysTableCross from "../../../../common/Holidays/HolidaysTable/HolidaysTableCross";
import Header from "../../../../layout/Header/Header";
import Footer from "../../../../layout/Footer/Footer";
import HolidaysTableYear from "../../../../common/Holidays/HolidaysTable/HolidaysTableYear";

import { getDailyPercentThunkAction } from "../../../../../redux/actions/Employees/thunk/getDailyPercent.thunk.action";
import HolidaysTableQuart from "../../../../common/Holidays/HolidaysTable/HolidaysTableQuart";

import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import React, { useEffect } from "react";

import "./HolidaysPage.scss";
import "./HolidayTableCross.scss";


const HolidaysPage = () => {

    const dispatch = useDispatch();
    const [queryString, setQueryString] = useSearchParams();

    const todayPercent = useSelector(state => state.employees.todaysPercent);
    const user = useSelector(state => state.user);

    const section = queryString.get("section");
    const representation = queryString.get("repres");


    const tableCondition = {
        lastYear: <HolidaysTableYear
            tableStyle={"hp-table-year"}
            section={section} />,
        currentYear:
            <HolidaysTableYear
                tableStyle={"hp-table-year"}
                section={section} />,
        currentWeek:
            <HolidaysTableWeek
                tableStyle={"hp-table-week"} />,
        firstQuart: <HolidaysTableQuart
            tableStyle={"hp-table-quart"}
            section={section} />,
        secondQuart: <HolidaysTableQuart
            tableStyle={"hp-table-quart"}
            section={section} />,
        thirdQuart: <HolidaysTableQuart
            tableStyle={"hp-table-quart"}
            section={section} />,
        fourthQuart: <HolidaysTableQuart
            tableStyle={"hp-table-quart"}
            section={section} />,
        directEmployees:
            <HolidaysTableCross
                tableStyle={"hp-table-cross"} />,
        employees: null,
    };

    useEffect(() => {
        dispatch(getDailyPercentThunkAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (!section) {
            setQueryString({ section: "currentYear", repres: "Gant" });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [section]);


    return (
        <>
            <Header />
            <Breadcrumbs />
            <HolidaysTabs
                representation={representation}
                todayPercent={todayPercent} />
            {/*TABLE*/}
            {tableCondition[`${section}`]}

            <Footer />
        </>
    );
};

export default HolidaysPage;


import BackButton from "../../../../Button/BackButton/BackButton";
import { isTerminal } from "../../../../../../constants";
import UButton from "../../../../Button/UButton/UButton";

import React from "react";

import { useNavigate } from "react-router-dom";


isTerminal ? import("./BackHandlerModalTerminal.scss") : import("./BackHandlerModal.scss");

const BackHandlerModal = (props) => {
    const navigate = useNavigate();
    return (
        <div className="back-handler-modal">
            <div className="back-handler-modal__content">
                <div className="back-handler-modal__content__head">
                    Сохранить введенные данные?
                </div>
                <div className="back-handler-modal__content__description">
                    В противном случае все несохраненные данные будут утеряны
                </div>
            </div>
            <div className="back-handler-modal__buttons">
                <BackButton route={"/main/hr-services/"} />
                <UButton
                    onClick={() => {
                        props.handler();
                        props.close(false);
                        navigate("/main/hr-services/");
                    }}>
                    сохранить
                </UButton>
            </div>
        </div>
    );
};

export default BackHandlerModal;

export const workTimeWorkersData = [
    {
        id: "workTimeWorkersData-1",
        title: "Отпуска и дни отдыха",
        description:
            "График отпусков по сп," +
            " количество накопленных дней отпуска," +
            " сроки запланированных," +
            " прошедших отпусков и дней отдыха," +
            "  отпуска по беременности и родам," +
            " по уходу за ребенком до 3-х лет",
        path: "/divisions/work-time/holidays",
    },
    {
        id: "workTimeWorkersData-2",
        title: "График работы",
        description:
            "Установленный график работы," +
            " сроки и периодичность смен," +
            " срок контракта у подчиненных работников," +
            " оформленных по срочному трудовому договору",
        path: "#",
    },
    {
        id: "workTimeWorkersData-3",
        title: "Вахтовые сотрудники",
        description:
            "Сроки (дата начала – дата окончания) и периодичность вахт," +
            " точка сбора, точка выезда на вахту," +
            " обсерватор (номер комнаты), общежитие (номер комнаты)",
        path: "#",
    },
    {
        id: "workTimeWorkersData-4",
        title: "Заявки по рабочему времени и отпускам сотрудников",
        description:
            "Заявки для внесения изменений параметров и характеристик служебных и информационных данных ",
        path: "#",
    },
    {
        id: "workTimeWorkersData-5",
        title: "Табель учета рабочего времени",
        description:
            "Заполнение табеля учета рабочего времени сотрудников подразделения",
        path: "#",
    },
    {
        id: "workTimeWorkersData-6",
        title: "Табель учета рабочего времени",
        description:
            "Информация о командировках и служебных поездках сотрудников",
        path: "#",
    },
];

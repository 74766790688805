
import leftArrow from "../../../../../hooks/usePagination/assets/leftarrow.svg";
import rightArrow from "../../../../../hooks/usePagination/assets/rightarrow.svg";

import React from "react";

const PagesPaginate = (
    {
        pageCount,
        currentPage,
        pages,
        pageHandler,
        defaultClass,
    }
) => {
    return (
        <React.Fragment>
            {
                pages.length > 0 &&
                <li className={
                    !(pages[0] === currentPage)
                        ? defaultClass
                            ? `${defaultClass}__pages-paginate__item-prev`
                            : "pagination-control__pages-paginate__item-prev"
                        : defaultClass
                            ? `${defaultClass}__pages-paginate__item-prev-lock`
                            : "pagination-control__pages-paginate__item-prev-lock"
                }
                onClick={
                    !(pages[0] === currentPage)
                        ? (e) => pageHandler({selected: currentPage - 2})
                        : null

                }>
                    <img src={leftArrow} alt="prev" />
                </li>
            }
            {
                pages.map(e => {
                    if (Number(e) === currentPage) {
                        return <li
                            className={
                                defaultClass
                                    ? `${defaultClass}__pages-paginate__item-selected`
                                    : "pagination-control__pages-paginate__item-selected"
                            }
                            key={e}
                            onClick={() => pageHandler({selected: Number(e - 1)})}
                        >{e}</li>;
                    }
                    if (Number(e) < 3 ||
                    Number(e) === currentPage - 1 ||
                    Number(e) === currentPage + 1 ||
                    Number(e) === currentPage ||
                    Number(e) > pageCount - 1) {
                        return (
                            <li
                                className={
                                    defaultClass
                                        ? `${defaultClass}__pages-paginate__item`
                                        : "pagination-control__pages-paginate__item"
                                }
                                onClick={() => pageHandler({selected: Number(e - 1)})}
                                key={e}>
                                {e}
                            </li>);
                    } else if (Number(e) === currentPage - 2 || Number(e) === currentPage + 2) {
                        return (
                            <li
                                className={
                                    defaultClass
                                        ? `${defaultClass}__pages-paginate__item-break`
                                        : "pagination-control__pages-paginate__item-break"
                                }
                                onClick={() => pageHandler({selected: Number(e - 1)})}
                                key={e}
                            >...</li>
                        );
                    } else {
                        return null;
                    }
                })
            }
            {
                pages.length > 0 &&
                <li className={
                    !(pages[pages.length - 1] === currentPage)
                        ? defaultClass
                            ? `${defaultClass}__pages-paginate__item-next`
                            : "pagination-control__pages-paginate__item-next"
                        : defaultClass
                            ? `${defaultClass}__pages-paginate__item-next-lock`
                            : "pagination-control__pages-paginate__item-next-lock"
                }
                onClick={
                    !(pages[pages.length - 1] === currentPage)
                        ? (e) => pageHandler({selected: currentPage})
                        : null

                }>
                    <img src={rightArrow} alt="next" />
                </li>
            }
        </React.Fragment>
    );
};

export default PagesPaginate;
import { jobHours } from "./jobHours.data";

import {
    addStudentHandlerCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/addStudentHandler.currentRequest";
import bluePlus from "../../../../../../common/Inputs/DatePeriodControlled/BluePlusCircle.svg";
import {
    deleteStudentHandlerCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/deleteStudentHandler.currentRequest";
import redPlus from "../../../../../../common/Inputs/DatePeriodControlled/RedRotatedPlusCircle.svg";

import DropdownForRecruitment from "../../../../../../common/Menu/DropdownForRecruitment/DropdownForRecruitment";

import DateSelectorList from "../../../../../../common/DateSelectorList/DateSelectorList";

import DropdownMenuTest from "../../../../../../common/Menu/DropdownMenuTest/DropdownMenuTest";

import DropdownMenu from "../../../../../../common/Menu/DropdownMenu/DropdownMenu";

import DatePopupSelector from "../../../../../../common/Inputs/Calendar/DatePopupSelector/DatePopupSelector";

import {
    addObjKeyYoArrayAction,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/addObjKeyYoArray.action";

import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";

import React, { useRef, useState } from "react";
import moment from "moment";
import { element } from "prop-types";

const AddStudentsButton = (
    {
        parentStyle,
        // setState,
    },
) => {
    const dispatch = useDispatch();

    function addStudentHandler(e) {
        dispatch(addStudentHandlerCurrentRequest({
            stateName: "employees",
            data: {
                pid: null,
                days: [],
            },
        }));
    }

    return (
        <div className={`${parentStyle}__button`}>
            <span>Добавить еще одного работника</span>
            <img
                onClick={addStudentHandler}
                className={`${parentStyle}__button__blue-plus`}
                src={bluePlus}
                alt="bluePlus"
            />
        </div>
    );
};

const DeleteStudentsButton = (
    {
        parentStyle,
        // setState,
        index,
    },
) => {
    const dispatch = useDispatch();

    function deleteStudentHandler(e) {
        dispatch(deleteStudentHandlerCurrentRequest({ index, stateName: "employees" }));
    }

    return (
        <div className={`${parentStyle}__button`}>
            <span>Удалить добавленного ниже работника</span>
            <img
                onClick={deleteStudentHandler}
                className={`${parentStyle}__button__red-plus`}
                src={redPlus}
                alt="redPlus"
            />
        </div>
    );
};


const Employe = (
    {
        employee,
        parentStyle,
        index,
        employeesLength,
    },
) => {
    const dispatch = useDispatch();
    console.log(employee, index);
    const currentRequest = useSelector(
        (state) => state.requests.currentRequest,
    );
    const subordinates = useSelector(state => state.requests.subordinates);
    const compensationTypes = useSelector(state => state.requests.compensationTypes);

    const inputRef = useRef();

    function selectDropdown(event, element, stateProperty) {
        console.log(event, element, stateProperty);
        dispatch(addObjKeyYoArrayAction({
            arrayKey: null,
            objKeyName: stateProperty,
            objKey: element.pid,
            indexInArray: null,
            elementIndex: index,
            fieldArray: "employees",
        }));
    }

    return (
        <div key={uuidv4()} className={`${parentStyle}__employe`}>
            {employeesLength > 1 && index !== 0 && (
                <DeleteStudentsButton parentStyle={parentStyle} index={index} />
            )}
            <DropdownForRecruitment
                fieldArray={"employees"}
                index={index}
                currentRequest={currentRequest && currentRequest["employees"] && currentRequest["employees"][index] && currentRequest["employees"][index]}
                helper={
                    // eslint-disable-next-line max-len
                    "В списке не отображаются сотрудники, не перешедшие на ЭДТО и сотрудники, ЭДТО которых приостановлено." +
                    " Статус ЭДТО сотрудников можно узнать в разделе «Список сотрудников». "
                }
                styleName={"recruitment"}
                label={"Выберите работника для привлечения к работе в выходные, праздничные дни *"}
                listOfOptions={subordinates && subordinates}
                stateProperty={"pid"}
                selectOptionClick={selectDropdown}
            />

            <DateSelectorList
                parentStyle={parentStyle}
                element={employee}
                elementIndex={index}
            />


            {index === employeesLength - 1 && (
                <AddStudentsButton parentStyle={parentStyle} />
            )}
        </div>
    );
};

export default Employe;

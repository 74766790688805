import "moment/locale/ru";
import $api from "../redux/services/axiosService";

// import loginPage from "../components/pages/LoginPage/LoginPage";
import createPeriod from "date-period";
import moment from "moment";

class LeavesService {

    static getCurrentYear() {
        const date = new Date();
        return date.getFullYear();
    }

    static addToCurrentDate(year = 0, month = 0, day = 0) {
        const date = new Date();
        if (year) date.setFullYear(date.getFullYear() + month);
        if (month) date.setMonth(date.getMonth() + month);
        if (day) date.setDate(date.getDate() + day);
        return date;
    }

    static getYearPrevMonth() {
        return this.addToCurrentDate(0, -1, 0).getFullYear() ;
    }

    static getCurrentMonth() {
        const date = new Date();
        return date.getMonth() + 1;
    }

    static getYearPeriod(currentYear) {
        const firstDayCurrYear = new Date(currentYear, 0, 1);
        const lastDayCurrYear = new Date(currentYear, 11, 31);
        const periodOfYear = createPeriod({
            start: firstDayCurrYear,
            duration: "P1D",
            end: moment(lastDayCurrYear).add(1, "d"),
        });
        return periodOfYear.map((el) => ({ leave: false, date: el }));
    }

    static async getItemsPeriod(currentYear) {
        const response = await $api(`v1/p/po/leaves/${currentYear}`);

        try {
            const temp = { ...response };
            const result = temp.data.map((el) => (
                el.items.length !== 0 ?
                    el.items.map((e) => {
                        if (new Date(e.from).getTime() === new Date(e.to).getTime()) {
                            // console.log( [new Date(e.from)])
                            const period = [new Date(e.from)];
                            return { ...e, itemsPeriod: period };

                        }
                        const period = createPeriod({
                            start: new Date(e.from),
                            duration: "P1D",
                            end: new Date(moment(e.to).add(1, "d")),
                        });
                        return { ...e, itemsPeriod: period };
                    })
                    :
                    el.items.map((e) => {
                        return { ...e };
                    })
            ));

            const num = result.map((el) => {
                const allPlan = [];
                const noPlan = [];
                for (let i = 0; i < el.length; i++) {
                    if (el[i].isCompleted === false) {
                        for (let j = 0; j < el[i].itemsPeriod.length; j++) {
                            allPlan.push(el[i].itemsPeriod[j]);
                        }
                    } else {
                        for (let j = 0; j < el[i].itemsPeriod.length; j++) {
                            noPlan.push(el[i].itemsPeriod[j]);
                        }
                    }
                }
                const temporary = this.getYearPeriod(currentYear);
                const result = temporary.map((el) => {
                    for (let i = 0; i < allPlan.length; i++) {
                        if (new Date(el.date).getTime() === new Date(allPlan[i]).getTime()) {
                            el.leave = true;
                            el.plan = true;
                        }
                    }
                    for (let i = 0; i < noPlan.length; i++) {
                        if (new Date(el.date).getTime() === new Date(noPlan[i]).getTime()) {
                            el.leave = true;
                            el.plan = false;
                        }
                    }

                    return el;
                },
                );
                return result;
            },
            );
            return num;
        } catch (e) {
            console.log(e);
        }

    }

    static async getPlanNoPlanPeriod(currentYear) {
        const response = await $api(`v1/p/po/leaves/${currentYear}`);
        try {
            const temp = { ...response };
            const result = temp.data.map((el) => (
                el.items.length !== 0 ?
                    el.items.map((e) => {
                        if (new Date(e.from).getTime() === new Date(e.to).getTime()) {
                            const period = [new Date(e.from)];
                            return { ...e, itemsPeriod: period };

                        }
                        const period = createPeriod({
                            start: new Date(e.from),
                            duration: "P1D",
                            end: new Date(moment(e.to).add(1, "d")),
                        });
                        return { ...e, itemsPeriod: period };
                    })
                    :
                    el.items.map((e) => {
                        return { ...e };
                    })
            ));

            const num = result.map((el) => {
                const allPlan = [];
                const noPlan = [];
                for (let i = 0; i < el.length; i++) {
                    if (el[i].isCompleted === true) {
                        for (let j = 0; j < el[i].itemsPeriod.length; j++) {
                            noPlan.push(el[i].itemsPeriod[j]);
                        }
                    } else {
                        for (let j = 0; j < el[i].itemsPeriod.length; j++) {
                            allPlan.push(el[i].itemsPeriod[j]);
                        }
                    }
                }

                return { ...el, noPlan: noPlan, allPlan: allPlan };
            },
            );
            return num;
        } catch (e) {
            // console.log(e)
        }
    }

    static getDayNames(period) {

        for (let i = 0; i < period.length; i++) {
            if (period[i].dayNumber === 1) {
                period[i].dayShortName = "пн";
            } else if (period[i].dayNumber === 2) {
                period[i].dayShortName = "вт";
            } else if (period[i].dayNumber === 3) {
                period[i].dayShortName = "ср";
            } else if (period[i].dayNumber === 4) {
                period[i].dayShortName = "чт";
            } else if (period[i].dayNumber === 5) {
                period[i].dayShortName = "пт";
            } else if (period[i].dayNumber === 6) {
                period[i].dayShortName = "сб";
            } else if (period[i].dayNumber === 7) {
                period[i].dayShortName = "вс";
            }
            if (new Date(period[i].date).getTime() < new Date().getTime()) {
                period[i].dayPass = true;
            }
        }
        return period;
    }

    static async getWorkPeriodForDayPicker(period) {
        const allPlan = [];
        const noPlan = [];
        // console.log('WOOOORK SHELDULE: ', period)
        const filteredArr = period.filter((el) => (el.dayTypeName === "рабочий") || (el.dayTypeName === "рабочий сокращенный"));
        for (let i = 0; i < filteredArr.length; i++) {
            if (new Date(filteredArr[i].date).getTime() < new Date().getTime()) {
                noPlan.push(new Date(filteredArr[i].date));
            } else {
                allPlan.push(new Date(filteredArr[i].date));
            }
        }
        return { allPlan: allPlan, noPlan: noPlan };
    }

}

export default LeavesService;

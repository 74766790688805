import UButton from "../../../Button/UButton/UButton";
import {
    unsetCurrentHrDocumentAction,
} from "../../../../../redux/actions/Requests/Hr-documents/unsetCurrentHrDocument.action";
import SwitchRequests from "../../../SwitchRequests/SwitchRequests";
import {
    setViewedHrDocumentThuunkAction,
} from "../../../../../redux/actions/Requests/Hr-documents/thunk/setViewedHrDocument.thuunk.action";
import {
    signingSignHrDocumentThunkAction,
} from "../../../../../redux/actions/Requests/Hr-documents/thunk/signingSignHrDocument.thunk.action";
import PhoneVerify from "../../../Auth/PhoneVerify/PhoneVerify";
import {
    unepSmsConfirmationThunkAction,
} from "../../../../../redux/actions/Requests/Hr-documents/thunk/unepSmsConfirmation.thunk.action";
import { isTerminal } from "../../../../../constants";

import left from "../../../../../assets/documents/leftVector.svg";

import right from "../../../../../assets/documents/rightVector.svg";

import { ClipLoader } from "react-spinners";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

isTerminal ? import("./HrDocumentCardTerminal.scss") : import("./HrDocumentCard.scss");

const HrDocumentCard = (
    {
        helpers,
        close,
        approveIdentityWith,
        isLeader,
        hrDocuments,
        openReportRequest,
    },
) => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const latestCertificate = useSelector(state => state.personalData.latestCertificate);
    const currentHrDocument = useSelector(state => state.requests.currentHrDocument);

    const [, setSing] = useState(false);
    const [loader, setLoader] = useState(false);
    const [signByUnep, setSignByUnep] = useState({});
    const [description, setDescription] = useState("");
    const [input, setInput] = useState("");
    const [counter, setCounter] = useState(60);
    const [active, setActive] = useState(false);
    const [, setAuthNumber] = useState({});
    const [markDocuments, setMarkDocuments] = useState({});

    function submitHandler(event) {
        event.preventDefault();

        dispatch(
            unepSmsConfirmationThunkAction({
                smsCode: input,
                taskId: signByUnep.id,
                setDescription,
                setLoader,
            }),
        );
    }

    function isLoading(docId) {
        if (markDocuments[docId] === undefined) return false;
        return markDocuments[docId] === "";
    }

    function isDisabled(docId) {
        return markDocuments[docId] !== undefined;
    }

    function markDocument(packageId, docId, workerId, typeViewTo, isViewed) {
        dispatch(
            setViewedHrDocumentThuunkAction(
                packageId,
                docId,
                workerId,
                typeViewTo,
                helpers,
                isViewed,
            ),
        );
        dispatch(unsetCurrentHrDocumentAction());
    }

    function disableReporAnError(el) {
        if (el.isSigned === true) {
            return true;
        } else if (el.isSigned === false) {
            if (el.signatureType === "NotEndorsing") {
                return true;
            } else if (currentHrDocument.request.hasAssignment === false) {
                return true;
            }
        } else {
            return false;
        }
    }

    function signDocument(packageId, docId, workerId, el) {
        setLoader(false);

        setSignByUnep({});
        setMarkDocuments((prev) => {
            const temp = { ...prev };
            temp[`${docId}`] = "";
            return temp;
        });
        if (el) {
            if (el.isInProcessing || el.extra.isInProcessing) {
                setMarkDocuments((prev) => {
                    const temp = { ...prev };
                    temp[`${docId}`] = "Документ находится в обработке";
                    return temp;
                });
            } else {
                if (el.isSignRequired) {
                    if (el.signatureType == null) {
                        if (localStorage.getItem("documentSignType") > 0) {
                            if (
                                currentHrDocument.request.hasAssignment ===
                                true ||
                                currentHrDocument.request.hasAssignment ===
                                undefined
                            ) {
                                if (el.edsType === "NQES") {
                                    if (latestCertificate) {
                                        if (
                                            new Date(
                                                latestCertificate.issueDate,
                                            ) &&
                                            new Date(
                                                latestCertificate.validDate,
                                            ).getTime() > new Date().getTime()
                                        ) {
                                            if (
                                                el.isCompanySignRequired === false ||
                                                el.isSignedByCompany === true
                                            ) {

                                                dispatch(
                                                    signingSignHrDocumentThunkAction(
                                                        packageId,
                                                        docId,
                                                        workerId,
                                                        {
                                                            setLoader: setLoader,
                                                            setSignByUnep: setSignByUnep,
                                                            setMarkDocuments: setMarkDocuments,
                                                        },
                                                    ),
                                                );

                                            } else {
                                                setMarkDocuments((prev) => {
                                                    const temp = { ...prev };
                                                    temp[`${docId}`] =
                                                        "Необходимо подойти в опер.отдел ОЦО для подписи";
                                                    return temp;
                                                });
                                            }
                                        } else {
                                            setMarkDocuments((prev) => {
                                                const temp = { ...prev };
                                                temp[`${docId}`] =
                                                    "Необходимо оформить УНЭП для подписи";
                                                return temp;
                                            });
                                        }
                                    } else {
                                        setMarkDocuments((prev) => {
                                            const temp = { ...prev };
                                            temp[`${docId}`] =
                                                "Необходимо обновить данные по УНЭП сертификату";
                                            return temp;
                                        });
                                    }
                                } else if (el.edsType === "SES") {
                                    if (
                                        el.isCompanySignRequired === false ||
                                        el.isSignedByCompany === true
                                    ) {

                                        dispatch(
                                            signingSignHrDocumentThunkAction(
                                                packageId,
                                                docId,
                                                workerId,
                                                {
                                                    setLoader: setLoader,
                                                    setSignByUnep: setSignByUnep,
                                                    setMarkDocuments: setMarkDocuments,
                                                },
                                            ),
                                        );

                                    } else {
                                        setMarkDocuments((prev) => {
                                            const temp = { ...prev };
                                            temp[`${docId}`] =
                                                "Необходимо подойти в опер.отдел ОЦО для подписи";
                                            return temp;
                                        });
                                    }
                                } else {
                                    setMarkDocuments((prev) => {
                                        const temp = { ...prev };
                                        temp[`${docId}`] =
                                            "Необходимо подойти в опер.отдел ОЦО для подписи";
                                        return temp;
                                    });
                                }
                            } else {
                                setMarkDocuments((prev) => {
                                    const temp = { ...prev };
                                    temp[`${docId}`] =
                                        "Необходимо подойти в опер.отдел ОЦО для подписи";
                                    return temp;
                                });
                            }
                        } else {
                            setMarkDocuments((prev) => {
                                const temp = { ...prev };
                                temp[`${docId}`] =
                                    "Необходимо подойти в опер.отдел ОЦО для подписи";
                                return temp;
                            });
                        }
                    } else {
                        if (el.signatureType === "NotEndorsing") {
                            setMarkDocuments((prev) => {
                                const temp = { ...prev };
                                temp[`${docId}`] =
                                    "Документ находится на доработке";
                                return temp;
                            });
                        } else if (el.signatureType === "Endorsing") {
                            setMarkDocuments((prev) => {
                                const temp = { ...prev };
                                temp[`${docId}`] = "Подписан ЭЦП";
                                return temp;
                            });
                        } else {
                            setMarkDocuments((prev) => {
                                const temp = { ...prev };
                                temp[`${docId}`] = "Ошибка";
                                return temp;
                            });
                        }
                    }
                } else {
                    if (el.isViewed) {
                        setMarkDocuments((prev) => {
                            const temp = { ...prev };
                            temp[`${docId}`] = "Вы ознакомились с документом";
                            return temp;
                        });
                    }
                }
            }
        }
    }


    useEffect(() => {
        if (localStorage.getItem("workerSignType")) {
            setSing(true);
        }
    }, []);


    // TODO REFACTOR MARKUP!!!

    return !currentHrDocument
        ? (<div
            className="request-modal-loader"
        >
            <ClipLoader color="#6DCDF6" />
        </div>)
        : signByUnep.phoneLastNumbers
            ? (<div className="verification-hr-sms">
                {
                    loader
                        ? (<ClipLoader />)
                        : description
                            ? (<div className="verification-hr-sms">
                                <p className="verification-hr-sms__description">
                                    {description}
                                </p>
                                <UButton onClick={close} className="request-modal__button">вернуться</UButton>
                            </div>)
                            : (<PhoneVerify
                                input={input}
                                setInput={setInput}
                                submitHandler={submitHandler}
                                styleParent={"verification-modal__phone-form"}
                                unep={true}
                                counter={counter}
                                setCounter={setCounter}
                                active={active}
                                setActive={setActive}
                                // authNumber={authNumber}
                                approveIdentityWith={approveIdentityWith}
                                setToNullAuthNumber={setAuthNumber}
                                phone={`+7 ( *** ) *** - ${signByUnep.phoneLastNumbers}`}
                            />
                            )
                }
            </div>)
            : (<>
                <div className="request-modal-hr">
                    <div className="request-modal-hr__header">
                        {/*<SwitchRequests*/}
                        {/*    typeText={"Кадровый документ"}*/}
                        {/*    isLeader={isLeader}*/}
                        {/*    userRequests={hrDocuments}*/}
                        {/*    currentRequest={currentHrDocument}*/}
                        {/*    number={currentHrDocument.request.documents[0].registrationNumber}*/}
                        {/*/>*/}
                        <div className="request-modal__header">
                            {/*{props.userRequests[0]?.uid !== props.currentRequest?.uid &&*/}
                            {/*    <img onClick={() => switchHandler("left", props.currentRequest.uid)}*/}
                            {/*         src={left}*/}
                            {/*         alt="left-vector" />*/}
                            {/*}*/}

                            <span>
                     Кадровый документ №
                                {
                                    currentHrDocument.request.documents[0].registrationNumber
                                }
                                {" "}
                                {
                                    currentHrDocument.leaveTypeName
                                }
                            </span>
                            {/*{props.userRequests[props.userRequests.length - 1]?.uid !== props.currentRequest?.uid &&*/}
                            {/*    <img onClick={() => switchHandler("right", props.currentRequest.uid)}*/}
                            {/*         src={right}*/}
                            {/*         alt="right-vector" />*/}
                            {/*}*/}
                        </div>
                    </div>
                    <div className="request-modal-hr__table">
                        {
                            currentHrDocument &&
                                Object.keys(currentHrDocument.requestCard)
                                    .map((el, index) => {
                                        return (
                                            <div key={index} className="request-modal-hr__table__line">
                                                <div className="left-column">{el}</div>
                                                <div className="right-column">
                                                    {currentHrDocument.requestCard[el]}
                                                </div>
                                            </div>
                                        );
                                    })
                        }
                        {
                            <div className="request-modal__table__line">
                                <div className="left-column docsLine">Документы</div>
                                <div className="right-column">
                                    {
                                        currentHrDocument.request.documents &&
                                            currentHrDocument.request.documents
                                                .map((el, i) => {
                                                    return (
                                                        <div key={i} className="document-uploader">
                                                            <span className="document-uploader__span">
                                                            Для просмотра кадрового документа, кликните по
                                                            его наименованию, и после этого станут
                                                            доступны функции “Ознакомиться” и “Ознакомиться с
                                                            комментарием”, в случае если вы обнаружите
                                                            ошибки или неточности в документе.
                                                            </span>
                                                            <div>
                                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                <a
                                                                    onClick={() =>
                                                                        markDocument(
                                                                            currentHrDocument.request.packageId,
                                                                            el.docId,
                                                                            currentHrDocument.request.workerId,
                                                                            currentHrDocument.request.typeViewTo,
                                                                            el.isViewed,
                                                                        )
                                                                    }
                                                                    target="_blank"
                                                                    className="custom-uploader__item__name"
                                                                >
                                                                    {el.name}
                                                                </a>
                                                            </div>
                                                            <div className={"document-uploader__button-container"}>
                                                                {
                                                                    el.isSignRequired
                                                                        ? (el.isViewed === true
                                                                            ? (el.extra.isRejectable
                                                                                ? (<>
                                                                                    <UButton
                                                                                        className="request-modal__button"
                                                                                        onClick={() =>
                                                                                            openReportRequest(
                                                                                                currentHrDocument.request,
                                                                                                i,
                                                                                            )
                                                                                        }
                                                                                        disabled={disableReporAnError(
                                                                                            el,
                                                                                        )}
                                                                                    >сообщить об ошибке</UButton>
                                                                                    <UButton
                                                                                        loading={isLoading(el.docId)}
                                                                                        className="request-modal__button"
                                                                                        disabled={
                                                                                            el.isSigned || isDisabled(el.docId)
                                                                                        }
                                                                                        onClick={() =>
                                                                                            signDocument(
                                                                                                currentHrDocument.request.packageId,
                                                                                                el.docId,
                                                                                                currentHrDocument.request.workerId,
                                                                                                el,
                                                                                            )
                                                                                        }
                                                                                    >{!el.isSigned ? "подписать" : "подписано"}
                                                                                    </UButton>
                                                                                </>)
                                                                                : (<UButton
                                                                                    loading={isLoading(el.docId)}
                                                                                    className="request-modal__button"
                                                                                    disabled={el.isSigned || isDisabled(el.docId)}
                                                                                    onClick={() =>
                                                                                        signDocument(
                                                                                            currentHrDocument.request.packageId,
                                                                                            el.docId,
                                                                                            currentHrDocument.request.workerId,
                                                                                            el,
                                                                                        )
                                                                                    }>{!el.isSigned ? "подписать" : "подписано"}
                                                                                </UButton>
                                                                                )
                                                                            ) : el.extra.isRejectable
                                                                                ? (<>
                                                                                    <UButton
                                                                                        className="request-modal__button"
                                                                                        onClick={() =>
                                                                                            openReportRequest(currentHrDocument.request, i)
                                                                                        }
                                                                                        disabled={true}
                                                                                    >сообщить об ошибке</UButton>
                                                                                    <UButton
                                                                                        className="request-modal__button"
                                                                                        disabled={true}
                                                                                        onClick={() =>
                                                                                            signDocument(
                                                                                                currentHrDocument.request.packageId,
                                                                                                el.docId,
                                                                                                currentHrDocument.request.workerId,
                                                                                                el)
                                                                                        }
                                                                                    >
                                                                                        {!el.isSigned ? "подписать" : "подписано"}
                                                                                    </UButton>
                                                                                </>)
                                                                                : (<UButton
                                                                                    className="request-modal__button"
                                                                                    disabled={true}
                                                                                    onClick={() =>
                                                                                        signDocument(
                                                                                            currentHrDocument.request.packageId,
                                                                                            el.docId,
                                                                                            currentHrDocument.request.workerId,
                                                                                            el,
                                                                                        )
                                                                                    }
                                                                                >{
                                                                                        !el.isSigned
                                                                                            ? "подписать"
                                                                                            : "подписано"
                                                                                    }</UButton>))
                                                                        : (<UButton
                                                                            className="request-modal__button"
                                                                            disabled={true}
                                                                        >
                                                                            {
                                                                                el.isViewed === true
                                                                                    ? "ознакомлено"
                                                                                    : "ознакомитьcя"
                                                                            }
                                                                        </UButton>
                                                                        )
                                                                }
                                                            </div>
                                                            {
                                                                loader
                                                                    ? (<ClipLoader color="#6DCDF6" />)
                                                                    : markDocuments[`${el.docId}`]
                                                                        ? (<span
                                                                            className="document-uploader__error-text">
                                                                            {
                                                                                markDocuments[`${el.docId}`]
                                                                            }
                                                                            <br />
                                                                            <br />
                                                                            {
                                                                                markDocuments[`${el.docId}`] ===
                                                                                "Необходимо оформить УНЭП для подписи" &&
                                                                                (<div
                                                                                    style={{ fontSize: "16px" }}
                                                                                    className="package-colored-link"
                                                                                    onClick={() =>
                                                                                        navigate(
                                                                                            "/profile/eds-release/unep",
                                                                                        )
                                                                                    }
                                                                                >
                                                                                        Оформить
                                                                                        УНЭП
                                                                                </div>
                                                                                )}
                                                                        </span>)
                                                                        : null
                                                            }
                                                        </div>
                                                    );
                                                })}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </>
            );
};

export default HrDocumentCard;

import ImportantTasks from "./ImportantTasks/ImportantTasks";
import UnpaidPractice from "./UnpaidPractice/UnpaidPractice";

import PersonalAllowance from "./PersonalAllowance/PersonalAllowance";

import RecruitmentToWorkOnHolidays from "./RecruitmentToWorkOnHolidays/RecruitmentToWorkOnHolidays";

import { mode } from "../../../../../app/mode";

import {
    getCurrentRequestThunkAction,
} from "../../../../../redux/actions/Requests/CurrentRequest/thunk/getCurrentRequest.thunk.action";

import { isTerminal } from "../../../../../constants";
import {
    clearCurrentRequestAction,
} from "../../../../../redux/actions/Requests/CurrentRequest/clearCurrentRequest.action";

import { clearAttachmentsAction } from "../../../../../redux/actions/Requests/Attachments/clearAttachments.action";
import Breadcrumbs from "../../../../common/Breadcrumbs/Breadcrumbs";

import Header from "../../../../layout/Header/Header";
import Footer from "../../../../layout/Footer/Footer";

import { useDispatch } from "react-redux";
import React, { memo, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

!isTerminal && import("./styles.scss");


function LrPage() {
    const params = useParams();
    // const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const queryId = searchParams.get("id");


    const [, setRequestOption] = useState(null);

    // NOTIFICATION IN CONTROL PROCEDURES CONTENT
    const [requestStatus, setRequestStatus] = useState(false);


    useEffect(() => {
        dispatch(clearAttachmentsAction());
        if (queryId) {
            dispatch(getCurrentRequestThunkAction(queryId, setRequestOption));
        }
        return () => {
            dispatch(clearCurrentRequestAction());
        };
    }, [dispatch, queryId]);

    return (
        <>
            <Header />
            <Breadcrumbs />
            {Number(params.id) === 15 && (
                <UnpaidPractice
                    requestStatus={requestStatus}
                    setRequestStatus={setRequestStatus}
                />
            )}
            {Number(params.id) === 16 && (
                <ImportantTasks
                    requestStatus={requestStatus}
                    setRequestStatus={setRequestStatus}
                />
            )}
            {
                mode === "TEST" || mode === "UNSTABLE" ?
                    Number(params.id) === 17 && (
                        <RecruitmentToWorkOnHolidays
                            requestStatus={requestStatus}
                            setRequestStatus={setRequestStatus}
                        />
                    )
                    : null
            }

            {Number(params.id) === 19 && (
                <PersonalAllowance
                    requestStatus={requestStatus}
                    setRequestStatus={setRequestStatus}
                />
            )}
            {/*{Number(params.id) === 1 ? (*/}
            {/*    <RequestButtons*/}
            {/*        sendHandler={sendHandler}*/}
            {/*        saveHandler={saveHandler}*/}
            {/*        backHandler={backHandler}*/}
            {/*    />*/}
            {/*) : (*/}
            {/*    <EmptyBody backHandler={backHandler} />*/}
            {/*)}*/}

            {/*/!*BACK*!/*/}
            {/*<Modal shown={modalStatus} close={setModalStatus}>*/}
            {/*    <BackHandlerModal*/}
            {/*        handler={saveHandler}*/}
            {/*        close={setModalStatus}*/}
            {/*    />*/}
            {/*</Modal>*/}
            {/*/!*SAVE SUCCESS*!/*/}
            {/*<Modal shown={modalSuccessStatus} close={setModalSuccessStatus}>*/}
            {/*    <SaveSuccessModal*/}
            {/*        route={"/main/hr-services/work-time"}*/}
            {/*        close={setModalSuccessStatus}*/}
            {/*    />*/}
            {/*</Modal>*/}
            {/*/!*REQUEST SEND*!/*/}
            {/*<ModalBanOverlay shown={modalSentStatus} close={setModalSentStatus}>*/}
            {/*    <RequestVerification*/}
            {/*        onValidation={onValidation}*/}
            {/*        setOnValidation={setOnValidation}*/}
            {/*        setModalError={setModalError}*/}
            {/*        sendButtonLoading={sendButtonLoading}*/}
            {/*        route={"/main/hr-services/work-time"}*/}
            {/*        setModalSentStatus={setModalSentStatus}*/}
            {/*    />*/}
            {/*</ModalBanOverlay>*/}
            {/*{modalError.status && (*/}
            {/*    <ModalBanOverlay shown={modalError} close={setModalError}>*/}
            {/*        <Error500Modal*/}
            {/*            modalError={modalError}*/}
            {/*            setModalError={setModalError}*/}
            {/*        />*/}
            {/*    </ModalBanOverlay>*/}
            {/*)}*/}

            <Footer />
        </>
    );
}

export default memo(LrPage);

import { isTerminal } from "../../../../constants";
import MultiWidget from "../../MultiWidget/MultiWidget";
import { userWidgetsThunkAction } from "../../../../redux/actions/User/profile/Widgets/Thunk/userWidgetsThunkAction";
import DropdownForAppointment from "../DropdownForAppointment/DropdownForAppointment";


import { $api } from "../../../../redux/services/request.config";

import { isLeaderThunkAction } from "../../../../redux/actions/Auth/Thunk/isLeader.thunk.action";

import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

isTerminal ? import("./InformationWidgetsPartTerminal.scss") : import("./InformationWidgetsPart.scss");


const InformationWidgetsPart = (
    {
        handleClick,
        clickHandlerWidgets,
    },
) => {

    const dispatch = useDispatch();

    const widgets = useSelector((state) => state.personalData.widgets);
    const user = useSelector(state => state.user);
    const [fio] = useState(JSON.parse(localStorage.getItem("fio")));
    const [appointment] = useState(localStorage.getItem("appointment"));
    const [requestOption, setRequestOption] = useState(null);
    console.log(requestOption);

    function selectOptionClick(event, element, stateProperty) {
        localStorage.setItem("appointment", element.name);
        setRequestOption(element.id);
        console.log($api.defaults.headers);
        $api.defaults.headers.common["pid"] = element.id;
        dispatch(userWidgetsThunkAction());
        dispatch(isLeaderThunkAction());

    }

    useEffect(() => {
        dispatch(userWidgetsThunkAction());

        // setRequestOption();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className="information-part">
            <div className="information-part__name">
                {fio ?
                    <span className="information-part__name__fio">
                        {
                            fio.lastNameRu
                        }
                        <br />
                        {
                            fio.firstNameRu
                        }
                        {" "}
                        {
                            fio.secondNameRu
                        }
                    </span>
                    :
                    <p style={{ minWidth: "343px" }}>
                        <br />
                    </p>

                }

                {
                    appointment ?
                        <DropdownForAppointment
                            // optionsLoader={typesListLoader}
                            styleName={"lists"}
                            // label={"заявки по рабочему времени"}
                            initialMenuMessage={localStorage.getItem("appointment") ? localStorage.getItem("appointment") : "Выберите назначение"}
                            listOfOptions={user && user.appointments ? user.appointments : []}
                            // stateProperty={"leaveTypeId"}
                            // currentRequest={currentRequest}
                            selectOptionClick={selectOptionClick}
                            // showBackButton={isTerminal}
                        />
                        // ? <p className='information-part__name__appointment'>
                        //     {appointment}
                        // </p>
                        : <p>

                        </p>
                }

            </div>
            {
                widgets && <MultiWidget
                    data={widgets}
                    styleParent={"information-part__widgets"} />
            }
        </div>
    );
};

export default InformationWidgetsPart;

import { AUTH_TYPE_ITEMS, AUTH_TYPE_LOGIN } from "../../LoginForm/loginForm.const";
import line from "../../../../../assets/icons/authIcons/Line.svg";

export const LoginTypeSelectInput = ({onSelectOption, visible, selectedOption, className}) => {

    return (
        <div>
            <ul className={ visible ? `${className}` : `${className}__disable`}>
                {
                    AUTH_TYPE_ITEMS.map((idx, i) => {

                        const el = AUTH_TYPE_LOGIN[idx];
                        return (

                            <li id={el.type} onClick={onSelectOption} key={i}>
                                <div id={el.type} onClick={onSelectOption} className="passWrapper-line">
                                    {el.name}
                                </div>
                                {
                                    selectedOption === el.type
                                        ? (<>
                                            <img src={line} alt="line" />
                                        </>)
                                        : null
                                }
                            </li>

                        );
                    })}
            </ul>
        </div>
    );
};
import { links } from "./navMenu.data";

import { isTerminal } from "../../../../constants";
import {
    getUserHrDocsThunkAction,
} from "../../../../redux/actions/Requests/Hr-documents/thunk/getUserHrDocs.thunk.action";
import { isLeaderThunkAction } from "../../../../redux/actions/Auth/Thunk/isLeader.thunk.action";
import {
    getUserRequestsThunkAction,
} from "../../../../redux/actions/Requests/GetUserRequests/thunk/getUserRequests.thunk.action";

import {
    getActualDocsThunkAction,
} from "../../../../redux/actions/Requests/Hr-documents/thunk/getActualDocsForBubble.thunk.action";

import {
    getActualDocumentsThunkAction,
} from "../../../../redux/actions/Documents/ActualDocuments/thunk/getActualDocuments.thunk.action";

import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

isTerminal ? import("./NavMenuTerminal.css") : import("./NavMenu.css");

const NavMenu = () => {

    const dispatch = useDispatch();

    // const requests = useSelector((state) => state.requests);
    const unseenDocuments = useSelector((state) => state.personalData.unseenDocuments);
    // const [userRequests] = useState(requests.userRequests);
    // const [hrDocuments] = useState(requests.hrDocuments);
    // const [leaderRequests] = useState(requests.leaderRequests);
    const [leader, setLeader] = useState(localStorage.getItem("isLeader"));
    const [docs, setDocs] = useState(0);


    // const [docs, setDocs] = useState(false);
    const [bubble, setBubble] = useState(null);

    useEffect(() => {
        // dispatch(getActualDocsThunkAction());
        dispatch(getActualDocumentsThunkAction());
        setDocs(unseenDocuments);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setBubble(docs);
    }, [docs]);

    useEffect(() => {
        dispatch(isLeaderThunkAction());

        //     // dispatch(getUserRequestsThunkAction());
        //     // dispatch(getUserHrDocsThunkAction());
        //     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className="main-menu-container">
            <div className="nav-menu__items">
                {links &&
                    links.map((el) => {
                        return (
                            <NavLink
                                key={el.id}
                                to={el.path}
                                onClick={
                                    (localStorage.getItem("agreement") > 0 &&
                                        localStorage.getItem("documentSignType") < 1 &&
                                        localStorage.getItem("hasActualDocuments") === "true" &&
                                        el.title !== "Документы") ||
                                    (localStorage.getItem("hasActualDocuments") === "true" &&
                                        el.title !== "Документы" &&
                                        ((e) => e.preventDefault()))
                                }
                                className={
                                    ({ isActive }) => isActive
                                        ? "nav-menu-item-active"
                                        : "nav-menu-item"
                                }
                            >
                                {el.title}
                                {!isTerminal && el.notification
                                    ? (bubble
                                        ? (<span className="show-active-notification">
                                            {
                                                el.notification ||
                                                bubble
                                                    ? bubble
                                                    : null
                                            }
                                        </span>)
                                        : null
                                    ) : null}
                            </NavLink>
                        );
                    })}
            </div>
            {/*<div>*/}
            {
                localStorage.getItem("isLeader") === "true" && !isTerminal
                    // ? (
                    // (localStorage.getItem("agreement") > 0 &&
                    // localStorage.getItem("documentSignType") < 1 &&
                    // localStorage.getItem("hasActualDocuments") === "true")
                    ? <NavLink
                        to={"/divisions"}
                        style={{ right: "0" }}
                        className={({ isActive }) =>
                            isActive
                                ? "divisions-active"
                                : "divisions-inactive"
                        }>
                        Мое подразделение
                    </NavLink>
                    //         : null
                    // )
                    : null
            }
        </div>
    );
};

export default React.memo(NavMenu);

import { data } from "./hrServicePage.data";
import { routerTerminal } from "./hrServicePageTerminal.data";

import { isTerminal } from "../../../constants";
import Header from "../../layout/Header/Header";
import Footer from "../../layout/Footer/Footer";
import MultiRoute from "../../common/MultiRoute/MultiRoute";

import blank from "../../../assets/icons/NavBlockIcons/blank-list.svg";

import NavMenuDiv from "../../common/NavMenuDiv/NavMenuDiv";

import { ScrollButton } from "../../common/componentsTerminal/ScrollButton/ScrollButton";

import React from "react";

const style = isTerminal ? null : { margin: "20px auto", width: "1160px" };

const router = isTerminal ? routerTerminal : data;

const HrServicesPage = () => {


    return (
        <>
            <Header />
            {/*{isTerminal && <ScrollButton />}*/}
            <MultiRoute
                data={router}
                styleParent={"hr-services"}
                breadCrumbs={true}
                subHeader={"Кадровый сервис"}
            />
            {!isTerminal && localStorage.getItem("isLeader") === "true" &&
                <div style={style}>
                    <NavMenuDiv
                        path={"/main/hr-services/leader-requests"}
                        title={"Заявки руководителя"}
                        description={"Отпуска, делегирование, служебные записки и другие"}
                        icon={blank}
                        styleParent={"hr-services"}
                    />
                </div>
            }


            <Footer />
        </>
    );
};

export default HrServicesPage;

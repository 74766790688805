import { memo } from "react";

import "./RoundedRemoveItemButton.scss";

const RoundedRemoveItemButton = (
    {
        handler,
    }
) => {

    return <div
        className="rounded-remove-item-button"
        onClick={() => handler ? handler() : null}>
    </div>;
};
 
export default memo(RoundedRemoveItemButton);
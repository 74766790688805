import InputMask from "react-input-mask";
import React from "react";

export const LoginPhoneMaskInput = ({onError, valid, setToValidLabelAndInput, inputPhone, handleChangePhone}) => {
    return (
        <InputMask
            className={
                (!valid &&
                    "login__wrapper__input-invalid") ||
                (onError &&
                    "login__wrapper__input-invalid") ||
                "login__wrapper__input"
            }
            onClick={setToValidLabelAndInput}
            mask="+7\ 999 999 99 99"
            ref={inputPhone}
            maskplaceholder={"+7 (***) *** - ** - **"}
            alwaysShowMask={true}
            onChange={handleChangePhone}
            name={"phone"}
        />
    );
};
import { forwardRef, useState } from "react";

const ImportantTasksDropdownTreeItem = forwardRef((
    {
        item,
        selectedId,
        setSelectedId,
        selectedIdPath,
    },
    ref,
) => {
    const [innerStructOpen, setInnerStructOpen] = useState(false);

    function selectHandler(item, event) {
        setInnerStructOpen(!innerStructOpen);
        setSelectedId(item.id);
    }

    return (
        <div>
            <div
                onClick={(event) => {
                    selectHandler(item, event);
                    ref.current = item;
                }}
                className = {[
                    "menu-tree-struct",
                    selectedIdPath && selectedIdPath.length && selectedIdPath.includes(item.id) && "selected-menu-tree-struct",
                ].join(" ")}
            >
                {
                    item.children.length ? 
                        <span className={`menu-tree-struct__arrow${innerStructOpen || 
                        (selectedIdPath && selectedIdPath.length && selectedIdPath.includes(item.id)) 
                            ? "__open" : ""}`}></span>
                        : <span className={"menu-tree-struct__buffer"}></span>
                }
                {item.name}
                {
                    selectedId === item.id
                        ? <span id={"menu-tree-arrow"} className={"menu-tree-struct__selected-arrow"}></span>
                        : null
                }
            </div>
            {
                item.children.length ? (
                    <div
                        className={`menu-tree-struct__sub-menu ${innerStructOpen || 
                            (selectedIdPath && selectedIdPath.length && selectedIdPath.includes(item.id)) 
                            ? "" : "sub-menu-shrunk"}`}
                    >
                        {
                            item.children.map(subItem => {
                                return <ImportantTasksDropdownTreeItem
                                    key={subItem.id}
                                    item={subItem}
                                    ref={ref}
                                    selectedId={selectedId}
                                    setSelectedId={setSelectedId}
                                    selectedIdPath={selectedIdPath}
                                />;
                            })
                        }
                    </div>

                )
                    : null
            }
        </div>
    );
});

export default ImportantTasksDropdownTreeItem;

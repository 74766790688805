import { mode } from "./mode";

import { isTerminal } from "../constants";
import StartPage from "../components/pages/StartPage/StartPage";
import RegisterPage from "../components/pages/RegisterPage/RegisterPage";
import LoginPage from "../components/pages/LoginPage/LoginPage";
import SecondAuthentication from "../components/pages/SecondAuthentication/SecondAuthentication";
import GosUslugi from "../components/pages/GosUslugi/GosUslugi";
import ResetNewPasswordPage from "../components/pages/ResetNewPasswordPage/ResetNewPasswordPage";
import HRServicesPage from "../components/pages/HRServicesPage/HRServicesPage";
import WorkTimePage from "../components/pages/HRServicesPage/WorkTimePage/WorkTimePage";
import WTRequestsPage from "../components/pages/HRServicesPage/WorkTimePage/WTRequestsPage/WTRequestsPage";
import MainPage from "../components/pages/MainPage/MainPage";
import VacationHolidaysPage from "../components/pages/VacationHolidaysPage/VacationHolidaysPage";
import PersonalData from "../components/pages/Profile/PersonalData/PersonalData";
import ServiceData from "../components/pages/Profile/ServiceData/ServiceData";
import PersonalDocuments from "../components/pages/Profile/PersonalDocuments/PersonalDocuments";
import EvaluationInformation from "../components/pages/Profile/EvaluationInformation/EvaluationInformation";
import EDSRelease from "../components/pages/Profile/EDSRelease/EDSRelease";
import Unep from "../components/pages/Profile/EDSRelease/Unep/Unep";
import Profile from "../components/pages/Profile/Profile";
import DataModification from "../components/pages/Profile/DataModification/DataModification";
import KnowledgeBase from "../components/pages/KnowlageBaze/KnowledgeBase";
import Documents from "../components/pages/Documents/Documents";
import Help from "../components/pages/Help/Help";
import MyDivision from "../components/pages/MyDivision/DirectorDivisionsPart";
import AuthorityDelegation
    from "../components/pages/MyDivision/DivisionStructure/ResponsibilitiesDelegation/AuthorityDelegation/AuthorityDelegation";
import AuthorityRevocation
    from "../components/pages/MyDivision/DivisionStructure/ResponsibilitiesDelegation/AuthorityRevocation/AuthorityRevocation";
import ProtectedAuth from "../components/pages/ProtectedAuth/ProtectedAuth";
import LeaderRequestsPage from "../components/pages/HRServicesPage/LeaderRequestsPage/LeaderRequestsPage";
import LrPage from "../components/pages/HRServicesPage/LeaderRequestsPage/LRPage/LRPage";
import WorkTimeWorkersPage from "../components/pages/MyDivision/WorkTimeWorkersPage/WorkTimeWorkersPage";
import HolidaysPage from "../components/pages/MyDivision/WorkTimeWorkersPage/HolidaysPage/HolidaysPage";
import SOUT from "../components/pages/HRServicesPage/WorkTimePage/SOUT/SOUT";
import AnnualCalendar from "../components/common/Modal/AnnualCalendar/AnnualCalendar";
import WorkSchedule from "../components/pages/WorkSchedule/WorkSchedule";
import UnepRelease from "../components/pages/Profile/EDSRelease/Unep/UnepRelease/UnepRelease";
import DivisionStructure from "../components/pages/MyDivision/DivisionStructure/DivisionStructure";
import AssessmentWorkingConditions
    from "../components/pages/HRServicesPage/WorkTimePage/SOUT/AssessmentWorkingConditions/AssessmentWorkingConditions";
import GuaranteeCompensationEmployee
    from "../components/pages/HRServicesPage/WorkTimePage/SOUT/GuaranteeCompensationEmployee/GuaranteeCompensationEmployee";
import EmployeeList from "../components/pages/MyDivision/DivisionStructure/EmployeeList/EmployeeList";
import LayoffPage from "../components/pages/HRServicesPage/LayoffPage/LayoffPage";
import WTLayOffPage from "../components/pages/HRServicesPage/LayoffPage/LayOffRequestPages/WTLayOffPage";
import ForbiddenPage from "../components/pages/ForbiddenPage/ForbiddenPage";
import ResponsibilitiesDelegation
    from "../components/pages/MyDivision/DivisionStructure/ResponsibilitiesDelegation/ResponsibilitiesDelegation";
import ImportantTasks from "../components/pages/HRServicesPage/LeaderRequestsPage/LRPage/ImportantTasks/ImportantTasks";
import UsefulLinks from "../components/pages/MainPage/UsefuLinks/UsefuLinks";
import ReferencePage from "../components/pages/ReferencePage/ReferencePage";
import SalaryPage from "../components/pages/ReferencePage/SalaryPage/SalaryPage";
import { RewardPage } from "../components/pages/HRServicesPage/RewardPage/RewardPage";
import { PayrollPage } from "../components/pages/HRServicesPage/RewardPage/PayrollPage/PayrollPage";
import { LogoutModalWindowTerminal } from "../components/common/componentsTerminal/LogoutModalWindowTerminal";

import { useLogout } from "../hooks/useLogout/useLogaut";

import MaintenanceNotification from "../components/MaintenanceNotitfication/MaintenanceNotification";

import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { useIdleTimer, IdleTimerProvider } from "react-idle-timer";
import React, { useState, useCallback } from "react";

// DO NOT DELETE! ITS APPLYING CUSTOM STYLES FOR DATEPICKER!
// TODO MOVE STYLES FROM INPUT TO ANOTHER FILE!
import ru from "date-fns/locale/ru";

import { registerLocale } from "react-datepicker";


!isTerminal &&
import(
    "../components/common/Inputs/SinglePairDates/SingleDateCalendarSelector/SingleDateCalendarSelector.scss"
);


isTerminal ? import("./AppTerminal.scss") : import("./App.scss");
registerLocale("ru", ru);

function App() {
    if (mode !== "PROD") {
        // eslint-disable-next-line no-console
        console.log("MODE => : ", mode);
    }
    const user = useSelector((state) => state.user);

    const timeout = 3 * 60 * 1000;
    const promptBeforeIdle = 30 * 1000;

    const [open, setOpen] = useState(false);

    const logout = useLogout();

    const onIdle = () => {
        setOpen(false);
        logout();
    };

    const onActive = () => {
        setOpen(false);
    };

    const onPrompt = () => {
        setOpen(true);
    };

    const { getRemainingTime, activate, start, pause } = useIdleTimer({
        timeout,
        promptBeforeIdle,
        crossTab: true,
        leaderElection: true,
        syncTimers: 200,
        onIdle,
        onActive,
        onPrompt,
        disabled: !isTerminal,
        startManually: true,
    });

    const handleStillHere = () => {
        activate();
    };

    return (
        <>
            <MaintenanceNotification />
            <Routes>
                <Route index element={<StartPage />} />
                <Route
                    element={
                        <ProtectedAuth
                            // isAuth={localStorage.getItem("token")}
                        />
                    }
                >
                    <Route path="/main" element={<MainPage />} />
                    <Route path="/main/useful-links" element={<UsefulLinks />} />

                    {/*<Route path="/main/annual" element={<AnnualCalendar />} />*/}
                    <Route path="/main/references" element={<ReferencePage />} />

                    <Route path="/main/references/salary" element={<SalaryPage />} />

                    <Route
                        path="/main/hr-services"
                        element={<HRServicesPage />}
                    />
                    <Route
                        path="/main/hr-services/leader-requests"
                        element={<LeaderRequestsPage />}
                    />
                    <Route
                        path="/main/hr-services/leader-requests/:id"
                        element={<LrPage />}
                    />
                    <Route
                        path="/main/hr-services/leader-requests/important"
                        element={<ImportantTasks />}
                    />
                    <Route
                        path="/main/hr-services/work-time"
                        element={<WorkTimePage />}
                    />
                    <Route
                        path="/main/hr-services/work-time/requests"
                        element={<WTRequestsPage />}
                    />
                    <Route
                        path="/main/hr-services/work-time/holidays"
                        element={<VacationHolidaysPage />}
                    />
                    <Route
                        path="/main/hr-services/work-time/work-schedule"
                        element={<WorkSchedule />}
                    />
                    <Route
                        path="/main/hr-services/work-time/sout"
                        element={<SOUT />}
                    />
                    <Route
                        path="/main/hr-services/work-time/sout/harmful-factors"
                        element={<AssessmentWorkingConditions />}
                    />
                    <Route
                        path="/main/hr-services/work-time/sout/guarantee-compensation"
                        element={<GuaranteeCompensationEmployee />}
                    />
                    <Route
                        path="/main/hr-services/layoff"
                        element={<LayoffPage />}
                    />
                    <Route
                        path="/main/hr-services/layoff/request"
                        element={<WTLayOffPage />}
                    />
                    <Route
                        path="/profile/personal-data"
                        element={<PersonalData />}
                    />
                    <Route
                        path="/profile/service-data"
                        element={<ServiceData />}
                    />
                    <Route
                        path="/profile/personal-documents"
                        element={<PersonalDocuments />}
                    />
                    <Route
                        path="/profile/evaluation-information"
                        element={<EvaluationInformation />}
                    />
                    <Route
                        path="/profile/eds-release"
                        element={<EDSRelease />}
                    />
                    <Route
                        path="/profile/eds-release/unep"
                        element={<Unep />}
                    />
                    <Route
                        path="/profile/eds-release/unep/release"
                        element={<UnepRelease />}
                    />
                    <Route path="/profile" element={<Profile />} />
                    <Route
                        path="data-modification"
                        element={<DataModification />}
                    />
                    <Route path="/knowledge-base" element={<KnowledgeBase />} />
                    <Route path="/documents" element={<Documents />} />
                    <Route path="/help" element={<Help />} />
                    <Route path="/divisions" element={
                        (user && user.isLeader && user.isLeader === true) ? (
                            <MyDivision />
                        ) : (
                            <Navigate to="/main" />
                        )
                    } />
                    <Route
                        path="/divisions/structure"
                        element={
                            (user && user.isLeader && user.isLeader === true) ? (
                                <DivisionStructure />
                            ) : (
                                <Navigate to="/main" />
                            )
                        }
                    />
                    <Route
                        path="/divisions/structure/employee-list"
                        element={
                            (user && user.isLeader && user.isLeader === true) ? (
                                <EmployeeList />
                            ) : (
                                <Navigate to="/main" />
                            )
                        }
                    />
                    <Route
                        path="/divisions/structure/delegation"
                        element={
                            (user && user.isLeader && user.isLeader === true) ? (
                                <ResponsibilitiesDelegation />
                            ) : (
                                <Navigate to="/main" />
                            )
                        }
                    />
                    <Route
                        path="/divisions/structure/delegation/transfer"
                        element={
                            (user && user.isLeader && user.isLeader === true) ? (
                                <AuthorityDelegation />
                            ) : (
                                <Navigate to="/main" />
                            )
                        }
                    />
                    <Route
                        path="/divisions/structure/delegation/revoke"
                        element={
                            (user && user.isLeader && user.isLeader === true) ? (
                                <AuthorityRevocation />
                            ) : (
                                <Navigate to="/main" />
                            )
                        }
                    />

                    <Route
                        path="/divisions/work-time"
                        element={
                            (user && user.isLeader && user.isLeader === true) ? (
                                <WorkTimeWorkersPage />
                            ) : (
                                <Navigate to="/main" />
                            )
                        }
                    />
                    <Route
                        path="/divisions/work-time/holidays"
                        element={
                            (user && user.isLeader && user.isLeader === true) ? (
                                <HolidaysPage />
                            ) : (
                                <Navigate to="/main" />
                            )
                        }
                    />
                    <Route path="/forbidden" element={<ForbiddenPage />} />
                </Route>

                {/*<Route element={ <AnonymousRoute isAuth={isAuth} /> } >*/}
                <Route path="/start" element={<StartPage />} />
                <Route path="/registration" element={<RegisterPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route
                    path="/authentication"
                    element={
                        user ? (
                            <SecondAuthentication />
                        ) : (
                            <Navigate to="/login" />
                        )
                    }
                />
                <Route path="/gosuslugi" element={<GosUslugi />} />
                <Route
                    path="/reset-password"
                    element={<ResetNewPasswordPage />}
                />
                <Route
                    path="/main/hr-services/reward"
                    element={<RewardPage />}
                />
                <Route
                    path="/main/hr-services/reward/payroll"
                    element={<PayrollPage />}
                />
                {/*<Route path='/reset-password'*/}
                {/*       element={(localStorage.getItem("token")) ? <ResetNewPasswordPage/> :*/}
                {/*         <Navigate to="/login"/>}/>*/}
                {/*</Route>*/}
            </Routes>
            {isTerminal && <LogoutModalWindowTerminal
                open={open}
                timeout={timeout}
                handleStillHere={handleStillHere}
                getRemainingTime={getRemainingTime}
                start={start}
                pause={pause}
            />}
            {/*{isAuth&&<Footer/>}*/}
        </>
    );
}

export default App;

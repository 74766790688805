import { mode } from "../../../../../../app/mode";

import DropdownMenu from "../../../../../common/Menu/DropdownMenu/DropdownMenu";
import DatepickerInput from "../../../../../common/Inputs/DatepickerInput/DatepickerInput";

import React from "react";

import "./PersonalAllowance.scss";


const listOfOptions = [
    {id: 100001, name: "Плановая оценка всех сотрудников"},
    {id: 100002, name: "Внеплановая индивидуальная оценка"},
];

const mockEmployee = {
    id: 1000003,
    struct: "Административно-управленческий персонал",
    name: "Константинов Константин Михайлович",
    position: "Ведущий специалист",
    grade: 6,
    experience: "03 г. 10 м.",
    disciplinePenalty: "отсутствует",
    currentPromotion: "10%",
    suggestedPromotion: "--%",
    changingPromotion: "--%",
    quality: null,
    performane: null,
    engagement: null,
    growth: null,
    deficiency: null,
    sixMonthsAbsence: null,
};

const mockEmployees = new Array(10).fill(null).map(e => mockEmployee);


const PersonalAllowance = () => {
    return (
        <>
            {
                mode === "PROD" ?
                    <>
                        <div className={"sub-header"}>Заявки руководителя</div>
                        <div style={{
                            margin: "60px auto",
                            fontSize: "24px",
                            lineHeight: "32px",
                            color: "gray",
                            width: "1160px",
                            minHeight: "300px",
                        }}>
                            На данный момент заявка находится в разработке
                        </div>
                    </> :
                    <div>
                        <div className="sub-header">
                            установление персональной надбавки
                        </div>
                        <div className="personal-allowance">
                            <div className="personal-allowance__legend">
                                В случае, если стаж работника менее 6 месяцев
                                оценка по критериям «Качество», «Производительность»,
                                «Взаимодействие», «Развитие» - не доступны для заполнения.
                            </div>
                            <DropdownMenu 
                                styleName={"reason"}
                                label={"Тип оценки *"}
                                listOfOptions={listOfOptions}/>
                        </div>
                        <div className="personal-allowance__form">
                            <div className="personal-allowance__form__header-left">
                                <div className="personal-allowance__form__header-left__struct">подразделение</div>
                                <div className="personal-allowance__form__header-left__employee">работник</div>
                            </div>
                            <div className="personal-allowance__form__header-right">
                                <div>Профессия, должность</div>
                                <div>Разряд</div>
                                <div>Стаж работы</div>
                                <div>Дисциплинарные взыскания</div>
                                <div>Действующая ПН. %</div>
                                <div>Предлагаемая ПН. %</div>
                                <div>Изменение ПН. %</div>
                                <div>Качество</div>
                                <div>Производительность</div>
                                <div>Взаимодействие</div>
                                <div>Развитие</div>
                                <div>Дефицитность (ДРУ) оплата до рыночного уровня</div>
                                <div>Количество неявок за предыдущие 6 мес.</div>
                            </div>
                        </div>
                    </div>
            }


        </>
    );
};

export default PersonalAllowance;

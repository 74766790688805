import openEyes from "../../../../../assets/icons/authIcons/ic_eyes_open.svg";
import passwordEye from "../../../../../assets/icons/authIcons/eye.svg";

import React, { useState } from "react";

export const LoginPasswordField = ({ password, errorView, onChange, title = null, name = null }) => {

    const [showPassword, setShowPassowrd] = useState(false);

    const togglePasswordVisiblity = () => {
        setShowPassowrd(!showPassword);
    };


    return (
        <>
            <label
                className={
                    errorView
                        ? "login__wrapper__label-invalid"
                        : "login__wrapper__label"
                }
                htmlFor={name ? name : "password"}>
                {errorView ? errorView: (title ? title : "Пароль")}
                <input
                    className={
                        (errorView &&
                            "login__wrapper__input-invalid") ||
                        "login__wrapper__input"
                    }
                    maxLength={32}
                    name={name ? name : "password"}
                    value={password}
                    onChange={(e) => onChange(e.target.value)}
                    type={showPassword ? "text" : "password"}
                />
                <i
                    className={"login__wrapper__icon"}
                    onClick={togglePasswordVisiblity}>
                    {showPassword ? (
                        <img src={openEyes} alt="openEye" />
                    ) : (
                        <img src={passwordEye} alt="icon" />
                    )}
                </i>{" "}
            </label>
        </>
    );

};
import { applicationsData } from "./applications.data";

import { Modal } from "../../Modal/Modal";
import { isTerminal } from "../../../../constants";

import {
    setCurrentRequestThunkAction,
} from "../../../../redux/actions/Requests/CurrentRequest/thunk/setCurrentRequest.thunk.action";
import RequestServices from "../../../../frontend.services/RequestServices/RequestServices";
import { unsetUserRequestAction } from "../../../../redux/actions/Requests/CurrentRequest/unsetUserRequest.action";
import UserRequestCard from "../ModalAssets/UserRequestCard/UserRequestCard";

import { LeftArrow } from "../../../../assets/icons/LeftArrow";
import { RightArrow } from "../../../../assets/icons/RightArrow";

import CustomLoader from "../../CustomLoader/CustomLoader";

import { ClipLoader } from "react-spinners";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

isTerminal ? import("./ApplicationsTerminal.scss") : import("./Applications.scss");

const Applications = (
    {
        currentRowsHandler,
        rowsPerPage,
        userRequests,
    },
) => {

    const dispatch = useDispatch();

    const [modalShownReferences, toggleModalShownReferences] = useState(false);
    const [description, setDescription] = useState("");
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + rowsPerPage;
    const currentItems = userRequests.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(userRequests?.length / rowsPerPage);

    function clickHandlerReferences(uid, i) {
        toggleModalShownReferences(!modalShownReferences);
        dispatch(setCurrentRequestThunkAction(uid, i, { setDescription: setDescription }));
    }

    function closeHandlerReferences(e) {
        toggleModalShownReferences(false);
        dispatch(unsetUserRequestAction());
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * rowsPerPage) % userRequests.length;
        setItemOffset(newOffset);
    };

    useEffect(() => {
        if (itemOffset < rowsPerPage) setItemOffset(0);
        setItemOffset(Math.floor(itemOffset / rowsPerPage) * rowsPerPage);
    }, [itemOffset, rowsPerPage]);

    return !userRequests
        ? <CustomLoader />
        : (<div>
            <div className="table-container">
                <table className="table">
                    <thead>
                        <tr className="table__row">
                            {applicationsData && applicationsData.map((el) => {
                                return (
                                    <th
                                        key={el.id}
                                        style={{ width: `${el.width}` }}
                                        className="table__row__item"
                                    >
                                        {el.title}
                                        {
                                            el.icon
                                                ? <img
                                                    src={el.icon}
                                                    alt="triangle"
                                                    className="header-triangle" />
                                                : null
                                        }
                                    </th>
                                );
                            })
                            }
                        </tr>
                    </thead>
                    {/*<tr>*/}
                    <tbody>
                        {currentItems && currentItems.map((el, i) => (
                            <tr
                                onClick={() => (clickHandlerReferences(el.uid, i))}
                                key={el.id}
                                className={el.isReviewed ? "row-request" : "row-request-active"}
                            >
                                <td style={{ width: isTerminal ? "89px" : "66px" }}>{el.typeText}</td>
                                <td style={{ width: isTerminal ? "137px" : "100px" }}>{el.orderNumber}</td>
                                <td style={{ width: isTerminal ? "160px" : "234px" }}>{el.leaveReasonId}</td>
                                <td style={{ width: isTerminal ? "139px" : "150px" }}>{el.statusText}</td>
                                <td style={{ width: isTerminal ? "224px" : "330px" }}>
                                    {
                                        el.employeeComment
                                            ? (el.employeeComment.length > 35
                                                ? el.employeeComment.slice(0, 35) + "..."
                                                : el.employeeComment)
                                            : "..."
                                    }
                                </td>
                                <td
                                    onClick={(e) => e.stopPropagation()}
                                    style={{ width: isTerminal ? "158px" : "145px" }}>{el.fileName ?
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                        <a
                                        // TODO REFACTOR LINK!!!
                                        // href="/#"
                                            download
                                            target="_blank"
                                            onClick={
                                                () => {
                                                    return RequestServices
                                                        .appFileDownloadTEMPORARYmethod(
                                                            el.applicationDocumentAttachment.leaveRequestUid,
                                                            el.applicationDocumentAttachment.id,
                                                            el.applicationDocumentAttachment.url,
                                                        );
                                                }
                                            }
                                            className="request-colored-link">{el.fileName}
                                        </a>
                                        : "..."}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>


                {
                    !userRequests.length
                        ? <div>

                        </div>
                        : <div className={isTerminal && "pagination-wrapper"}>
                            {!isTerminal && <div className="pagination-container">
                                <div className="pagination-container__rows">
                                    <div
                                        onClick={() => currentRowsHandler(5)}
                                        className={rowsPerPage === 5 ? "rowsPerPage-number-active" : "rowsPerPage-number-inactive"}>
                                            5
                                    </div>
                                    <div
                                        onClick={() => currentRowsHandler(10)}
                                        className={rowsPerPage === 10 ? "rowsPerPage-number-active" : "rowsPerPage-number-inactive"}>
                                            10
                                    </div>
                                    <div
                                        onClick={() => currentRowsHandler(20)}
                                        className={rowsPerPage === 20 ? "rowsPerPage-number-active" : "rowsPerPage-number-inactive"}>

                                            20
                                    </div>

                                    <div
                                        onClick={() => currentRowsHandler(50)}
                                        className={rowsPerPage === 50 ? "rowsPerPage-number-active" : "rowsPerPage-number-inactive"}>
                                            50
                                    </div>
                                </div>
                            </div>}
                            <ReactPaginate
                                className={"pagination"}
                                breakLabel=".."
                                nextLabel={isTerminal ? <RightArrow /> : ">"}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                pageCount={pageCount}
                                previousLabel={isTerminal ? <LeftArrow /> : "<"}
                                renderOnZeroPageCount={null}
                                forcePage={Math.floor(itemOffset / rowsPerPage)}
                            />
                        </div>


                }
            </div>
            <Modal
                shown={modalShownReferences}
                close={closeHandlerReferences}>
                <UserRequestCard

                    setDescription={setDescription}
                    description={description}
                    userRequests={userRequests}
                    close={closeHandlerReferences}
                />
            </Modal>

        </div>
        );
};

export default React.memo(Applications);

import React from "react";

export const LoginEmptySelectInput = ({ onClick }) => {
    return (
        <>
            <input
                className={"login__wrapper__input login__wrapper__input-empty"}
                placeholder={"Выберите значение из списка"}
                readOnly={true}
                type={"text"}
                onClick={onClick}
                name={"empty"}
            />
        </>

    );
};
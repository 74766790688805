import {
    ADD_PEOPLE_HANDLER_CURRENT_REQUEST,
} from "../../../enums/Request/request.types";

export const addStudentHandlerCurrentRequest = (data) => {
    return {
        type: ADD_PEOPLE_HANDLER_CURRENT_REQUEST,
        payload: data,
    };
};

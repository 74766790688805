import triangle from "../../../../../assets/icons/authIcons/Input/Triangle.svg";

import React from "react";

export const LoginInputTypeSelectTriangle = ({visible, visibleSelect}) => {
    return (
        <>
            <img
                className={
                    !visible
                        ? "login__wrapper__img"
                        : "login__wrapper__img-reverse"
                }
                onClick={visibleSelect}
                src={triangle}
                alt="triangle"
            />
        </>
    );
};
import bluePlus from "./images/bluePlus.svg";

import {
    getStructureHeadThunkAction,
} from "../../../../../../redux/actions/Requests/LeaderRequests/thunk/getStructureHead.thunk.action";
import {
    getStructureTopThunkAction,
} from "../../../../../../redux/actions/Requests/LeaderRequests/thunk/getStructureTop.thunk.action";
import {
    getFunctionMarkerThunkAction,
} from "../../../../../../redux/actions/Requests/LeaderRequests/thunk/getFunctionMarker.thunk.action";
import {
    clearStructureTopAction,
} from "../../../../../../redux/actions/Requests/LeaderRequests/clearStructureTop.action";
import {
    clearStructureTreeAction,
} from "../../../../../../redux/actions/Requests/LeaderRequests/clearStructureTree.action";
import {
    clearFunctionMarkersAction,
} from "../../../../../../redux/actions/Requests/LeaderRequests/clearFunctionMarkers.action";
import {
    clearSelectedStructItemAction,
} from "../../../../../../redux/actions/Requests/currentRequestControls/ImportantTask/clearSelectedStructItem.action";
import {
    getEmployeesByStructIdThunkAction,
} from "../../../../../../redux/actions/Requests/LeaderRequests/thunk/getEmployeesByStructId.thunk.action";
import {
    clearEmployeeListAction,
} from "../../../../../../redux/actions/Requests/LeaderRequests/clearEmployeeList.action";
import {
    addWorkerToImportantTaskAction,
} from "../../../../../../redux/actions/Requests/currentRequestControls/ImportantTask/addWorkerToImportantTask.action";
import ImportantTasksService from "../../../../../../frontend.services/LeaderRequests/ImportantTasks.service";

import ImportantTasksWorkers from "../../../../../common/ImportantTasksWorkers/ImportantTasksWorkers";
import ImportantTasksDropdownTree from "../../../../../common/ImportantTasksDropdownTree/ImportantTasksDropdownTree";
import DropdownMenu from "../../../../../common/Menu/DropdownMenu/DropdownMenu";
import RequestButtons from "../../../../../common/Button/RequestButtons/RequestButtons";
import CommentInput from "../../../../../common/Inputs/CommentInput/CommentInput";
import {
    selectDropdownMenuItemAction,
} from "../../../../../../redux/actions/Requests/currentRequestControls/ImportantTask/selectDropdownMenuItem.action";
import {
    addCommentHandlerCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/addCommentHandler.currentRequest";

import ImportantTasksApplication from "../../../../../../frontend.classes/Requests/nonBasic/ImportantTasks.application";
import {
    emptyCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/emptyCurrentRequest";

import {
    calendarDatePickCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/calendarDatePick.currentRequest";
import {
    validationControlCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/validationControl.currentRequest";
import {
    addWorkersToAmmountAction,
} from "../../../../../../redux/actions/Requests/RequestsFromLeader/ImportanyTask/addWorkersToAmmount.action";
import { clearAttachmentsAction } from "../../../../../../redux/actions/Requests/Attachments/clearAttachments.action";
import {
    requestValidationForLeaderRequestsThunkAction,
} from "../../../../../../redux/actions/Requests/RequestValidation/thunk/requestValidationForLeaderRequests.thunk.action";
import {
    saveDraftCurrentLeaderRequestThunk,
} from "../../../../../../redux/actions/Requests/RequestsFromLeader/thunk/saveDraftCurrentLeaderRequest.thunk.action";
import {
    updateCurrentLeaderRequestThunkAction,
} from "../../../../../../redux/actions/Requests/RequestsFromLeader/thunk/updateCurrentLeaderRequest.thunk.action";
import NotificationWindowRequests from "../../../../../common/NotificationWindowRequests/NotificationWindowRequests";
import EmptyBody from "../../../../../common/EmptyBody/EmptyBody";
import { Modal } from "../../../../../common/Modal/Modal";
import BackHandlerModal from "../../../../../common/Modal/ModalConditions/Requests/BackHandlerModal/BackHandlerModal";
import SaveSuccessModal from "../../../../../common/Modal/ModalConditions/Requests/SaveSuccessModal/SaveSuccessModal";
import ModalBanOverlay from "../../../../../common/Modal/ModalBanOverlay/ModalBanOverlay";
import RequestVerification
    from "../../../../../common/Modal/ModalConditions/Requests/RequestVerification/RequestVerification";
import Error500Modal from "../../../../../common/Modal/ModalConditions/Requests/Error500Modal/Error500Modal";

import DatePopupSelector from "../../../../../common/Inputs/Calendar/DatePopupSelector/DatePopupSelector";
import ImportantTasksDescription from "../../../../../common/ImportantTasksDescription/ImportantTasksDescription";
import SingleUploaderNewVersion from "../../../../../common/SingleUploaderNewVersion/SingleUploaderNewVersion";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import { v4 as uuid } from "uuid";

import "./ImportantTasks.scss";

const ImportantTasks = () => {

    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const queryId = searchParams.get("id");
    const currentRequest = useSelector(state => state.requests.currentRequest);
    const [workersState, setWorkersState] = useState(queryId && currentRequest ? currentRequest.workers : [{
        pid: "",
        amount: 0,
    }]);
    useSelector(state => state.requests.structTree);
    const structTop = useSelector(state => state.requests.structTop);
    const functionMarkers = useSelector(state => state.requests.functionMarkers);
    useSelector(state => state.requests.selectedStructItem);
    const structId = useSelector(state => state.requests?.currentRequest?.structId ? state.requests.currentRequest.structId : 0);

    const [sendButtonLoading, setSendButtonLoading] = useState(false);
    const [onValidation, setOnValidation] = useState(false);


    // NOTIFICATION IN CONTROL PROCEDURES CONTENT
    const [requestStatus, setRequestStatus] = useState(false);
    const [modalStatus, setModalStatus] = useState(false);
    const [modalSuccessStatus, setModalSuccessStatus] = useState(false);
    const [modalSentStatus, setModalSentStatus] = useState(false);
    const [notificationWindow, setNotificationWindow] = useState(false);

    const [isRunningSaveHandler, setIsRunningSaveHandler] = useState(false);
    const [isRunningSendHandler, setIsRunningSendHandler] = useState(false);
    const [structTopSort, setStructTopSort] = useState(null);


    const [modalError, setModalError] = useState({
        sign: false,
        validation: false,
        status: false,
    });
    const fundingSource = [
        {
            id: 0,
            name: "Фонд руководителя актива",
        },
        {
            id: 1,
            name: "Фонд руководителя функции",
        },
    ];

    function backHandler() {
        currentRequest && currentRequest.leaveReasonId
            ? setModalStatus(true)
            : navigate("/main/hr-services");
    }

    function selectDropdown(event, element, stateProperty) {
        dispatch(selectDropdownMenuItemAction(event, element, stateProperty));

        if (stateProperty === "assetId" && element.id) {
            dispatch(clearStructureTreeAction());
            dispatch(clearSelectedStructItemAction());
            dispatch(clearEmployeeListAction());

            dispatch(getStructureHeadThunkAction(element.id));
        }
    }

    function commentHandler(e) {
        dispatch(addCommentHandlerCurrentRequest(e.target.value));
    }

    function changeDateHandler(field, date) {
        const value = moment(date).format("YYYY-MM-DD");
        dispatch(validationControlCurrentRequest(field, true));
        dispatch(calendarDatePickCurrentRequest({ field: field, date: value }));
    }

    function addWorkerHandler() {
        if (currentRequest && queryId) {
            setWorkersState(currentRequest.workers);
        }
        dispatch(addWorkerToImportantTaskAction());
        setWorkersState((prev) => {
            return [...prev, { pid: "", amount: "", name: "" }];
        });
    }

    async function saveHandler(e) {
        let result = false;
        const temp = await ImportantTasksService.filterWorkers(currentRequest, dispatch, setWorkersState, workersState);
        if (ImportantTasksService.save(temp, dispatch, setWorkersState, workersState)) {
            setModalSuccessStatus(true);
            setIsRunningSaveHandler(true);
            if (!currentRequest.uid) {
                result = dispatch(
                    saveDraftCurrentLeaderRequestThunk(
                        temp,
                        setModalSuccessStatus,
                        params.id,
                    ),
                );
            } else {
                result = dispatch(
                    updateCurrentLeaderRequestThunkAction(
                        temp,
                        setModalSuccessStatus,
                        params.id,
                    ),
                );
            }
        }

        if (result)
            result.then(() => {
                setIsRunningSaveHandler(false);
            });
        else setIsRunningSaveHandler(false);
    }

    function submitHandler() {
        let result = false;
        dispatch(addWorkersToAmmountAction(workersState));
        if (ImportantTasksService.validation(currentRequest, dispatch)) {
            setModalSentStatus(true);
            setIsRunningSendHandler(true);
            result = dispatch(
                requestValidationForLeaderRequestsThunkAction({
                    currentRequest,
                    setRequestStatus,
                    stateLoader: setSendButtonLoading,
                    closeModal: setModalSentStatus,
                    setModalError,
                    option: Number(params.id),
                }),
            );
        }
        if (result)
            result.then(() => {
                setIsRunningSendHandler(false);
            });
        else setIsRunningSendHandler(false);
    }

    useEffect(() => {
        dispatch(addWorkersToAmmountAction(workersState));
    }, [workersState]);

    useEffect(() => {
        //dispatch(getStructureHeadThunkAction()); //??
        dispatch(getStructureTopThunkAction());
        dispatch(getFunctionMarkerThunkAction());
        dispatch(clearAttachmentsAction());
        if (!queryId) {
            dispatch(emptyCurrentRequest(new ImportantTasksApplication({ workers: [{ pid: "", amount: 0 }] })));
        }

        return () => {
            dispatch(clearStructureTreeAction());
            dispatch(clearStructureTopAction());
            dispatch(clearFunctionMarkersAction());
            dispatch(clearSelectedStructItemAction());
            dispatch(clearEmployeeListAction());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryId]);

    useEffect(() => {
        if (requestStatus && !requestStatus.isSuccess) {
            setNotificationWindow(true);
        }
    }, [requestStatus]);

    useEffect(() => {
        if (structTop) {
            const key = "name";
            const sorted = structTop.sort((a, b) => a[key] > b[key] ? 1 : -1);
            setStructTopSort(sorted);
        }
    }, [structTop]);

    useEffect(() => {
        dispatch(getEmployeesByStructIdThunkAction(structId));
    }, [structId]);

    useEffect(() => {
        const filteredRequest = currentRequest?.workers.filter(element =>
            Boolean(element.pid) === true && Boolean(Number(element.amount)) === true);
        const filteredState = workersState.filter(element =>
            Boolean(element.pid) === true && Boolean(Number(element.amount)) === true);

        if (filteredRequest?.length !== filteredState?.length && currentRequest?.workers)
            setWorkersState(currentRequest.workers);
    }, [currentRequest, queryId]);

    return (
        <div>
            <div style={{ width: "1160px", margin: "0 auto", minHeight: "35vh" }}>
                {/*<Breadcrumbs />*/}
                <div className={"sub-header"} style={{ width: "767px", margin: "0 0 60px 0" }}>
                    Премирование за выполнение ОВЗ
                </div>
                <div className={"important-tasks"}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "1160px",
                            margin: "auto",
                            gap: "15px",
                        }}
                    >
                        <DatePopupSelector
                            field={"dateFrom"}
                            label={"Дата начала выполнения работ *"}
                            errorMessage={
                                currentRequest &&
                                currentRequest.isValid &&
                                currentRequest.isValid.dateFrom
                                    ? ""
                                    : "Обязательное поле"
                            }
                            changeHandler={changeDateHandler}
                            propsDate={
                                currentRequest && currentRequest.dateFrom
                                    ? new Date(currentRequest.dateFrom)
                                    : null
                            }
                            minDate={new Date(moment().subtract(12, "months"))}
                            maxDate={currentRequest &&
                            currentRequest.dateTo ? currentRequest.dateTo === "Invalid date"
                                    ? new Date(moment().subtract(1, "days"))
                                    : new Date(currentRequest.dateTo)
                                : new Date(moment().subtract(1, "days"))
                            }
                        />
                        <DatePopupSelector
                            field={"dateTo"}
                            label={"Дата окончания выполнения работ *"}
                            errorMessage={
                                currentRequest &&
                                currentRequest.isValid &&
                                currentRequest.isValid.dateTo
                                    ? ""
                                    : "Обязательное поле"
                            }
                            changeHandler={changeDateHandler}
                            propsDate={
                                currentRequest && currentRequest.dateTo ? new Date(currentRequest.dateTo) : null
                            }
                            minDate={currentRequest && currentRequest.dateFrom
                                ? currentRequest.dateFrom === "Invalid date"
                                    ? new Date(moment().subtract(12, "months"))
                                    : new Date(currentRequest.dateFrom)
                                : new Date(moment().subtract(12, "months"))}
                            maxDate={new Date(moment().subtract(1, "days"))}

                        />
                    </div>
                    <ImportantTasksDescription
                        value={currentRequest && currentRequest.description}
                        field={"description"}
                        message={"Опишите состав поставленного задания"}
                        textAreaLabel={"Описание задания *"} />

                    <ImportantTasksDescription
                        value={currentRequest && currentRequest.goal}
                        field={"goal"}
                        message={"Опишите состав поставленного задания"}
                        textAreaLabel={"Цель задания *"} />

                    <DropdownMenu
                        styleName={"important-task"}
                        label={"Источник премирования *"}
                        listOfOptions={fundingSource ? fundingSource : []}
                        stateProperty={"fundingSource"}
                        currentRequest={currentRequest}
                        selectOptionClick={selectDropdown}
                        isValidKey={
                            currentRequest && currentRequest.isValid &&
                            currentRequest.isValid.fundingSource
                                ? currentRequest.isValid.fundingSource
                                : false} />

                    <DropdownMenu
                        styleName={"important-task"}
                        label={"Актив *"}
                        listOfOptions={structTopSort ? structTopSort : []}
                        stateProperty={"assetId"}
                        currentRequest={currentRequest}
                        selectOptionClick={selectDropdown}
                        isValidKey={
                            currentRequest &&
                            currentRequest.isValid &&
                            currentRequest.isValid.assetId
                                ? currentRequest.isValid.assetId
                                : false} />

                    {
                        currentRequest &&
                        currentRequest.fundingSource === 1 &&
                        <DropdownMenu
                            styleName={"important-task"}
                            label={"Маркер функции *"}
                            listOfOptions={functionMarkers ? functionMarkers : []}
                            stateProperty={"functionMarkerId"}
                            currentRequest={currentRequest}
                            selectOptionClick={selectDropdown}
                            isValidKey={
                                currentRequest && currentRequest.isValid &&
                                currentRequest.isValid.functionMarkerId
                                    ? currentRequest.isValid.functionMarkerId
                                    : false} />
                    }

                    <div className={"important-tasks__legend"}>
                        Сумма премирования не должна быть меньше 10% от должностного
                        оклада/месячной тарифной ставки работника с учетом РКиСН/КРРТ
                        и не может превышать шести должностных окладов/месячных тарифных
                        ставок с учетом РКиСН/КРРТ по совокупности премий за ОВЗ в год.
                        В случае нарушения условий методики,
                        премирование может быть не согласовано.
                    </div>

                    <ImportantTasksDropdownTree
                        currentRequest={currentRequest && currentRequest}
                        isValid={
                            currentRequest &&
                            currentRequest.isValid &&
                            currentRequest.isValid.structId
                        }
                        setWorkersState={setWorkersState}
                    />

                    {
                        workersState &&
                        workersState.map((worker, workerIndex) => {
                            return <ImportantTasksWorkers
                                key={uuid()}
                                worker={worker}
                                workerIndex={workerIndex}
                                pidValid={worker.pidValid}
                                amountValid={worker.amountValid}
                                setWorkerState={setWorkersState}
                            />;
                        })
                    }

                    <div style={{ display: "flex", gap: "20px", height: "48px", alignItems: "center" }}>
                        <div style={{ fontSize: "18px", lineHeight: "42px" }}>
                            Добавить еще одного работника
                        </div>
                        <img
                            style={{ cursor: "pointer" }}
                            onClick={addWorkerHandler}
                            src={bluePlus}
                            alt="add" />
                    </div>

                    <div>
                        <div style={{ fontSize: "12px" }}>Итого суммы премии</div>
                        <div style={{
                            width: "587px",
                            borderBottom: "1px solid #eeeeee",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                        }}>
                            {
                                workersState &&
                                workersState.reduce((acc, worker) => acc += worker.amount, 0)
                            }
                        </div>
                    </div>


                    <CommentInput
                        maxLength={255}
                        value={currentRequest && currentRequest.employeeComment}
                        changeHandler={commentHandler}
                        label={"Комментарий"}
                        parentStyle={"postpone-vacation"} />

                    <SingleUploaderNewVersion
                        label={"Прикрепить скан (фото) документов "}
                        i={0}
                        fileName={"Отпуск_по_уходу_за_ребенком.pdf"}
                        subTypeNumber={7}
                    />

                    <div style={{ marginTop: "40px" }}></div>
                    <NotificationWindowRequests
                        notificationWindowStatus={requestStatus}
                        setNotificationWindowStatus={setRequestStatus}
                        notificationWindowStyle={notificationWindow}
                        blockingMessages={true}
                    />
                    {Number(params.id) === 16 ? (
                        <RequestButtons
                            sendHandler={submitHandler}
                            saveHandler={saveHandler}
                            backHandler={backHandler}
                            isRunningSaveHandler={isRunningSaveHandler}
                            isRunningSendHandler={isRunningSendHandler}
                        />
                    ) : (
                        <EmptyBody backHandler={backHandler} />
                    )}

                    {/*BACK*/}
                    <Modal shown={modalStatus} close={setModalStatus}>
                        <BackHandlerModal
                            handler={saveHandler}
                            close={setModalStatus}
                        />
                    </Modal>
                    {/*SAVE SUCCESS*/}
                    <Modal shown={modalSuccessStatus} close={setModalSuccessStatus}>
                        <SaveSuccessModal
                            route={"/main/hr-services"}
                            close={setModalSuccessStatus}
                        />
                    </Modal>
                    {/*REQUEST SEND*/}
                    <ModalBanOverlay shown={modalSentStatus} close={setModalSentStatus}>
                        <RequestVerification
                            onValidation={onValidation}
                            setOnValidation={setOnValidation}
                            setModalError={setModalError}
                            sendButtonLoading={sendButtonLoading}
                            route={"/main/hr-services/"}
                            setModalSentStatus={setModalSentStatus}

                        />
                    </ModalBanOverlay>
                    {modalError.status && (
                        <ModalBanOverlay shown={modalError} close={setModalError}>
                            <Error500Modal
                                modalError={modalError}
                                setModalError={setModalError}
                            />
                        </ModalBanOverlay>
                    )}
                </div>


            </div>
        </div>
    );
};

export default ImportantTasks;

import { UNSET_CURRENT_DOCUMENT } from "../../../enums/Documents/DocumentsType";


export const setCurrentDocumentAction = (data) => {
    return {
        type: "SET_CURRENT_DOCUMENT",
        payload: data,
    };
};
export const unsetCurrentDocumentAction = () => {
    return {
        type: UNSET_CURRENT_DOCUMENT,
    };
};

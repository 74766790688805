import stylesTerminal from "./LoginFormTerminal.module.css";
import { AUTH_TYPE_LOGIN } from "./loginForm.const";
import { LoginFromTerminalService } from "./loginFromTerminal.service";

import { InputTerminal } from "../../componentsTerminal/Input/InputTerminal";
import { ButtonTerminal } from "../../componentsTerminal/Button/ButtonTerminal";
import logo from "../../../../assets/icons/authIcons/alrosaMainLogo/Logo.svg";
import passwordEye from "../../../../assets/icons/authIcons/eye.svg";
import openEyes from "../../../../assets/icons/authIcons/ic_eyes_open.svg";
import { resetErrorLoginMessageAction } from "../../../../redux/actions/Auth/wrongAuthAction";
import { userLoginThunkAction } from "../../../../redux/actions/Auth/Thunk/userLoginThunkAction";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import "./LoginForm.scss";

const LoginFormTerminal = ({ modalShownSupport, toggleModalSupport, modalShown, toggleModal }) => {
    const ref = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.user);
    const [onError, setOnError] = useState(false);
    const [selectedOption, setSelectedOption] = useState(AUTH_TYPE_LOGIN.EMPTY.type);
    const [, setVisible] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const [valid, setValid] = useState(true);
    const [openEye, setOpenEye] = useState(false);
    const [terminalInput, setTerminalInput] = useState("");
    const [loading, setLoading] = useState(false);

    function clickOutside(e) {
        if (!ref.current?.contains(e.target)) {
            setVisible(false);
        }
    }

    const setToValidLabelAndInput = () => {
        setValid(true);
        dispatch(resetErrorLoginMessageAction());
    };

    const submitHandlerTerminal = (e) => {
        e.preventDefault();
        let userId = null;
        switch (selectedOption) {
        case AUTH_TYPE_LOGIN.EMAIL.type:
            userId = terminalInput.trim();
            break;

        case AUTH_TYPE_LOGIN.PHONE.type:
            const phone = terminalInput.replace(/(\D)/g, "");
            userId = "+" + phone;
            break;

        case AUTH_TYPE_LOGIN.SNILS.type:
            userId = terminalInput.replace(/(\D)/g, "");
            break;
        default:
            userId = null;
        }

        if (userId) {
            setLoading(true);
            dispatch(
                userLoginThunkAction({ userId: userId, password: password }, (message) => setOnError(message)),
            )
                .then(() => setLoading(false));
            if (user) {
                navigate("/authentication");
            }
        }

    };

    const togglePasswordVisiblity = () => {
        setOpenEye(!openEye);
        setPasswordShown(!passwordShown);
    };

    function clickHandlerForgotPassword() {
        toggleModal(!modalShown);
    }

    function clickHandlerSupport() {
        toggleModalSupport(!modalShownSupport);
    }

    useEffect(() => {
        document.addEventListener("mousedown", clickOutside);
        return () => document.removeEventListener("mousedown", clickOutside);
    }, [selectedOption]);

    useEffect(() => {
        setSelectedOption(LoginFromTerminalService.getAuthType(terminalInput));
    }, [terminalInput]);

    useEffect(() => {
        // localStorage.removeItem('userId')
        localStorage.removeItem("resetPassword");
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        if (user) {
            navigate("/authentication");
        }

        setValid(onError !== "Пользователь не найден");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, onError]);

    useEffect(() => {
        document.addEventListener("mousedown", clickOutside);
        return () => document.removeEventListener("mousedown", clickOutside);
    }, [selectedOption]);


    return (
        <>
            <form
                onSubmit={submitHandlerTerminal}
                onClick={setToValidLabelAndInput}
                className={stylesTerminal.loginTerminal}>
                <img src={logo} alt="login-logo" style={{ width: "50%" }} />
                <InputTerminal
                    placeholder="Начните вводить значение"
                    onChange={(e) => setTerminalInput(e.target.value)}
                    label="Телефон/Email/СНИЛС"
                    errorView={!valid && "Некорректные значения"}
                />
                <InputTerminal
                    placeholder="Введите пароль"
                    onChange={(e) => setPassword(e.target.value)}
                    label="Пароль"
                    type={passwordShown ? "text" : "password"}
                    trailingIcon={
                        <i
                            className={stylesTerminal.wrapperEye}
                            onClick={togglePasswordVisiblity}>
                            {openEye ? (
                                <img src={openEyes} alt="openEye" />
                            ) : (
                                <img src={passwordEye} alt="icon" />
                            )}
                        </i>
                    }
                    errorView={(!valid || onError) && "Неверный пароль"}
                />
                <ButtonTerminal
                    label="Забыли пароль?"
                    variant="text"
                    textAlign="text-start"
                    type="button"
                    onClick={clickHandlerForgotPassword}
                />
                <ButtonTerminal
                    label="Войти"
                    disabled={!password || !terminalInput || loading}
                />
            </form>
            <div className={stylesTerminal.loginContainerHelpers}>
                <div className={stylesTerminal.loginHelpersWrapper}>
                    <span>Еще нет аккаунта?</span>
                    <ButtonTerminal
                        label="Регистрация"
                        onClick={() => navigate("/registration")}
                        variant="text"
                    />
                </div>
                <ButtonTerminal
                    label="Запрос в поддержку"
                    onClick={clickHandlerSupport}
                    variant="text"
                    textAlign="text-start"
                />
            </div>
        </>
    );

};

export default LoginFormTerminal;

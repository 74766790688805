import React from "react";

export const RegValidateResultSection = ({onError, validSnils}) => {
    return (
        onError ? (
            <label
                className="register-form__wrapper__label-invalid"
                htmlFor="socNumber">
                {onError}
            </label>
        ) : (
            <label
                className={
                    validSnils
                        ? "register-form__wrapper__label"
                        : "register-form__wrapper__label-invalid"
                }
                htmlFor="socNumber">
                {validSnils
                    ? "СНИЛС"
                    : "Необходимо ввести корректный СНИЛС"}
            </label>
        )
    );
};
import OkButton from "../../../../Button/OKButton/OKButton";
import CustomButton from "../../../../Button/CustomButton/CustomButton";

import { isTerminal } from "../../../../../../constants";

import {
    reqruitmentValidationThunkAction,
} from "../../../../../../redux/actions/Requests/RequestsFromLeader/RecruitmentToWork/thunk/reqruitmentValidation.thunk.action";

import ModalBanOverlay from "../../../ModalBanOverlay/ModalBanOverlay";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import React from "react";
import { element } from "prop-types";

isTerminal ? import("./RequestVerificationTerminal.scss") : import("./RequestConfirmation.scss");

const RequestConfirmation = ({ props, back, next, newTuple }) => {
    const [validationState, setValidationState] = useState(false);
    console.log("onValidation: ", props.onValidation);

    const kp = [
        // {
        //     "jobDate": "2024-05-01",
        //     "isBlocking": true,
        //     "reason": "Ошибка: выбранная дата не является выходным днем у работника (2024-05-01 Ибгмдлпоюйсхрлнрызуб Юкицфщхеребчмжфстэло Уйчдкшбрфчревпхоуьэю Эксперт). Необходимо скорректировать период привлечения или выбрать другого работника.",
        // },
        {
            "jobDate": "2024-05-01",
            "isBlocking": false,
            "reason": "Обращаем внимание: у работника (Ибгмдлпоюйсхрлнрызуб Юкицфщхеребчмжфстэло Уйчдкшбрфчревпхоуьэю) количество часов привлечения к работе в выходные, праздничные дни превышает максимальное количество часов работы в день по его текущему режиму работы",
        },
        // {
        //     "jobDate": "2024-06-06",
        //     "isBlocking": true,
        //     "reason": "Ошибка: выбранная дата не является выходным днем у работника (2024-06-06 Ибгмдлпоюйсхрлнрызуб Юкицфщхеребчмжфстэло Уйчдкшбрфчревпхоуьэю Эксперт). Необходимо скорректировать период привлечения или выбрать другого работника.",
        // },
    ];


    return (
        <ModalBanOverlay shown={true}>
            <div style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                background: "#FFFFFF",
                minHeight: "40vh",
                width: "70vw",
                textAlign: "center",
                padding: "20px",
                gap: "30px",
            }}>
                <div className="request-verification__head">
                    Вы уверены что хотите направить заявку?
                </div>
                {/*<div className="request-verification__input">*/}
                {/*    {*/}
                {/*        kp && kp.map((element) => {*/}
                {/*            return (*/}
                {/*                <div>*/}
                {/*                    {element["reason"]}*/}
                {/*                </div>*/}
                {/*            );*/}
                {/*        })*/}
                {/*    }*/}
                {/*</div>*/}


                <div style={{
                    display: "flex",
                    width: "800px",
                    justifyContent: "space-around",
                    alignItems: "center",

                }}>
                    {/*<CustomButton*/}
                    {/*    onValidation={onValidation}*/}
                    {/*    regular={true}*/}
                    {/*    title={"отмена"}*/}
                    {/*    modal={back}*/}
                    {/*    route={"/main/hr-services"}*/}
                    {/*/>*/}
                    <OkButton
                        onValidation={props.onValidation}
                        handler={() => back()}
                        title={"отмена"} />
                    <OkButton
                        onValidation={validationState}
                        handler={() => {
                            next(newTuple, props);
                            setValidationState(true);
                        }}
                        title={"направить"} />
                </div>
            </div>

        </ModalBanOverlay>

    );
};

export default RequestConfirmation;

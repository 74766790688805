import stylesWeb from "./Help.module.css";
import stylesTerminal from "./HelpTerminal.module.css";

import Header from "../../layout/Header/Header";
import Footer from "../../layout/Footer/Footer";
import { isTerminal } from "../../../constants";

const styles = isTerminal ? stylesTerminal : stylesWeb;

const Help = () => {
    return (
        <>
            <Header />
            <section className={styles.container}>
                <div className={styles.wrapper}>
                    <h1 className={styles.header}>
                        Раздел помощи
                        <span className={styles.description}>(в разработке)</span>
                    </h1>
                </div>
            </section>
            <Footer />
        </>

    );
};

export default Help;

import React from "react";
import { useNavigate } from "react-router-dom";

export const LoginForgotPasswordSection = ({clickHandlerForgotPassword}) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="login__wrapper__checkbox-text">
                <div>
                    Еще нет аккаунта?{" "}
                    <span
                        onClick={() => navigate("/registration")}
                        className="colored-texts">
                                    Регистрация
                    </span>
                </div>
            </div>
            <div className="login__wrapper__login">
                <span
                    onClick={clickHandlerForgotPassword}
                    className="colored-texts">
                                Забыли пароль?
                </span>
            </div>
        </>
    );

};
import {
    inputTextAreaContentCurrentRequestAction,
} from "../../../redux/actions/Requests/currentRequestControls/inputTextAreaContent.currentRequest.action";
import { useDebounce } from "../../../hooks/useDebounce/useDebounce";

import React, { useEffect, useRef, useState } from "react";
import "./ImportantTasksDescription.scss";
import { useDispatch, useSelector } from "react-redux";

const ImportantTasksDescription = (
    {
        field,
        textAreaLabel,
        message,
        value,
    },
) => {

    const dispatch = useDispatch();
    const ref = useRef(null);
    const [charCounter, setCharCounter] = useState(0);
    const currentRequest = useSelector(state => state.requests.currentRequest);
    const [currentValue, setCurrentValue] = useState(value);
    const debounce = useDebounce(500);

    function onInputHandler(event) {
        let str = event.target.value;
        if (str.length > 1000) str = str.slice( 0, 1000 );
        setCurrentValue(str);
        debounce(
            ()=> dispatch(inputTextAreaContentCurrentRequestAction(field, str))
        );
        setCharCounter(str.length);
    }

    const resizeTextArea = () => {
        ref.current.style.height = "auto";
        ref.current.style.height = ref.current.scrollHeight + "px";
    };
    useEffect(resizeTextArea, [ref, currentValue]);

    useEffect(()=>{
        if (value) {
            setCurrentValue(value);
            setCharCounter(value.length);
        }
    }, [value]);

    return (
        <div className={"important-tasks-description"}>
            <div className={"important-tasks-description__label"}>
                {textAreaLabel && textAreaLabel}
            </div>
            <textarea
                autoResize={true}
                value={currentValue ? currentValue : ""}
                spellCheck={false}
                className={"important-tasks-description__textarea"}
                onChange={onInputHandler}
                ref={ref}
                onLoad={(event) => {
                    if (currentRequest && currentRequest[field]) {
                        event.target.innerText = "";
                    }
                }}
            >
            </textarea>
            <div className={"important-tasks-description__char-counter"}>
                {1000 - charCounter}/1000
            </div>
            {
                message
                    ? <div className={`important-tasks-description__message${
                        currentRequest && currentRequest.isValid &&
                        currentRequest.isValid[field]
                            ? ""
                            : "-error"
                    }`}>
                        {message}
                    </div>
                    : null
            }

            {
                currentRequest && currentRequest.isValid &&
                currentRequest.isValid[field]
                    ? <div className={"important-tasks-description__error-buffer"}></div>
                    : <div className={"important-tasks-description__error"}
                    >
                        Проверьте правильность заполнения
                    </div>
            }

        </div>
    );
};

export default ImportantTasksDescription;

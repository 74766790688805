import { data } from "./sout.data";

import { $api } from "../../../../../redux/services/request.config";

import Footer from "../../../../layout/Footer/Footer";
import Header from "../../../../layout/Header/Header";
import MultiRoute from "../../../../common/MultiRoute/MultiRoute";

import { useEffect } from "react";

import "./SOUT.scss";


const SOUT = () => {

    useEffect(() => {
        $api("v1/is/sewc/exists").then(res => console.log("EXISTS: ", res.data)).catch(err => console.log("ERROR: ", err));
    }, []);

    return ( 
        <>
            <Header />
            <div className="sout">
                <MultiRoute
                    breadCrumbs={true}
                    subHeader={"СОУТ"}
                    styleParent={"work-time"}
                    data={data}
                />
            </div>
            <Footer />
        </>
    );
};
 
export default SOUT;
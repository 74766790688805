import stylesTerminal from "./RegFormTerminal.module.css";

import logo from "../../../../assets/icons/authIcons/alrosaMainLogo/Logo.svg";
import "./RegForm.scss";
import passwordEye from "../../../../assets/icons/authIcons/eye.svg";
import openEyes from "../../../../assets/icons/authIcons/ic_eyes_open.svg";

import { resetErrorMessageAction } from "../../../../redux/actions/Auth/wrongAuthAction";
import UButton from "../../Button/UButton/UButton";
import file from "../../../pages/RegisterPage/terms.pdf";

import { isTerminal } from "../../../../constants";
import { InputTerminal } from "../../componentsTerminal/Input/InputTerminal";
import { ButtonTerminal } from "../../componentsTerminal/Button/ButtonTerminal";
import { Modal } from "../../Modal/Modal";
import { ScrollButton } from "../../componentsTerminal/ScrollButton/ScrollButton";
import { userRegisterThunkAction } from "../../../../redux/actions/Auth/Thunk/userRegisterThunkAction";
import { LoginSnilsMaskInput } from "../LoginFormSection/LoginSnilsMaskInput/LoginSnilsMaskInput";
import { LoginPasswordField } from "../LoginFormSection/LoginPasswordField/LoginPasswordField";
import { RegValidateResultSection } from "../RegFormSection/RegValidateResultSection/RegValidateResultSection";
import LoginFormValidationService from "../../../../frontend.services/Auth/LoginFormValidation.service";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";

const RegForm = ({ clickHandlerSupport }) => {
    const input_initial = { socNumber: "", password: "", confirmPassword: "" };
    const navigate = useNavigate();
    const inputSnils = useRef();

    const dispatch = useDispatch();

    const user = useSelector(state => state.user);

    const [onError, setOnError] = useState(false);

    const [validSnils, setValidSnils] = useState(true);
    const [validPassword, setValidPassword] = useState(true);
    const [input, setInput] = useState(input_initial);
    const [checked, setChecked] = useState(false);
    const [invalidLength, setInvalidLength] = useState(false);
    const [changedSnils, setChangedSnils] = useState("");

    const handleChangeSnils = (e) => {
        const cardValue = inputSnils.current?.value
            .replace(/\D/g, "")
            .match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
        inputSnils.current.value = !cardValue[2]
            ? cardValue[1]
            : `${cardValue[1]}-${cardValue[2]}${`${
                cardValue[3] ? `-${cardValue[3]}` : ""
            }`}${`${cardValue[4] ? ` ${cardValue[4]}` : ""}`}`;
        setChangedSnils(inputSnils.current.value); //?
        const numbers = inputSnils.current?.value.replace(/(\D)/g, "");
        setInput(prev => ({ ...prev, [e.target.name]: numbers }));
    };

    const validateSocNumber = () => {
        const validator = LoginFormValidationService;
        return validator.validate(input.socNumber,
            [
                { rule: validator.LENGTH.EQ, param: 11 },
                { rule: validator.TYPE.SNILS },
            ]);
    };

    const validatePassword = () => {
        const validator = LoginFormValidationService;
        return validator.validate(input.password,
            [
                { rule: validator.LENGTH.MIN, param: 8 },
            ]);
    };
    const validateConfirmPassword = () => {
        return (input.password.length === input.confirmPassword.length);
    };

    const submitHandler = (e) => {
        let result = false;
        const result_arr = [];

        e.preventDefault();
        setValidPassword(true);
        setInvalidLength(false);
        setValidSnils(true);

        result = validateSocNumber();
        if (result !== true) setValidSnils(false);
        result_arr.push(result);

        result = validatePassword();
        if (result !== true) setInvalidLength(true);
        result_arr.push(result);

        result = validateConfirmPassword();
        if (result !== true) setValidPassword(false);
        result_arr.push(result);

        const isValid = result_arr.filter((i) => i !== true).length < 1;

        if (isValid) {
            dispatch(userRegisterThunkAction(input, (message)=>setOnError(message)));//санка реги на токен
            if (user) {
                navigate("/authentication");
            }
        }
    };

    useEffect(() => {
        if (user) {
            navigate("/authentication");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const setToValidLabelAndInput = () => {
        setValidSnils(true);
        dispatch(resetErrorMessageAction());
    };

    useEffect(() => {
        return () => {
            setValidSnils(true);
            dispatch(resetErrorMessageAction());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //terminal -------------------------------
    const [openEye, setOpenEye] = useState(false);
    const [openEyeRepeat, setOpenEyeRepeat] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShownRepeat, setPasswordShownRepeat] = useState(false);
    const [isOpenModal, setOpenModal] = useState(false);

    const inputHandler = (e) => {
        setInput(prev => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const togglePasswordVisiblity = () => {
        setOpenEye(!openEye);
        setPasswordShown(!passwordShown);
    };
    const togglePasswordVisiblityRepeat = () => {
        setOpenEyeRepeat(!openEyeRepeat);
        setPasswordShownRepeat(!passwordShownRepeat);
    };


    if (!isTerminal) {
        return (
            <>
                <form onSubmit={submitHandler} className="register-form">
                    <img
                        className="register-form__logo"
                        src={logo}
                        alt="reg-logo"
                    />
                    <div className="register-form__wrapper">

                        <RegValidateResultSection onError={onError} validSnils={validSnils}/>

                        <LoginSnilsMaskInput
                            onError={onError}
                            inputSnils={inputSnils}
                            handleChangeSnils={handleChangeSnils}
                            setToValidLabelAndInput={setToValidLabelAndInput}
                            changedSnils={changedSnils}
                            valid={validSnils}
                        />

                    </div>
                    <div className="register-form__wrapper">

                        <LoginPasswordField
                            onChange={(e) => setInput(prev => ({ ...prev, password: e }))}
                            errorView={invalidLength ? "Пароль должен содержать не менее 8 символов" : null}
                        />


                    </div>
                    <div className="register-form__wrapper">
                        <LoginPasswordField
                            onChange={(e) => setInput(prev => ({ ...prev, confirmPassword: e }))}
                            name={"confirmPassword"}
                            title={"Повторите пароль"}
                            errorView={!validPassword ? "Пароли должны совпадать" : null}
                        />

                    </div>
                    <UButton
                        disabled={
                            !input.socNumber ||
                            !input.password ||
                            !input.confirmPassword ||
                            !checked
                        }
                    >зарегистрироваться</UButton>
                    <div className="register-form__wrapper">
                        <div className="register-form__wrapper__checkbox-text">
                            <input
                                type="checkbox"
                                className="checkboxInput"
                                checked={checked}
                                onChange={(e) => setChecked(e.target.checked)}
                            />
                            <div>
                                Ознакомление с{" "}
                                <a
                                    href={file}
                                    target="_blank"
                                    className="colored-texts"
                                    rel="noreferrer">
                                    Условиями
                                    <br />
                                    использования приложения
                                </a>
                            </div>
                        </div>
                        <div className="register-form__wrapper__login">
                            Уже есть аккаунт?{" "}
                            <span
                                onClick={() => navigate("/login")}
                                className="colored-texts">
                                Войти
                            </span>
                        </div>
                    </div>
                    <div className="register-form__support">
                        <span
                            className="colored-texts"
                            onClick={clickHandlerSupport}>
                             Запросить в поддержку
                        </span>
                    </div>
                </form>
            </>
        );
    }

    if (isTerminal) {
        return (
            <>
                <form
                    onSubmit={submitHandler}
                    onClick={setToValidLabelAndInput}
                    className={stylesTerminal.regTerminal}>
                    <img src={logo} alt="login-logo" style={{ width: "50%" }} />
                    <InputTerminal
                        name="socNumber"
                        placeholder="Начните вводить значение"
                        label="СНИЛС"
                        type="tel"
                        inputRef={inputSnils}
                        onChange={handleChangeSnils}
                        errorView={onError}
                    />
                    <InputTerminal
                        name="password"
                        placeholder="Введите пароль"
                        label="Пароль"
                        type={passwordShown ? "text" : "password"}
                        onChange={inputHandler}
                        onClick={() => setValidPassword(true)}
                        errorView={
                            invalidLength &&
                            "Пароль должен содержать не менее 8 символов"
                        }
                        trailingIcon={
                            <i
                                className={stylesTerminal.wrapperEye}
                                onClick={togglePasswordVisiblity}>
                                {openEye ? (
                                    <img src={openEyes} alt="openEye" />
                                ) : (
                                    <img src={passwordEye} alt="icon" />
                                )}
                            </i>
                        }
                    />
                    <InputTerminal
                        name="confirmPassword"
                        placeholder="Введите пароль"
                        label="Повторите пароль"
                        onClick={() => setValidPassword(true)}
                        type={passwordShownRepeat ? "text" : "password"}
                        errorView={!validPassword && "Пароли должны совпадать"}
                        onChange={inputHandler}
                        trailingIcon={
                            <i
                                className={stylesTerminal.wrapperEye}
                                onClick={togglePasswordVisiblityRepeat}>
                                {openEyeRepeat ? (
                                    <img src={openEyes} alt="openEye" />
                                ) : (
                                    <img src={passwordEye} alt="icon" />
                                )}
                            </i>
                        }
                    />
                    <ButtonTerminal
                        label="Зарегистрироваться"
                        disabled={
                            !input.socNumber ||
                            !input.password ||
                            !input.confirmPassword ||
                            !checked
                        }
                    />
                </form>
                <div className={stylesTerminal.regHelpersWrapper}>
                    <input
                        type="checkbox"
                        className={stylesTerminal.checkbox}
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                    />
                    <div>
                        Ознакомление с{" "}
                        <span
                            onClick={() => setOpenModal(true)}
                            className={stylesTerminal.coloredTexts}
                        >
                            Условиями
                            <br />
                            использования Личного кабинета
                        </span>
                    </div>
                    <Modal shown={isOpenModal} close={setOpenModal}>
                        <iframe
                            title="Pdf"
                            className={stylesTerminal.modal}
                            src={require("../../../pages/RegisterPage/terms.pdf")}
                        />
                    </Modal>
                </div>
                <div className={stylesTerminal.regHelpers}>
                    <div className={stylesTerminal.regHelpersWrapper}>
                        <span>Уже есть аккаунт?</span>
                        <ButtonTerminal
                            label="Войти"
                            variant="text"
                            onClick={() => navigate("/login")}
                        />
                    </div>
                    <ButtonTerminal
                        label="Запрос в поддержку"
                        variant="text"
                        textAlign="text-start"
                        onClick={clickHandlerSupport}
                    />
                </div>
            </>
        );
    }
};

export default RegForm;
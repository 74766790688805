import $api from "../../../../services/axiosService";
// import {getCurrentRequestAction} from "../../CurrentRequest/getCurrentRequest.action";
// import WithoutPayApplication from "../../../../../frontend.classes/Requests/WithoutPay.application";
import { clearCurrentRequestAction } from "../../CurrentRequest/clearCurrentRequest.action";
// import {setCurrentRequestAction} from "../../CurrentRequest/setCurrentRequest.action";
import { getUserRequestsThunkAction } from "../../GetUserRequests/thunk/getUserRequests.thunk.action";
import RequestServices from "../../../../../frontend.services/RequestServices/RequestServices";
import { setCurrentDocumentAction } from "../../CurrentRequest/setCurrentDocument.action";

export const getCurrentLeaderRequestThunkAction = (uid, worker, props) => async (dispatch) => {
    console.log(props);
    try {

        const response = await $api(`/v1/leaverequest/linemanager/${uid}`);
        if (response.data.isSuccess) {
            const data = await RequestServices.getOneRequest(response.data.item, worker);

            const temp = { ...response.data.item };
            temp.approvers.map(el => {
                switch (el.status) {
                case 2:
                    el.statusText = "на согласовании";
                    return el;
                case 3:
                    el.statusText = new Date(el.assignmentDate).toLocaleDateString();
                    return el;
                case 4:
                    el.statusText = "отклонена";
                    return el;
                case 5:
                    el.statusText = "снято с согласования";
                    return el;
                default:
                    return el;
                }
            });

            // const requestData = await RequestServices.downloadAttachmentsfilesInCard(response.data.item);
            dispatch(setCurrentDocumentAction({ currentRequestCard: data, currentRequest: response.data.item }));
            dispatch(getUserRequestsThunkAction());
        } else {
            dispatch(clearCurrentRequestAction());
        }
    } catch (e) {
        props.setDescription && props.setDescription("Не удалось загрузить информацию по заявке. Пожалуйста, повторите попытку позже.");
    }
};

import Header from "../../../layout/Header/Header";
import MultiRoute from "../../../common/MultiRoute/MultiRoute";
import Footer from "../../../layout/Footer/Footer";

import Breadcrumbs from "../../../common/Breadcrumbs/Breadcrumbs";

import {
    getAvailableTypesForLeaderThunkAction,
} from "../../../../redux/actions/Requests/RequestsFromLeader/thunk/getAvailableTypesForLeader.thunk.action";

import NavMenuDiv from "../../../common/NavMenuDiv/NavMenuDiv";

import { mode } from "../../../../app/mode";

import CustomLoader from "../../../common/CustomLoader/CustomLoader";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import React from "react";

const LeaderRequestsPage = () => {
    const dispatch = useDispatch();
    const requestTypes = useSelector(state => state.requests.types);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        dispatch(getAvailableTypesForLeaderThunkAction({ option: 3, setLoader: setLoader }));

        // dispatch(unsetUserRequestAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Header />
            <div style={{ minHeight: "35vh", width: "1160px", margin: "0 auto" }}>
                {loader ? <CustomLoader /> :
                    (
                        requestTypes &&
                        requestTypes.length !== 0
                            ?
                            <>
                                <MultiRoute
                                    breadCrumbs={true}
                                    subHeader={"Заявки руководителя"}
                                    styleParent={"leader-requests"}
                                    data={requestTypes}
                                />
                                {/*path: `/main/hr-services/leader-requests/${el.id}`,*/}
                                {/*title: el.name,*/}
                                {
                                    mode === "TEST" || mode === "UNSTABLE" ?
                                        <div style={{ width: "1160px", margin: "20px auto" }}>
                                            <NavMenuDiv
                                                path={"/main/hr-services/leader-requests/17"}
                                                title={"Привлечение к работе в выходные и праздничные дни"}
                                                styleParent={"leader-requests"}
                                                secured={false}
                                                unep={false}
                                            />
                                        </div>
                                        : null
                                }

                            </>


                            : <div style={{ margin: "60px auto", width: "1160px" }}>
                                <Breadcrumbs />
                                <div className={"sub-header"}>Заявки руководителя</div>
                                <div style={{ margin: "60px auto", fontSize: "24px", lineHeight: "32px", color: "gray" }}>
                                    На данный момент нет доступных заявок руководителя
                                </div>

                            </div>
                    )
                }


            </div>

            <Footer />
        </>
    );
};

export default LeaderRequestsPage;

import { releaseUnepAction } from "../releaseUnep.action";
import $api from "../../../../../services/axiosService";
import ElectronicSignatureService from "../../../../../../frontend.services/ElectronicSignature.service";

import moment from "moment";

export const releaseUnepThunkAction = () => async (dispatch) => {
    try {
        const responseInfo = await $api.get("/v1.0/c/u/0");
        // const passport = await $api.get("/v1.0/c/u/5");//test
        const passportPROD = await $api.get("/v1.0/c/u/15");//prod

        const responseNumber = await $api.get("/v1.0/c/u/10");
        const inn = await $api.get("/v1.0/c/u/3");
        const snils = await $api.get("/v1.0/c/u/4");
        const responseEmail = await $api.get("/v1.0/c/u/9");
        const birthTime = await $api.get("/v1.0/c/u/1");
        const data = await ElectronicSignatureService.formingUnep([
            "Усиленная неквалифицированная электронная подпись",
            "Облачное",
            // eslint-disable-next-line max-len
            `${responseInfo.data.data.personal.lastNameRu} ${responseInfo.data.data.personal.firstNameRu} ${responseInfo.data.data.personal.secondNameRu}`,
            passportPROD.data.data.passport.seria + " " + passportPROD.data.data.passport.number,
            new Date(passportPROD.data.data.passport.date).toLocaleDateString(),
            passportPROD.data.data.passport.code && passportPROD.data.data.passport.code,
            passportPROD.data.data.passport.grant,
            responseNumber.data.data.phoneNumber,
            inn.data.data.inn,
            snils.data.data.socNumber
                .replace(
                    /(\d{3})\.?-?-?(\d{3})\.?-?-?(\d{3})\.? ?(\d{2})/g,
                    "$1-$2-$3 $4",
                ),
            responseEmail.data.data.email,
        ], passportPROD.data.data.passport.isForeigner); ///prod
        const information = {
            inn: inn.data.data.inn,
            snils: snils.data.data.socNumber,
            lastname: responseInfo.data.data.personal.lastNameRu,
            firstname: responseInfo.data.data.personal.firstNameRu,
            middlename: responseInfo.data.data.personal.secondNameRu,
            email: responseEmail.data.data.email,
            phone: responseNumber.data.data.phoneNumber.slice(1, responseNumber.data.data.phoneNumber.length),
            birthDate: moment(birthTime.data.data.birthDate).format("YYYY-MM-DD"),
            series: passportPROD.data.data.passport.seria,
            number: passportPROD.data.data.passport.number,
            issueDate: moment(passportPROD.data.data.passport.date).format("YYYY-MM-DD"),
            issueOrganizationId: passportPROD.data.data.passport.code,
            grant: passportPROD.data.data.passport.grant,
            isForeigner: passportPROD.data.data.passport.isForeigner,
        };

        dispatch(releaseUnepAction({
            requestData: data,
            requestInfo: information,
        }));
        // }
    } catch (e) {
        // console.log('releaseUnepThunkAction: ', e.response.data)
    }
};

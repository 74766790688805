import {
    DELETE_PEOPLE_HANDLER_CURRENT_REQUEST,
} from "../../../enums/Request/request.types";

export const deleteStudentHandlerCurrentRequest = (data) => {
    return {
        type: DELETE_PEOPLE_HANDLER_CURRENT_REQUEST,
        payload: data,
    };
};

export const GET_EMPLOYEE_LIST = "GET_EMPLOYEE_LIST";
export const CLEAR_EMPLOYEE_LIST = "CLEAR_EMPLOYEE_LIST";
export const ADD_SUBSTITUTION = "ADD_SUBSTITUTION";
export const DELETE_SUBSTITUTION = "DELETE_SUBSTITUTION";

export const ADD_ERR_DESCRIPTION_EMPLOYEE_LIST = "ADD_ERR_DESCRIPTION_EMPLOYEE_LIST";
export const CLEAR_ERROR_DESCRIPTION_EMPLOYEE_LIST = "CLEAR_ERROR_DESCRIPTION_EMPLOYEE_LIST";

//EmployeeDetail
export const GET_EMPLOYEE_DETAIL_INFORMATION = "GET_EMPLOYEE_DETAIL_INFORMATION";
export const CLEAR_EMPLOYEE_DETAIL_INFORMATION = "GET_EMPLOYEE_DETAIL_INFORMATION";


import $api from "../../../../../services/axiosService";
import { requestSenderAction } from "../requestSender.action";
import { deleteUnepIssueAction } from "../deleteUnepIssue.action";

export const createCertificateWhithSmsCodeThunkAction = (props, issueId) => async (dispatch) => {
    try {

        const response = await $api.post(`v1/c/certificates/issues/${issueId}/sign/sms`);
        if (response.status === 200) {
            props.setLoader(false);
            props.setPhoneToggle(true);
            dispatch(requestSenderAction({ sent: "byPhone" }));
            return props.setPhoneToggle(" +7 (***) *** **** ");
        }

        props.setLoader(false);
        props.setPhoneToggle(true);
        return props.setPhoneToggle(" +7 (***) *** **** ");
    } catch (e) {
        dispatch(deleteUnepIssueAction());
        props.setLoader(false);
        return props.setDescription("Ошибка соединения с удостоверяющим центром. Оформите заявку в службу поддержки пользователей ");
    }
};

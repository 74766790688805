import { LineLeftArrow } from "../../../../assets/icons/Claendar/LineLeft";
import { LineRightArrow } from "../../../../assets/icons/Claendar/LineRight";
import { isTerminal } from "../../../../constants";

import React, { memo, useEffect, useState } from "react";
import DatePicker from "react-datepicker";


isTerminal && import("./AnnualCalendarTerminal.scss");

export const AnnualCalendarForTerminal = memo(({
    firstDisplayedMonth,
    shown,
    onClose,
    type,
    year,
    mausePosition,
}) => {
    const [startDate] = useState(null);
    const [sizeMarginTop, setSizeMarginTop] = useState(null);
    const [currentPage, setCurrentPage] = useState(firstDisplayedMonth);
    const [newYear, setNewYear] = useState(null);
    
    const nextPage = () => {
        if (currentPage === 9) {
            setCurrentPage(1);
            setNewYear(newYear + 1);
        } else {
            setCurrentPage(currentPage + 4);
        }  
    };

    const backPage = () => {
        if (currentPage === 1) {
            setCurrentPage(9);
            setNewYear(newYear - 1);
        } else {
            setCurrentPage(currentPage - 4);
        } 
    };

    const onCloseCallendar = (e) => {
        e.target.className === "annual-calendar" && onClose();
    };

    useEffect(() => {
        switch (type.type) {
        case "Другие отпуска":
        case "Плановые дни отдыха":
            setSizeMarginTop(mausePosition.y - 10);
            break;
        default:
            setSizeMarginTop(mausePosition.y);
            break;
        }
        setCurrentPage(firstDisplayedMonth);
    }, [firstDisplayedMonth, mausePosition.y, type.type]);

    useEffect(() => {
        setNewYear(year);
    }, [year]);

    const highlightWithRanges = [
        {
            "react-datepicker__day--highlighted-custom-1": type.data?.noPlan,
        },
        {
            "react-datepicker__day--highlighted-custom-2": type.data?.allPlan,
        },
    ];
    return (
        shown 
            ? (
                <div 
                    className="annual-calendar"
                    onClick={onCloseCallendar}>
                    <div
                        className="annual-calendar__container" 
                        style={{ top: sizeMarginTop }}
                    >   
                        <div className="annual-calendar__arrow left" onClick={backPage}>
                            <LineLeftArrow />
                        </div>
                        <DatePicker
                            calendarClassName="custom-react-datpicker"
                            locale="ru"
                            readOnly
                            selected={startDate}
                            fixedHeight
                            disabled
                            highlightDates={highlightWithRanges}
                            disabledKeyboardNavigation
                            openToDate={new Date(`${newYear}/` + currentPage + "/01")}
                            monthsShown={4}
                            inline
                        />
                        <div className="annual-calendar__arrow right" onClick={nextPage}>
                            <LineRightArrow />
                        </div>
                    </div>
                </div>
            )
            : null
    );
});

import { $api } from "../../../../services/request.config";
import { getActualDocsForBubbleAction } from "../../../Requests/Hr-documents/getActualDocsForBubble.action";

export const getActualDocumentsThunkAction = () => async (dispatch) => {
    try {
        if (localStorage.getItem("agreement") > 0) {
            if (localStorage.getItem("documentSignType") > 0) {
                const actualDocuments = await $api.get(
                    "v3/directum/documents/actual",
                );
                localStorage.setItem(
                    "hasActualDocuments",
                    actualDocuments.data.hasActualDocuments,
                );
                dispatch(getActualDocsForBubbleAction(actualDocuments.data.actualDocuments.length));

            }
        }
    } catch (error) {
        localStorage.setItem("hasActualDocuments", "false");
        throw new Error(error.response);
    }
};

import RoundedAddItemButton from "../../UI/RoundedAddItemButton/RoundedAddItemButton";
import RoundedRemoveItemButton from "../../UI/RoundedRemoveItemButton/RoundedRemoveItemButton";

import {
    addObjKeyYoArrayAction,
} from "../../../redux/actions/Requests/currentRequestControls/addObjKeyYoArray.action";

import {
    removeObjfromArrayAction,
} from "../../../redux/actions/Requests/currentRequestControls/removeObjfromArray.action";

import DropdownMenuTest from "../Menu/DropdownMenuTest/DropdownMenuTest";

import {
    jobHours,
} from "../../pages/HRServicesPage/LeaderRequestsPage/LRPage/RecruitmentToWorkOnHolidays/Employe/jobHours.data";

import DropdownMenu from "../Menu/DropdownMenu/DropdownMenu";

import DatePopupSelector from "../Inputs/Calendar/DatePopupSelector/DatePopupSelector";

import DayItem from "../../pages/HRServicesPage/LeaderRequestsPage/LRPage/RecruitmentToWorkOnHolidays/DayItem/DayItem";

import React, { useCallback, useState, forwardRef } from "react";
import ReactDatePicker from "react-datepicker";
import { v4 as uuid } from "uuid";
import moment from "moment";
import ru from "date-fns/locale/ru";

import "./DateSelectorList.scss";

import { useDispatch, useSelector } from "react-redux";

const AddBox = forwardRef(({ onClick, ...props }, ref) => {
    return (
        <div
            onClick={onClick}
            ref={ref}>
            <RoundedAddItemButton {...props} />
        </div>
    );
});

const ItemBox = ({ value }) => {
    return (
        <div
            className="date-selector-list__item"
        >
            <div>{value ? value : "Выберите дату"}</div>
            <div className="date-selector-list__item__buffer"></div>
        </div>
    );
};

const DateSelectorList = (
    {
        element,
        elementIndex,
        parentStyle,
    },
) => {
    const currentRequest = useSelector(
        (state) => state.requests.currentRequest,
    );
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(0);
    console.log(visible);
    const addItemHandler = useCallback((date) => {
        console.log(date);
        dispatch(addObjKeyYoArrayAction({
            arrayKey: "days",
            objKeyName: "jobDate",
            objKey: moment(date).format("YYYY-MM-DD"),
            indexInArray: element.days.length,
            elementIndex: elementIndex,
            fieldArray: "employees",
        }));
        // setDatesArray(prev => {
        //     const temp = [...prev];
        //     temp.push(date);
        //     return temp;
        // });
    }, []);


    const removeItemHandler = useCallback((index) => {
        dispatch(removeObjfromArrayAction({
            arrayKeyRemove: "days",
            indexInArrayRemove: index,
            elementIndexRemove: elementIndex,
            fieldArrayRemove: "employees",
        }));
        //     setDatesArray(prev => prev.filter((_, i) => i !== index));
    }, []);
    // const item = document


    return (
        <>
            <div className="date-selector-list">
                {
                    element.days.map((el, index) => {
                        return (

                            <button
                                className="date-selector-list__wrapper"
                                key={uuid()}
                                tabIndex={0}
                                onFocus={(event) => {
                                    setVisible(index);
                                    event.target.classList.add("date-selector-list__wrapper--focus");
                                    // event.target.focus();
                                    console.log("FOCUS SET TO: ", index);
                                }}
                                onBlur={(event) => {
                                    // setVisible(false);
                                    event.target.classList.remove("date-selector-list__wrapper--focus");
                                }}
                            >
                                {
                                    el.jobDate ?
                                        <>
                                            <ItemBox value={moment(el.jobDate).format("DD.MM.YYYY")} />
                                            <RoundedRemoveItemButton
                                                handler={() => removeItemHandler(index)}
                                            />
                                        </> :
                                        null

                                }


                            </button>


                        );
                    })
                }
                <div className="date-selector-list__add-date-box">
                    <div>Добавить дату привлечения</div>
                    <div>
                        <ReactDatePicker
                            excludeDates={element.days}
                            locale={ru}
                            onChange={addItemHandler}
                            customInput={<AddBox />}
                            dateFormat={"dd.MM.yyyy"}
                        />
                    </div>
                </div>
            </div>
            {
                element.days.map((el, index) => {
                    return (
                        <>
                            {
                                // (visible || visible === 0) &&
                                visible === index ? (
                                    <DayItem index={index} setVisible={setVisible} elementIndex={elementIndex}
                                        parentStyle={parentStyle} />) : null
                            }
                        </>
                    );
                })
            }

        </>

    );
};

export default React.memo(DateSelectorList);

import $api from "../../../../services/axiosService";

import { getAllUserRequestsAction } from "../getAllUserRequests.action";
import RequestServices from "../../../../../frontend.services/RequestServices/RequestServices";


// TODO REFACTOR
export const getUserRequestsThunkAction = (props) => async (dispatch) => {
    try {
        const response = await $api("v2/request/all/for/po");
        if (response.status === 200) {
            const data = await RequestServices.getUserRequestsWithFile(response.data.items);
            dispatch(getAllUserRequestsAction(data));
        }
    } catch (e) {
        if (props) {
            props.setErrDescription("Что-то пошло не так, повторите попытку позже.");
            props.setErrorModal(true);
        }
        // console.log("ERROR WHILE GETTING USER REQUESTS: ", e.response)
    }
};

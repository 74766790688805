import CurrentRequestCard from "../CurrentRequestCard/CurrentRequestCard";

import ErrorModal from "../../../Modal/ModalConditions/Profile/ErrorModal/ErrorModal";


import React from "react";
import { useEffect } from "react";

function UserRequestCard(props) {
    useEffect(() => {
        return () => {
            props.setDescription(false);
        };
    }, []);
    return (
        <>
            {props.description ?
                <ErrorModal
                    description={props.description}
                    setError={props.close}
                />
                : <CurrentRequestCard
                    isLeader={props.isLeader}
                    setDescription={props.setDescription}
                    description={props.description}
                    userRequests={props.userRequests}
                    close={props.close}
                />
            }

        </>
    );
}

export default UserRequestCard;

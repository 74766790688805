import React from "react";

export const LoginEmailMaskInput = ({onError, valid,email, disabled, setToValidLabelAndInput, inputEmail, setEmail}) => {
    return (
        <input
            className={
                (!valid &&
                    "login__wrapper__input-invalid") ||
                (onError &&
                    "login__wrapper__input-invalid") ||
                "login__wrapper__input"
            }
            value={email}
            disabled={disabled}
            name={"email"}
            onClick={setToValidLabelAndInput}
            type={"text"}
            ref={inputEmail}
            onChange={(e) => setEmail(e.target.value)
            }
        />
    );
};
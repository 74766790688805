import { initialState } from "../../init/initialState";
import { ADD_USER_STATE, GET_WORKER_APPOINTMENTS, SET_USER_LOGIN, UNSET_USER } from "../../enums/AuthType";
import { GET_ACTUAL_DOCUMENTS } from "../../enums/Documents/DocumentsType";
import { CLEAR_MAINTENANCENOTIFICATION, GET_MAINTENANCENOTIFICATION } from "../../enums/Common/Common";

export const authUserReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
    case ADD_USER_STATE:
        return payload;
    case SET_USER_LOGIN:
        return payload;
    case UNSET_USER:
        return null;
    case "IS_LEADER":
        return { ...state, isLeader: payload };
    case "WORK_SIGN_TYPE":
        return { ...state, workSignType: payload };
    case GET_WORKER_APPOINTMENTS:
        return { ...state, appointments: payload };
    case GET_MAINTENANCENOTIFICATION:
        return { ...state, message: payload };
    case CLEAR_MAINTENANCENOTIFICATION:
        return { ...state, message: null };
    default:
        return state;
    }
};

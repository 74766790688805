import { emploumentData } from "./emploument.data";

import HrDocumentCard from "./HrDocumentCard/HrDocumentCard";
import ReportAnError from "./ReportAnError/ReportAnError";

import { isTerminal } from "../../../../constants";
import { Modal } from "../../Modal/Modal";
import {
    setCurrentHrDocsThunkAction,
} from "../../../../redux/actions/Requests/Hr-documents/thunk/setCurrentHrDocs.thunk.action";
import {
    unsetCurrentHrDocumentAction,
} from "../../../../redux/actions/Requests/Hr-documents/unsetCurrentHrDocument.action";
import ErrorModal from "../../Modal/ModalConditions/Profile/ErrorModal/ErrorModal";

import {
    getActualDocumentsThunkAction,
} from "../../../../redux/actions/Documents/ActualDocuments/thunk/getActualDocuments.thunk.action";
import { LeftArrow } from "../../../../assets/icons/LeftArrow";
import { RightArrow } from "../../../../assets/icons/RightArrow";
import { ScrollButton } from "../../componentsTerminal/ScrollButton/ScrollButton";

import { ClipLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";

import ReactPaginate from "react-paginate";

isTerminal ? import("./EmploymentDocumentsTerminal.scss") : import("./EmploymentDocuments.scss");

const EmploymentDocuments = (
    {
        currentRowsHandler,
        rowsPerPage,
        hrDocuments,
    },
) => {
    const dispatch = useDispatch();

    const [modalShownPackage, toggleModalShownPackages] = useState(false);
    const [reportAnError, setReportAnError] = useState(false);
    const [docInfo, setDocInfo] = useState({});
    const [errorModal, setErrorModal] = useState(false);
    const [errDescription, setErrDescription] = useState("");
    const [props] = useState({
        errDescription: errDescription,
        helper: closeErrorModal,
        setErrDescription: setErrDescription,
        closeHandlerPackages: closeHandlerPackages,
    });
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + rowsPerPage;
    const currentItems = hrDocuments && hrDocuments.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(hrDocuments?.length / rowsPerPage);

    function closeHandlerPackages(e) {
        if (localStorage.getItem("hasActualDocuments") === "true") {
            dispatch(getActualDocumentsThunkAction());
        }
        toggleModalShownPackages(false);
        dispatch(unsetCurrentHrDocumentAction());
    }

    function openReportRequest(hrDocument, index) {
        toggleModalShownPackages(false);
        setDocInfo({ hrDocument, index });
        setTimeout(() => setReportAnError(true), 0);
    }

    function closeReportRequest(e) {
        setReportAnError(false);
    }

    function closeErrorModal() {
        setErrorModal(!errorModal);
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * rowsPerPage) % hrDocuments.length;
        setItemOffset(newOffset);
    };

    function clickHandlerPackages(packageId, workerId, typeViewTo) {
        dispatch(
            setCurrentHrDocsThunkAction(packageId, workerId, typeViewTo, props),
        );
        toggleModalShownPackages(!modalShownPackage);
    }

    useEffect(() => {
        if (itemOffset < rowsPerPage) setItemOffset(0);
        setItemOffset(Math.floor(itemOffset / rowsPerPage) * rowsPerPage);
    }, [itemOffset, rowsPerPage]);

    return !hrDocuments ? (
        <ClipLoader color="#6DCDF6" />
    ) : (
        <div>
            <div className="hrTable-container">
                <table className="hr-table">
                    <thead>
                        <tr className="hr-table__row">
                            {emploumentData &&
                            emploumentData.map((el) => {
                                return (
                                    <th
                                        key={el.id}
                                        style={{ width: `${el.width}` }}
                                        className="hr-table__row__item"
                                    >
                                        {el.title}
                                        {el.icon ? (
                                            <img
                                                src={el.icon}
                                                alt="triangle"
                                                className="header-triangle"
                                            />
                                        ) : null}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    {/*<tr>*/}
                    <tbody>
                        {
                            currentItems &&
                        currentItems.map((el, i) => (
                            <tr
                                onClick={() =>
                                    clickHandlerPackages(
                                        el.packageId,
                                        el.workerId,
                                        el.typeViewTo,
                                    )
                                }
                                key={el.packageId}
                                className={
                                    el.typeWeight
                                        ? `${
                                            el.typeColor
                                                ? `hr-row-request-inActive ${el.typeColor} ${el.typeWeight}`
                                                : `hr-row-request-inActive ${el.typeWeight}`
                                        }  `
                                        : `${
                                            el.typeColor
                                                ? `hr-row-request-inActive ${el.typeColor}`
                                                : "hr-row-request-inActive"
                                        }`
                                }
                            >
                                <td style={{ width: isTerminal ? "111px" : "113px" }}>
                                    {el.kind.length > 20
                                        ? el.kind.slice(0, 10) + "..."
                                        : el.kind}
                                </td>
                                <td style={{ width: isTerminal ? "230px" : "386px" }}>
                                    {isTerminal ? el.name.length >= 70
                                        ? el.name.slice(0, 70) + "..."
                                        : el.name : el.name}
                                </td>
                                <td style={{ width: isTerminal ? "210px" : "164px" }}>
                                    {el.typeViewTo ? el.typeViewTo : "  "}
                                </td>
                                <td style={{ width: isTerminal ? "0" : "181px" }}>
                                    {el.dateTo ? el.dateTo : "  "}
                                </td>
                                <td
                                    // onClick={(e) => e.stopPropagation()}

                                    style={{ width: isTerminal ? "191px" : "192px" }}
                                >
                                    {el.packageId ? (
                                        <span className="package-colored-link">
                                                Document file.docx
                                        </span>
                                    ) : (
                                        "  "
                                    )}
                                </td>
                                <td style={{ width: isTerminal ? "138px" : "160px" }}>
                                    {el.statusText}
                                </td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
                {
                    !hrDocuments.length
                        ? <div></div>
                        : <div className={isTerminal && "pagination-wrapper"}>
                            {!isTerminal && <div className="pagination-container">
                                <div className="pagination-container__rows">
                                    <div
                                        onClick={() => currentRowsHandler(5)}
                                        className={
                                            rowsPerPage === 5
                                                ? "rowsPerPage-number-active"
                                                : "rowsPerPage-number-inactive"
                                        }
                                    >
                                        5
                                    </div>
                                    <div
                                        onClick={() => currentRowsHandler(10)}
                                        className={
                                            rowsPerPage === 10
                                                ? "rowsPerPage-number-active"
                                                : "rowsPerPage-number-inactive"
                                        }
                                    >
                                        10
                                    </div>
                                    <div
                                        onClick={() => currentRowsHandler(20)}
                                        className={
                                            rowsPerPage === 20
                                                ? "rowsPerPage-number-active"
                                                : "rowsPerPage-number-inactive"
                                        }
                                    >
                                        20
                                    </div>

                                    <div
                                        onClick={() => currentRowsHandler(50)}
                                        className={
                                            rowsPerPage === 50
                                                ? "rowsPerPage-number-active"
                                                : "rowsPerPage-number-inactive"
                                        }
                                    >
                                        50
                                    </div>
                                </div>
                            </div>}
                            <ReactPaginate
                                className={"pagination"}
                                breakLabel=".."
                                nextLabel={isTerminal ? <RightArrow /> : ">"}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                pageCount={pageCount}
                                previousLabel={isTerminal ? <LeftArrow /> : "<"}
                                renderOnZeroPageCount={null}
                                forcePage={Math.floor(itemOffset / rowsPerPage)}
                            />
                        </div>
                }
            </div>
            <Modal shown={reportAnError} close={closeReportRequest}>
                <ReportAnError
                    information={docInfo}
                    close={closeReportRequest}
                />
            </Modal>
            <Modal shown={modalShownPackage} close={closeHandlerPackages}>
                <HrDocumentCard
                    openReportRequest={openReportRequest}
                    userRequests={hrDocuments}
                    close={closeHandlerPackages}
                    name={"test"}
                    helpers={props}
                />
            </Modal>
            <Modal shown={errorModal} close={closeErrorModal}>
                <ErrorModal
                    description={errDescription}
                    setError={closeErrorModal}
                    route={"/documents"}
                />
            </Modal>
        </div>
    );
};

export default React.memo(EmploymentDocuments);

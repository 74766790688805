import {
    addInputToStudentActon,
} from "../../../../redux/actions/Requests/currentRequestControls/addInputToStudent.acton";

import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const RequestInputInArray = forwardRef(function RequestInput(
    {
        parentStyle,
        label,
        value,
        changeHandler,
        maxLength,
        stateProperty,
        basicInput,
        errorMessage,
        helper,
        type,
        letter,
        index,
        field,
        uploader,
        handler,
    },
    ref,
) {
    const dispatch = useDispatch();
    const area = document.getElementById(`area-${stateProperty}`);
    const count = document.querySelector(`.count-${stateProperty}`);

    function countLetters() {
        const textlength = area?.value.length; // #2
        count.innerText = `${textlength}`; // #3
        const myElement = document.getElementById(`count-${stateProperty}`);
        if (count && count.innerText > 250) {
            myElement.style.color = "red";
        } else {
            myElement.style.color = "black";
        }
    }

    const [input, setInput] = useState(value ? value : "");

    function addInputToStudent(event) {
        setInput(event.target.value);
    }


    function onFocusOutHandler() {
        dispatch(
            addInputToStudentActon({
                input,
                index,
                field,
            }),
        );
        // setInput("");
    }

    useEffect(() => {
        console.log(value, "PPPOOOBBBEEEDDDAAA");
        setInput(value);
    }, [value]);

    console.log("input", value, input);
    return (
        <div className={`${parentStyle}-comment__container`}>
            <label
                htmlFor="comment"
                className={`${parentStyle}-comment__label`}
            >
                {label}
            </label>
            <input
                onBlur={handler ? () => handler(input, index, field) : onFocusOutHandler}
                ref={ref}
                name="comment"
                value={input}
                id={`area-${stateProperty}`}
                onChange={uploader ? addInputToStudent : changeHandler}
                maxLength={maxLength ? maxLength : null}
                onKeyUp={countLetters}
                className={
                    !errorMessage
                        ? `${parentStyle}-comment`
                        : `${parentStyle}-commentError`
                }
                type={"text"}
            />
            <div style={{ bottom: "60px" }} className={`${parentStyle}-comment__container__counter`}>
                <span
                    id={`count-${stateProperty}`}
                    className={`count-${stateProperty}`}
                >
                    {input?.length ? input.length : 0}
                </span>{" "}
                /
                {letter}
            </div>
            {errorMessage ? (
                <div className="date-popup-selector__error-message">
                    {errorMessage}
                </div>
            ) : (
                <div className="date-popup-selector__buffer"></div>
            )}
            {helper && (
                <p className={`${parentStyle}-comment__underline`}>{helper}</p>
            )}
            {!basicInput && (
                <p className={`${parentStyle}-comment__underline`}>
                    * Поля обязательные для заполнения
                </p>
            )}

        </div>
    );
});

export default RequestInputInArray;

import {
    addStudentHandlerCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/addStudentHandler.currentRequest";
import {
    deleteStudentHandlerCurrentRequest,
} from "../../../../../../../redux/actions/Requests/currentRequestControls/deleteStudentHandler.currentRequest";
import bluePlus from "../../../../../../common/Inputs/DatePeriodControlled/BluePlusCircle.svg";
import redPlus from "../../../../../../common/Inputs/DatePeriodControlled/RedRotatedPlusCircle.svg";

import SingleUploaderNew from "../../../../../../common/SingleUploaderNew/SingleUploaderNew";
import RequestInputInArray from "../../../../../../common/Inputs/RequestInputInArray/RequestInputInArray";


import { useDispatch, useSelector } from "react-redux";

import { v4 as uuidv4 } from "uuid";
import React, { useRef } from "react";

import "./StudentInformation.scss";

const AddStudentsButton = (
    {
        parentStyle,
        // setState,
    },
) => {
    const dispatch = useDispatch();

    function addStudentHandler(e) {
        dispatch(addStudentHandlerCurrentRequest({
            stateName: "students",
            data: {
                fullName: "",
                attachments: [],
            },
        }));
    }

    return (
        <div className={`${parentStyle}__button`}>
            <span>Добавить студента</span>
            <img
                onClick={addStudentHandler}
                className={`${parentStyle}__button__blue-plus`}
                src={bluePlus}
                alt="bluePlus"
            />
        </div>
    );
};

const DeleteStudentsButton = (
    {
        parentStyle,
        // setState,
        index,
    },
) => {
    const dispatch = useDispatch();

    function deleteStudentHandler(e) {
        dispatch(deleteStudentHandlerCurrentRequest({ index, stateName: "students" }));
    }

    return (
        <div className={`${parentStyle}__button`}>
            <span>Удалить добавленного ниже студента</span>
            <img
                onClick={deleteStudentHandler}
                className={`${parentStyle}__button__red-plus`}
                src={redPlus}
                alt="redPlus"
            />
        </div>
    );
};

const StudentInformation = (
    {
        // student,
        parentStyle,
        index,
        studentsLength,
    },
) => {

    const currentRequest = useSelector(
        (state) => state.requests.currentRequest,
    );
    const inputRef = useRef();

    return (
        <div key={uuidv4()} className={`${parentStyle}-student`}>
            {studentsLength > 1 && index !== 0 && (
                <DeleteStudentsButton parentStyle={parentStyle} index={index} />
            )}
            <RequestInputInArray
                uploader={true}
                value={
                    currentRequest &&
                    currentRequest.students &&
                    currentRequest.students[index].fullName
                }
                helper={
                    "Обращаем внимание: каждый вид документа должен быть отсканирован одним файлом"
                }
                ref={inputRef}
                basicInput={true}
                maxLength={300}
                stateProperty={"fullName"}
                field={"fullName"}
                index={index}
                label={"ФИО студента *"}
                errorMessage={
                    currentRequest &&
                    currentRequest.students &&
                    currentRequest.students[index] &&
                    !currentRequest.students[index].fullNameValid
                        ? ""
                        : "Обязательное поле"
                }
                parentStyle={"unpaid-practice"}
                letter={300}
            />
            <SingleUploaderNew
                fileName={"Учебная_практика.pdf"}
                index={index}
                i={0}
                subTypeNumber={1}
                label={"Паспортные данные *"}
                field={"students"}
                errorMessage={
                    currentRequest &&
                    currentRequest.students &&
                    currentRequest.students[index] &&
                    currentRequest.students[index].attachments &&
                    currentRequest.students[index].attachments[0] &&
                    currentRequest.students[index].attachments[0].attachValidation
                        ? "Пожалуйста, прикрепите документ"
                        : ""
                }
                // errorMessage={"Пожалуйста, прикрепите документ"}
            />
            <SingleUploaderNew
                fileName={"Учебная_практика.pdf"}
                label={"Данные студенческого билета *"}
                index={index}
                i={1}
                field={"students"}
                subTypeNumber={5}
                errorMessage={
                    currentRequest &&
                    currentRequest.students &&
                    currentRequest.students[index] &&
                    currentRequest.students[index].attachments &&
                    currentRequest.students[index].attachments[1] &&
                    currentRequest.students[index].attachments[1].attachValidation
                        ? "Пожалуйста, прикрепите документ"
                        : ""
                }
            />
            <SingleUploaderNew
                fileName={"Учебная_практика.pdf"}
                index={index}
                subTypeNumber={10}
                i={2}
                label={"Проект договора с ВУЗом *"}
                field={"students"}
                sub={"До 50 Мб, принимаемые форматы: .jpg, .png, .pdf, .docx. " +
                    "При наличии документа в редактируемом формате (.docx) необходимо приложить его"}
                errorMessage={
                    currentRequest &&
                    currentRequest.students &&
                    currentRequest.students[index] &&
                    currentRequest.students[index].attachments &&
                    currentRequest.students[index].attachments[2] &&
                    currentRequest.students[index].attachments[2].attachValidation
                        ? "Пожалуйста, прикрепите документ"
                        : ""
                }
            />
            <SingleUploaderNew
                fileName={"Учебная_практика.pdf"}
                label={"Копия согласия на обработку персональных данных *"}
                index={index}
                i={3}
                subTypeNumber={15}
                field={"students"}
                errorMessage={
                    currentRequest &&
                    currentRequest.students &&
                    currentRequest.students[index] &&
                    currentRequest.students[index].attachments &&
                    currentRequest.students[index].attachments[3] &&
                    currentRequest.students[index].attachments[3].attachValidation
                        ? "Пожалуйста, прикрепите документ"
                        : ""
                }
            />
            <SingleUploaderNew
                fileName={"Учебная_практика.pdf"}
                label={"Копия справки о вакцинации "}
                index={index}
                i={4}
                subTypeNumber={20}
                field={"students"}
            />

            {index === studentsLength - 1 && (
                <AddStudentsButton parentStyle={parentStyle} />
            )}
        </div>
    );
};

export default StudentInformation;

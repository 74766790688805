import ImportantTasksDropdownTreeItem from "./ImportantTasksDropdownTreeItem";

import {
    editStructureAction,
} from "../../../redux/actions/Requests/RequestsFromLeader/ImportanyTask/editStructure.action";
import {
    setSelectedStructItemAction,
} from "../../../redux/actions/Requests/currentRequestControls/ImportantTask/setSelectedStructItem.action";
import {
    getEmployeesByStructIdThunkAction,
} from "../../../redux/actions/Requests/LeaderRequests/thunk/getEmployeesByStructId.thunk.action";

import { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";




export const ImportantTasksDropdownTreeSelector = ({tree, setIsMenuActive, setWorkersState}) => {

    const ref = useRef(null);
    const dispatch = useDispatch();

    const currentSelectedId = useSelector(state => parseInt(state.requests?.currentRequest?.structId));

    const [selectedId, setSelectedId] = useState(0);

    const [treePath, setTreePath] = useState([]);


    function acceptHandler() {
        dispatch(editStructureAction());
        dispatch(setSelectedStructItemAction(ref.current));
        dispatch(getEmployeesByStructIdThunkAction(ref.current.id));
        setIsMenuActive(false);
        setWorkersState([{ pid: "", amount: "", name: "" }]);
    }


    function declineHandler() {
        setIsMenuActive(false);
    }

    function paths(items) {
        const arr = [];
        function oneStep(items, parent_id = 0) {
            if (typeof(items) === "object") {
                for (const key in items) {
                    if (items[key].hasOwnProperty("id")) {
                        const id = items[key].id;
                        let _tmp = [];
                        if (parent_id){
                            _tmp = [...arr[parent_id]];
                        }
                        arr[id] = [..._tmp, id];
                    }
                    if (items[key].hasOwnProperty("children")) oneStep(items[key].children, items[key].id);
                }
            }
        }
        oneStep(items);
        return arr;
    }

    useEffect(() => {
        setTreePath(paths(tree));
    }, [tree]);

    useEffect(() => {
        setSelectedId(currentSelectedId);
    }, [currentSelectedId]);

    return tree
        ? (
            <>
                <div className={"tree"}>
                    {
                        tree.map(node => <ImportantTasksDropdownTreeItem
                            key={node.id}
                            item={node}
                            selectedId={selectedId}
                            setSelectedId={setSelectedId}
                            selectedIdPath={treePath[selectedId]}
                            ref={ref}
                        />)
                    }
                </div>
                <div className={"tree__buttons"}>
                    <div
                        className={"tree__buttons__decline"}
                        onClick={declineHandler}
                    >
                        ОТМЕНА
                    </div>
                    <div
                        className={"tree__buttons__accept"}
                        onClick={acceptHandler}
                    >
                        ПРИНЯТЬ
                    </div>
                </div>
            </>

        )
        : <div>loading</div>;
};
import stylesWeb from "./Header.module.css";
import stylesTerminal from "./HeaderTerminal.module.css";

import { isTerminal } from "../../../constants";
import photoWeb from "../../../assets/icons/headerIcons/feedbackBannerALROSA.png";
import photoTerminal from "../../../assets/icons/headerIcons/pic@3x.png";
import NavMenu from "../../common/HeaderItems/NavMenu/NavMenu";
import WidgetModal from "../../common/HeaderItems/WidgetModal/WidgetModal";
import InformationVidjetsPart from "../../common/HeaderItems/InformationWidgetsPart/InformationWidgetsPart";
import InputSearch from "../../common/HeaderItems/InputSearch/InputSearch";
import { Modal } from "../../common/Modal/Modal";

import { userWidgetsThunkAction } from "../../../redux/actions/User/profile/Widgets/Thunk/userWidgetsThunkAction";

import React, { useEffect, useState } from "react";

const styles = isTerminal ? stylesTerminal : stylesWeb;
// const photo = isTerminal ? photoTerminal : photoWeb;
const photo = photoTerminal;


const Header = (
    {
        setToggleState,
        toggleState,
    },
) => {

    const [modalShownWidgets, toggleModalWidgets] = useState(false);

    function toggleTab(index) {
        setToggleState(index);
    };

    function clickHandlerWidgets(e) {
        toggleModalWidgets(!modalShownWidgets);
    }

    function closeHandlerWidgets(e) {
        toggleModalWidgets(false);
    }

    // useEffect(()=>{
    //     userWidgetsThunkAction();
    // }, []);

    return (
        <div className={styles["header-container"]}>
            <InputSearch />
            {/*{!isTerminal && (<a*/}
            {/*    target="_blank"*/}
            {/*    href="https://kakdela.hh.ru/preview/poll/UICsEJo5RrAy9QkRbZvwu?iamtestlink=RAeyVc368l"*/}
            {/*    rel="noopener noreferrer">*/}
            {/*    <img*/}
            {/*        className={styles["image-header"]}*/}
            {/*        src={photo}*/}
            {/*        alt="" />*/}
            {/*</a>)}*/}
            <img
                className={styles["image-header"]}
                src={photo}
                alt="" />
            <InformationVidjetsPart
                clickHandlerWidgets={clickHandlerWidgets} />
            <NavMenu toggleTab={toggleTab} toggleState={toggleState} setToggleState={setToggleState} />

            <Modal
                shown={modalShownWidgets}
                close={closeHandlerWidgets}
            >
                <WidgetModal close={closeHandlerWidgets} />
            </Modal>
        </div>
    );
};


export default Header;

import { userLoginAction } from "../userLoginAction";
import { rawHeaders, urlOnUse } from "../../../../app/mode";

import axios from "axios";

export const userLoginThunkAction = (input, onError) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${urlOnUse()}v1.0/auth/signin`,
            // `https://${urlOnUse()}/api/v1.0/auth/signin`,
            input,
            { headers: rawHeaders },
        );

        switch (response.status) {
        case 200:
            localStorage.setItem("userId", response.data.userId);
            return dispatch(userLoginAction({userId: response.data.userId, phoneNumber:response.data.phoneNumber}));
        case 400:
            window.location.assign("/");
            return true;
        case 502:
            window.location.assign("/");
            return true;
        default:
            return null;
        }
    } catch (error) {
        if (error.response.status === 502 || error.response.status === 504) {
            return onError("Что-то пошло не так, повторите попытку позже");
        }

        return onError(error.response.data);
    }
};

import $api from "../../../../services/axiosService";
import RequestServices from "../../../../../frontend.services/RequestServices/RequestServices";
import { getUserRequestsThunkAction } from "../../GetUserRequests/thunk/getUserRequests.thunk.action";
import { setCurrentDocumentAction } from "../setCurrentDocument.action";

export const setCurrentRequestThunkAction = (uid, worker, props) => async (dispatch) => {
    try {
        const response = await $api(`v1/leaverequest/${uid}`);
        await $api.post(`v1/leaverequest/${uid}/review`);
        if (response.data.isSuccess) {
            const data = await RequestServices.getOneRequest(response.data.item, worker);
            const temp = { ...response.data.item };
            temp.approvers.map(el => {
                switch (el.status) {
                case 2:
                    el.statusText = "на согласовании";
                    return el;
                case 3:
                    el.statusText = new Date(el.assignmentDate).toLocaleDateString();
                    return el;
                case 4:
                    el.statusText = "отклонена";
                    return el;
                case 5:
                    el.statusText = "снято с согласования";
                    return el;
                default:
                    return el;
                }
            });
            await RequestServices.downloadAttachmentsfilesInCard(response.data.item);
            // const requestData = await RequestServices.downloadAttachmentsfilesInCard(response.data.item);
            dispatch(setCurrentDocumentAction({ currentRequestCard: data, currentRequest: response.data.item }));
            dispatch(getUserRequestsThunkAction());
        }
    } catch (e) {
        props.setDescription && props.setDescription("Не удалось загрузить информацию по заявке. Пожалуйста, повторите попытку позже.");
    }
};

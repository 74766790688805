import { $api } from "../../../../../../redux/services/request.config";

import Breadcrumbs from "../../../../../common/Breadcrumbs/Breadcrumbs";
import Footer from "../../../../../layout/Footer/Footer";
import Header from "../../../../../layout/Header/Header";

import { useEffect, useState } from "react";

import "./GuaranteeCompensationEmployee.scss";

const GuaranteeCompensationEmployee = () => {

    const [isDataAvailable, setIsDataAvailable] = useState([]);

    useEffect(() => {
        $api("v1/is/sewc/benefits").then(res => {
            setIsDataAvailable(res.data.data.table);
        }).catch(err => {
            // eslint-disable-next-line no-console
            console.log("ERROR: ", err);
        });
    }, []);


    return (
        <>
            <Header />
            <Breadcrumbs />
            <div className="guarantee-compenstation">
                <div className="sub-header">
                    гарантии и компенсации работнику
                </div>
                
                {
                    isDataAvailable.length 
                        ? <div className="guarantee-compenstation__table">
                            <div className="guarantee-compenstation__table__header">
                                <div className="guarantee-compenstation__table__header__first">
                                    виды гарантий и компенсаций
                                </div>
                                {/* <div className="guarantee-compenstation__table__header__second">
                                    фактическое наличие
                                </div>
                                <div className="guarantee-compenstation__table__header__third">
                                    необходимость в установлении (да/нет)
                                </div>
                                <div className="guarantee-compenstation__table__header__fourth">
                                    основание
                                </div> */}
                            </div>
                            <div className="guarantee-compenstation__table__body">
                                {
                                    isDataAvailable.map(guarantee => {
                                        // в бизнес требованиях указано что отображать значение
                                        // только в случае если value = "да"
                                        if (guarantee.value === "да") {
                                            return (
                                                <div 
                                                    key={guarantee.id} 
                                                    className="guarantee-compenstation__table__body__row">
                                                    <div className="guarantee-compenstation__table__body__row__first">
                                                        {guarantee.name}
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })
                                }
                                {/* <div className="guarantee-compenstation__table__body__row">
                                    <div className="guarantee-compenstation__table__body__row__first">
                                        Повышенная оплата труда работника
                                    </div>
                                    <div className="guarantee-compenstation__table__body__row__second">
                                        нет
                                    </div>
                                    <div className="guarantee-compenstation__table__body__row__third">
                                        нет
                                    </div>
                                    <div className="guarantee-compenstation__table__body__row__fourth">
                                        отсутствует
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        : <div className="guarantee-compenstation__lack-of-info">Информация отсутствует</div> 
                }
            </div>
            <Footer />
        </>
    );
};
 
export default GuaranteeCompensationEmployee;
import blankList from "../../../assets/icons/hr-services/ic_worktime.svg";
import calendar from "../../../assets/icons/hr-services/ic_reward.svg";
import deleteIcon from "../../../assets/icons/hr-services/ic_dismissal.svg";

export const routerTerminal = [
    {
        title: "Рабочее время",
        description: "График работы, отпуска, и другие данные о рабочем времени ",
        icon: blankList,
        path: "/main/hr-services/work-time",
    },
    // {
    //     title: "Вознаграждения",
    //     description: "Оклад, премии, бонусы и другие данные о вознаграждении",
    //     icon: calendar,
    //     path: "/main/hr-services/reward",
    // },
    {
        title: "Увольнение",
        description: "Оформление и документы для увольнения",
        icon: deleteIcon,
        path: "/main/hr-services/layoff",
    },
];

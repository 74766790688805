import { memo } from "react";

import "./RoundedAddItemButton.scss";

const RoundedAddItemButton = (
    {
        handler,
    }
) => {

    return <div
        className="rounded-add-item-button"
        onClick={() => handler ? handler() : null}>
    </div>;
};
 
export default memo(RoundedAddItemButton);
import { tableHeadYear, TableHeadYearMonths } from "./tableYear.data";

import TableHeadRow from "../../Table/TableHeadRow";
import TableHead from "../../Table/TableHead";
import Table from "../../Table/Table";
import "./HolidayTableYear.scss";
import TableBody from "../../Table/TableBody";
import TableRowYear from "../../Table/TableRowYear";

import {
    getGantRepresentationThunkAction,
} from "../../../../redux/actions/Employees/Gant/thunk/getGantRepresentation.thunk.action";
import Portal from "../../Portal/Portal";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const HolidaysTableYear = ({ tableStyle, section }) => {

    const style = "internal-table";
    const dispatch = useDispatch();

    const gantData = useSelector(state => state.employees.gant);
    const user = useSelector(state => state.user);

    const [tooltip, setTooltip] = useState(null);

    const mockDataCredits = gantData && gantData.map(e => {
        return {
            pid: e.pid,
            struct: e.struct,
            name: e.name,
            totalDays: e.totalDays,
        };
    });
    const mockDataVacations = gantData && gantData.map(e => {
        return {
            planned: e.leaves.map((el, i) => {
                return { ...el, pid: e.pid };
            }),
        };
    });

    useEffect(() => {
        if (section === "currentYear") {
            const from = moment().startOf("year").format("YYYY-MM-DD");
            const to = moment().endOf("year").format("YYYY-MM-DD");
            dispatch(getGantRepresentationThunkAction({ from, to }));
        }
        if (section === "lastYear") {
            const from = moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD");
            const to = moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD");
            dispatch(getGantRepresentationThunkAction({ from, to }));
        }
    }, [dispatch, section, user]);


    return (
        <>
            <div className="table-year-container">
                <Table tableStyle={tableStyle}>
                    <TableHead parentStyle={tableStyle}>
                        <TableHeadRow parentStyle={tableStyle} data={tableHeadYear} />
                    </TableHead>
                    <TableBody parentStyle={tableStyle}>
                        <TableRowYear parentStyle={tableStyle} data={mockDataCredits} />
                    </TableBody>
                </Table>
                <Table tableStyle={style}>
                    <TableHead parentStyle={style}>
                        <TableHeadRow parentStyle={style} data={TableHeadYearMonths()} />
                    </TableHead>
                    <tbody
                        className={"internal-table__body"}>
                        <TableRowYear
                            parentStyle={style}
                            data={mockDataVacations}
                            setTooltip={setTooltip} />
                    </tbody>
                </Table>
            </div>
            {
                tooltip &&
                <Portal>
                    {tooltip}
                </Portal>
            }
        </>
    );
};

export default HolidaysTableYear;

import apple from "../../../assets/footer/apple.svg";
import ruStore from "../../../assets/footer/ruStore.png";

export const footerBadges = [
    {
        id: "badge-1",
        title: "RuStore",
        head: "доступно в",
        img: ruStore,
        link: "https://apps.rustore.ru/app/ru.alrosa.dp",
    },
    // {
    //     id: "badge-2",
    //     title: "App Store",
    //     head: "загрузите в",
    //     img: apple,
    //     link: "https://apps.apple.com/app/id1579026870",
    // },
];

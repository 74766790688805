import ModalSupport from "../../common/Auth/ModalSupport/ModalSupport";
import {Modal} from "../../common/Modal/Modal";
import {userRegisterThunkAction} from "../../../redux/actions/Auth/Thunk/userRegisterThunkAction";
import RegForm from "../../common/Auth/RegForm/RegForm";
import { isTerminal } from "../../../constants";

import {useNavigate} from "react-router-dom";

import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import "../LoginPage/LoginPage.scss";
import "./RegPageTerminal.scss";

const RegisterPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [input, setInput] = useState({socNumber:"",password:"",confirmPassword:""});


    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShownRepeat, setPasswordShownRepeat] = useState(false);

    // const [modalShownSudgestions, toggleModalSudgestions] = useState(false);//modalSudgestens
    const [modalShownSupport, toggleModalSupport] = useState(false);//modalSupport






    function clickHandlerSupport(e) {
        toggleModalSupport(!modalShownSupport);
    }
    function closeHandlerSupport(e){
        toggleModalSupport(false);
    }



    return (

        <div className={isTerminal ? "login-container-terminal" : "login-container"}>
            <div className={isTerminal ? "login-wrapper-terminal" : "login-wrapper"}>
                <RegForm clickHandlerSupport={clickHandlerSupport}

                />
            </div>
            { !isTerminal && <div className={"login-img-auth"}></div> }
            { isTerminal && <img style={{height: "100vh"}} src={require("../../../assets/authPhoto/photo.png")} alt="" /> }
            <Modal
                showCloseButton={!isTerminal}
                shown={modalShownSupport}
                close={closeHandlerSupport}
            >
                <ModalSupport close = {closeHandlerSupport}/>
            </Modal>
        </div>

    );
};

export default RegisterPage;
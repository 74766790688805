import { useState } from "react";

export const useDebounce = (timeout = 100) => {
    const [timerId, setTimerId] = useState(null);

    return (cb) => {
        if (timerId) clearTimeout(timerId);
        const t = setTimeout(() => {
            if (typeof cb == "function") cb();
        }, timeout);
        setTimerId(t);
    };
};
import {
    headData,
} from "./tableCross.data";

import TableHead from "../../Table/TableHead";
import Table from "../../Table/Table";
import HolidayTableController from "../../Controllers/HolidayTableController/HolidayTableController";
import HelperService from "../../../../frontend.services/Helper.service";
import DateService from "../../../../frontend.services/Date.service";
import TableService from "../../../../frontend.services/TableServices/Table.service";

import TableCrossMonthHelper from "../../../../frontend.services/TableServices/TableCrossMonth.helper";
import { setRequestCheckBoxAction } from "../../../../redux/actions/Employees/setRequestCheckBox.action";
import { setAllRequestCheckBoxAction } from "../../../../redux/actions/Employees/setAllRequestCheckBox.action";
import BackButton from "../../Button/BackButton/BackButton";
import HolidayButtonsController from "../../Button/HolidayButtonsController/HolidayButtonsController";
import { approveRequestsThunkAction } from "../../../../redux/actions/Employees/thunk/approveRequests.thunk.action";
import ModalBanOverlay from "../../Modal/ModalBanOverlay/ModalBanOverlay";
import AcceptVacation from "../../Modal/HolidayTable/AcceptVacation/AcceptVacation";
import HolidaysApplications from "../HolidaysApplications/HolidaysApplications";

import HolidaysEmployeesLeaves from "../HolidaysEmployeesLeaves/HolidaysEmployeesLeaves";

import {
    getEmployeesWithApplicationsThunkAction,
} from "../../../../redux/actions/Employees/LeavesCrossings/thunk/getEmployeesWithApplications.thunk.action";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useEffect, useState } from "react";


const HolidaysTableCross = ({ tableStyle }) => {

    const dispatch = useDispatch();

    const userPendingApplications = useSelector(state => state.employees.pendingApplications);
    const userPendingApplicationsRedux = useSelector(state => state.employees.pendingApplications?.applications);
    const selectedPids = useSelector(state => state.employees.selectedPids);
    const user = useSelector(state => state.user);

    const currentPeriod = HelperService.capitalizeSentence(DateService.getCurrentDateFormat(new Date(), "MMMM YYYY"));

    const [modal, setModal] = useState({
        applicationsUID: null,
        modal: false,
        items: [],
        loader: false,
    });

    const [period, setPeriod] = useState({
        displayPeriod: currentPeriod,
        dateFormat: moment().toDate(),
    });

    const startOfMonth = moment(period.dateFormat).startOf("month").format("YYYY-MM-DD");
    const endOfMonth = moment(period.dateFormat).endOf("month").format("YYYY-MM-DD");

    function closeModal() {
        setModal((prev) => {
            return {
                ...prev,
                modal: false,
            };
        });
    }

    function selectClickHandler(event) {
        dispatch(setRequestCheckBoxAction({ uid: event.target.id, checked: !event.target.checked }));
    }

    function multipleSelection(event) {
        dispatch(setAllRequestCheckBoxAction(event.target.checked));
    }

    function declineHandler(e) {
        // TODO REFACTOR
        const requests = [];
        document.getElementsByName("checkbox").forEach(e => {
            if (e.checked === true) {
                requests.push(e.id);
            }
        });
        const arrayOfRequests = requests.map(element => {
            return userPendingApplicationsRedux.find(reduxEl => reduxEl.uid === element);
        });
        setModal(prev => {
            return {
                applicationsUID: null,
                modal: "decline-comment",
                items: arrayOfRequests,
                loader: false,
            };
        });
    }

    function acceptHandler(e) {
        // TODO REFACTOR
        const requests = [];
        document.getElementsByName("checkbox").forEach(e => {
            if (e.checked === true) {
                requests.push(e.id);
            }
        });
        const arrayOfRequests = requests.map(element => {
            return userPendingApplicationsRedux.find(reduxEl => reduxEl.uid === element);
        });
        setModal(prev => {
            return {
                applicationsUID: null,
                items: arrayOfRequests,
                modal: "accept",
                loader: false,
            };
        });
        dispatch(approveRequestsThunkAction(arrayOfRequests, setModal));
    }

    useEffect(() => {
        dispatch(getEmployeesWithApplicationsThunkAction({
            from: startOfMonth,
            to: endOfMonth,
            subordinatePids: selectedPids ? selectedPids : [],
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period, user]);


    return (
        <>
            {/*CROSS MONTH*/}
            <HolidayTableController
                parentStyle={"cross-table-controller"}
                period={period}
                setPeriod={setPeriod} />
            {/*hp-table-cross*/}
            <Table tableStyle={tableStyle}>
                <TableHead parentStyle={tableStyle}>
                    {
                        TableCrossMonthHelper
                            .getHeaderDatesRow(
                                headData,
                                TableService.getMonthDaysHeaderMod(period.dateFormat),
                                tableStyle, { startOfMonth, endOfMonth },
                            )
                    }
                </TableHead>
            </Table>
            {/*ACCEPT*/}
            {/*SHOWS ONLY WHEN THERE ARE REQUESTS*/}
            {userPendingApplications
            && userPendingApplications.applications
            && userPendingApplications.applications.length
                ?
                <>
                    <HolidaysApplications
                        setModal={setModal}
                        selectClickHandler={selectClickHandler}
                        multipleSelection={multipleSelection} />
                </>
                : null
            }
            <HolidaysEmployeesLeaves />

            {/*BUTTONS*/}
            <div className="hp-table-week__button-container">
                <BackButton route={"/divisions/work-time/"} />
                <HolidayButtonsController
                    acceptHandler={acceptHandler}
                    declineHandler={declineHandler} />
            </div>

            {/*MODALS*/}
            <ModalBanOverlay shown={!!modal.modal} close={closeModal}>
                <AcceptVacation
                    modal={modal}
                    setModal={setModal}
                    period={period}
                />
            </ModalBanOverlay>
        </>
    );
};

export default HolidaysTableCross;

class LoginFormValidationService {

    static LENGTH = { MAX:"LENGTH_MAX", MIN:"LENGTH_MIN", EQ: "LENGTH_EQ"};
    static REQUIRED = { TRUE: "REQUIRED_TRUE", FALSE:"REQUIRED_FALSE"};
    static TYPE = { EMAIL: "TYPE_EMAIL",PHONE: "TYPE_PHONE", SNILS: "TYPE_SNILS"};


    static validate(value, rules = []){

        const errors = [];

        rules.forEach((r)=>{

            const {rule = null, param = null} = r;

            if (rule === this.LENGTH.MAX && value.length > parseInt(param))
                errors.push({
                    type: this.LENGTH.MAX,
                    param:param,
                    length:value.length,
                });


            if (rule === this.LENGTH.MIN && value.length < parseInt(param))
                errors.push({
                    type: this.LENGTH.MIN,
                    param:param,
                    length:value.length,
                });

            if (rule === this.LENGTH.EQ && value.length !== parseInt(param))
                errors.push({
                    type: this.LENGTH.EQ,
                    param:param,
                    length:value.length,
                });


            if (rule === this.REQUIRED.TRUE && value.length < 1)
                errors.push({
                    type: this.REQUIRED.TRUE,
                    param:param,
                    length:value.length,
                });


            if (rule === this.TYPE.EMAIL && !value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g))
                errors.push({
                    type: this.TYPE.EMAIL,
                    param:param,
                    length:value.length,
                });


            if (rule === this.TYPE.SNILS && !value.match(/^\d{3}-?\d{3}-?\d{3}-? ?\d{2}$/gm))
                errors.push({
                    type: this.TYPE.SNILS,
                    param:param,
                    length:value.length,
                });




        });



        return errors.length > 0 ? errors : true;
    }

}

export default LoginFormValidationService;
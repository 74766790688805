import Employe from "./Employe/Employe";
import RWDLockPage from "./RWDLockPage";
import { requestData } from "./requestData.mock";

import ModalSuccess from "./ModalSuccess/ModalSuccess";

import StageValidationHOC from "../../../../../../hocs/StageValidation.HOC";
import { mode } from "../../../../../../app/mode";
import { isTerminal } from "../../../../../../constants";
import { $api } from "../../../../../../redux/services/request.config";

import RequestInput from "../../../../../common/Inputs/RequestInput/RequestInput";

import {
    getSubordinatesThunkAction,
} from "../../../../../../redux/actions/Requests/RequestsFromLeader/RecruitmentToWork/thunk/getSubordinates.thunk.action";
import { Modal } from "../../../../../common/Modal/Modal";
import BackHandlerModal from "../../../../../common/Modal/ModalConditions/Requests/BackHandlerModal/BackHandlerModal";
import SaveSuccessModal from "../../../../../common/Modal/ModalConditions/Requests/SaveSuccessModal/SaveSuccessModal";
import ModalBanOverlay from "../../../../../common/Modal/ModalBanOverlay/ModalBanOverlay";
import Error500Modal from "../../../../../common/Modal/ModalConditions/Requests/Error500Modal/Error500Modal";

import UButton from "../../../../../common/Button/UButton/UButton";

import {
    emptyCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/emptyCurrentRequest";


import RecruitmentToWorkOnHolidaysApplication
    from "../../../../../../frontend.classes/Requests/nonBasic/RecruitmentToWorkOnHolidays.application";
import RequestConfirmation
    from "../../../../../common/Modal/ModalConditions/Requests/RequestConfirmation/RequestConfirmation";

import {
    getCompensationTypesThunkAction,
} from "../../../../../../redux/actions/Requests/RequestsFromLeader/RecruitmentToWork/thunk/getCompensationTypes.thunk.action";
import {

    addBasicInputHandlerCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/addBasicInputHandler.currentRequest";

import {
    validationControlCurrentRequest,
} from "../../../../../../redux/actions/Requests/currentRequestControls/validationControl.currentRequest";

import {
    setCurrentRequestAction,
} from "../../../../../../redux/actions/Requests/CurrentRequest/setCurrentRequest.action";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import "./RecruitmentToWorkOnHolidays.scss";


const TestHOCComponent = ({ props, back, next, newTuple }) => {
    return (
        <ModalBanOverlay shown={true}>
            <div style={{
                top: "calc(100dvh - 80%)",
                left: "calc(100dvw - 80%)",
                position: "fixed",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                height: "30vh",
                background: "lavender",
            }}>
                <h1 onClick={() => {
                    console.log("NEW TUPLE: ", newTuple);
                }}>
                    testHOCComponent {props.name}
                </h1>
                <button onClick={() => next(newTuple)}>next</button>
                <button onClick={back}>back</button>
            </div>

        </ModalBanOverlay>
    );
};


const RecruitmentToWorkOnHolidays = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const subordinates = useSelector(state => state.requests.subordinates);
    const currentRequest = useSelector(state => state.requests.currentRequest);


    const [notificationWindow, setNotificationWindow] = useState(false);
    const [arrayOfDates, setArrayOfDates] = useState([]);
    const [modalWindowKP, setModalWindowKP] = useState(null);
    const [sendButtonLoading, setSendButtonLoading] = useState(false);
    const [onValidation, setOnValidation] = useState(false);

    // NOTIFICATION IN CONTROL PROCEDURES CONTENT
    const [requestStatus, setRequestStatus] = useState(false);
    const [modalStatus, setModalStatus] = useState(false);
    const [modalSuccessStatus, setModalSuccessStatus] = useState(false);
    const [modalSentStatus, setModalSentStatus] = useState(false);
    const [modalError, setModalError] = useState({
        sign: false,
        validation: false,
        status: false,
    });


    const [isRunningSaveHandler, setIsRunningSaveHandler] = useState(false);
    const [isRunningSendHandler, setIsRunningSendHandler] = useState(false);

    const [stageModalWindow, setStageModalWindow] = useState(false);

    function inputHandler(e, field) {
        const value = e.target.value;
        dispatch(addBasicInputHandlerCurrentRequest({ field, value }));
        dispatch(validationControlCurrentRequest(field, true));
    }


    function backHandler(e) {
        navigate("/main/hr-services/leader-requests");
    }


    // const actions = {
    //     back: () => {
    //         console.log("BACK");
    //     },
    //     //TODO add new tuple to jsDocs
    //     next: (newTuple) => {
    //         console.log("NEXT", newTuple);
    //         newTuple[0][0].props.name = "NEW HOC";
    //         setStageModalWindow(StageValidationHOC(newTuple));
    //     },
    //     props: { name: "testHOC" },
    //     // newTuple: null,
    // };
    const confirmAction = {
        back: () => {
            setStageModalWindow(null);
        },
        next: async (newTuple, props) => {
            try {
                const response = await $api.post("/v1/managers/requests/rvd/applications", currentRequest);
                if (response.data) {
                    props.setOnValidation(false);
                    newTuple[0][0].props.responseData = response.data;
                    setStageModalWindow(StageValidationHOC(newTuple));
                }
            } catch (e) {
                setStageModalWindow(StageValidationHOC(newTuple));
            }
        },
        props: {
            setRequestStatus: setRequestStatus,
            stateLoader: setSendButtonLoading,
            onValidation: onValidation,
            setOnValidation: setOnValidation,
            setModalError: setModalError,
            sendButtonLoading: sendButtonLoading,
            setModalSentStatus: setModalSentStatus,
        },
    };
    // createdAtUtc: "2024-04-25T09:10:01"
    // number: "103-2/2024"
    // pid: 150431
    // reason: "jk.klj"
    // stateId: 1
    // stateName: "Ожидает обработки"
    // uid: "a8c69165-89ef-4bc3-a541-c954e50130a5"
    const actionsOK = {
        back: () => {
            console.log("BACK");
        },
        next: (newTuple) => {
            console.log("NEXT", newTuple);

            // newTuple[1][0].props.name = "NEW HOC";
            // setStageModalWindow(StageValidationHOC(newTuple));
        },
        props: {
            name: "testHOC",
            currentRequest: currentRequest,
        },
        // newTuple: null,
    };

    const testHOC = StageValidationHOC([
        [confirmAction, RequestConfirmation],
        [actionsOK, ModalSuccess],

    ]);

    async function requestStageValidationHandler() {
        // setOnValidation(true);
        setIsRunningSendHandler(true);
        const { data } = await $api.post("/v1/managers/requests/rvd/validations", requestData);
        // setOnValidation(false);
        setIsRunningSendHandler(false);

        setStageModalWindow(testHOC);
        // const component = RequestValidationService.stageValidation(data);
        // setModalWindowKP(component);
    }

    function submitHandler() {
        const result = false;
        setModalSentStatus(true);
    }

    useEffect(() => {
        dispatch(getSubordinatesThunkAction());
        dispatch(getCompensationTypesThunkAction());
        setRequestStatus(false);
        dispatch(
            emptyCurrentRequest(
                new RecruitmentToWorkOnHolidaysApplication({
                    leaveTypeId: 17,
                    reason: "",
                    employees: [
                        {
                            pid: null,
                            days: [
                                // {
                                //     jobDate: null, // "2024-05-01",
                                //     jobHours: null, // 8.5,
                                //     compensationType: null, // 2,
                                //     restDate: null, // "2024-05-04"
                                // },
                            ],
                        },
                    ],

                }),
            ),
        );
    }, []);

    useEffect(() => {
        if (requestStatus && !requestStatus.isSuccess) {
            setNotificationWindow(true);

        }
    }, [requestStatus]);

    console.log("setOnValidation: ", onValidation);

    return (
        <>
            {
                mode === "PROD"
                    ? <RWDLockPage />
                    : (
                        <div className={"recruitment-to-work"}>
                            <div className="sub-header" style={{ width: "767px", margin: "0" }}>
                                Привлечение к работе в выходные и праздничные дни
                            </div>
                            <RequestInput
                                value={currentRequest && currentRequest.reason}
                                basicInput={true}
                                maxLength={400}
                                stateProperty={"reason"}
                                changeHandler={(e) => inputHandler(e, "reason")}
                                label={"Расшифровка причины привлечения к работе в выходные, праздничные дни *"}
                                letter={400}
                                parentStyle={"unpaid-practice"}
                                placeholder={"В связи с [         ] прошу привлечь" +
                                    " к работе в выходной, праздничный день следующих работников:"}

                            />

                            {
                                currentRequest &&
                                currentRequest.employees &&
                                currentRequest.employees.map((employee, index) => {
                                    return (
                                        <Employe
                                            parentStyle={"recruitment-to-work"}
                                            employee={employee}
                                            index={index}
                                            key={index}
                                            employeesLength={currentRequest.employees.length}
                                        />
                                    );
                                })
                            }

                            {/* MOCK MODAL START */}
                            {/*<button onClick={requestStageValidationHandler}>*/}
                            {/*    validate*/}
                            {/*</button>*/}
                            {
                                modalWindowKP
                            }
                            {stageModalWindow}
                            {/* MOCK MODAL END */}
                            <div className="wt-request-buttons">
                                <UButton
                                    onClick={backHandler}
                                    className="wt-request-buttons__default">
                                    {!isTerminal && "назад"}
                                    {isTerminal && "Назад"}
                                </UButton>
                                <div className="wt-request-buttons__two-buttons">
                                    {
                                        // !props.references &&
                                        <UButton
                                            disabled={true}
                                            // onClick={props.saveHandler}
                                            className="wt-request-buttons__default"
                                            loading={isRunningSaveHandler}
                                        >
                                            {!isTerminal && "cохранить"}
                                            {isTerminal && "Cохранить"}
                                        </UButton>
                                    }

                                    <UButton
                                        // disabled={true}
                                        onClick={requestStageValidationHandler}
                                        className="wt-request-buttons__default"
                                        loading={isRunningSendHandler}
                                    >
                                        {!isTerminal && "направить заявку"}
                                        {isTerminal && "Направить заявку"}
                                    </UButton>
                                </div>
                            </div>
                            <Modal shown={modalStatus} close={setModalStatus}>
                                <BackHandlerModal
                                    // handler={saveHandler}
                                    close={setModalStatus}
                                />
                            </Modal>
                            {/*SAVE SUCCESS*/}
                            <Modal shown={modalSuccessStatus} close={setModalSuccessStatus}>
                                <SaveSuccessModal
                                    route={"/main/hr-services"}
                                    close={setModalSuccessStatus}
                                />
                            </Modal>
                            {/*REQUEST SEND*/}
                            <ModalBanOverlay shown={modalSentStatus} close={setModalSentStatus}>
                                <RequestConfirmation
                                    setRequestStatus={setRequestStatus}
                                    stateLoader={setSendButtonLoading}
                                    onValidation={onValidation}
                                    setOnValidation={setOnValidation}
                                    setModalError={setModalError}
                                    sendButtonLoading={sendButtonLoading}
                                    route={"/main/hr-services/"}
                                    back={setModalSentStatus}

                                />
                            </ModalBanOverlay>
                            {modalError.status && (
                                <ModalBanOverlay shown={modalError} close={setModalError}>
                                    <Error500Modal
                                        modalError={modalError}
                                        setModalError={setModalError}
                                    />
                                </ModalBanOverlay>
                            )}
                        </div>)
            }
        </>
    );
};

export default RecruitmentToWorkOnHolidays;

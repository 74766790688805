export const GET_STRUCT_TREE = "GET_STRUCT_TREE";
export const CLEAR_STRUCT_TREE = "CLEAR_STRUCT_TREE";
export const GET_STRUCT_TOP = "GET_STRUCT_TOP";
export const CLEAR_STRUCT_TOP = "CLEAR_STRUCT_TOP";
export const GET_FUNCTION_MARKERS = "GET_FUNCTION_MARKERS";
export const CLEAR_FUNCTION_MARKERS = "CLEAR_FUNCTION_MARKERS";
export const SET_SELECTED_STRUCT_ITEM = "SET_SELECTED_STRUCT_ITEM";
export const CLEAR_SELECTED_STRUCT_ITEM = "CLEAR_SELECTED_STRUCT_ITEM";
export const GET_EMPLOYEE_BY_STRUCT_ID = "GET_EMPLOYEE_BY_STRUCT_ID";
export const CLEAR_EMPLOYEE_BY_STRUCT_ID = "CLEAR_EMPLOYEE_BY_STRUCT_ID";
export const SELECT_DROPDOWN_MENU_ITEM = "SELECT_DROPDOWN_MENU_ITEM";
export const EDIT_STRUCTURE_ITEM = "EDIT_STRUCTURE_ITEM";
export const GET_SUBORDINATES = "GET_SUBORDINATES";

import React from "react";

export const LoginSnilsMaskInput = ({onError, valid, changedSnils,  setToValidLabelAndInput, inputSnils, handleChangeSnils}) => {
    return (
        <input
            className={
                (!valid &&
                    "login__wrapper__input-invalid") ||
                (onError &&
                    "login__wrapper__input-invalid") ||
                "login__wrapper__input"
            }
            value={changedSnils}


            name={"socNumber"}
            onClick={setToValidLabelAndInput}
            type={"tel"}
            ref={inputSnils}
            onChange={handleChangeSnils}
        />
    );
};
import UButton from "../../../../Button/UButton/UButton";
import PhoneVerify from "../../../../Auth/PhoneVerify/PhoneVerify";
import {
    approveCertificateBySmsThunkAction,
} from "../../../../../../redux/actions/User/profile/ElectronicSignature/thunk/approveCertificateBySms.thunk.action";
import { isTerminal } from "../../../../../../constants";

import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

isTerminal ? import("./VerifyingIdentityTerminal.scss") : import("./VerifyingIdentity.scss");


function VerifyingIdentity(props) {
    console.log(props);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [input, setInput] = useState("");
    const [counter, setCounter] = useState(60);
    const [active, setActive] = useState(false);
    const [authNumber, setAuthNumber] = useState("");
    const submitHandler = (e) => {
        e.preventDefault();
        if (input.length === 6) {

            dispatch(approveCertificateBySmsThunkAction(input, props.issueId, {
                setLoader: props.setLoader,
                setDescription: props.setDescription,
                setPhoneToggle: props.setPhoneToggle,
                setAuthNumber: setAuthNumber,
            }));
        }
    };

    return (
        <div className="verification-modal">
            {props.loader ?

                <div className={"verification-modal__loader"}>
                    <ClipLoader color="#6DCDF6" />
                </div>
                : props.description ?
                    <div className={"verification-modal__loader"}>
                        <span className={"verification-modal__loader__span"}>
                            {props.description}
                        </span>
                        <UButton onClick={() => navigate("/profile/eds-release/unep")}>закрыть</UButton>
                    </div>
                    :
                    <>
                        {props.phoneToogle ?
                            <div className="verification-modal__phone-form">
                                <PhoneVerify
                                    input={input}
                                    setInput={setInput}
                                    submitHandler={submitHandler}
                                    counter={counter}
                                    setCounter={setCounter}
                                    active={active}
                                    setActive={setActive}
                                    styleParent={"verification-modal__phone-form"}
                                    unep={true}
                                    authNumber={authNumber}
                                    approveIdentityWith={props.approveIdentityWith}
                                    setToNullAuthNumber={setAuthNumber}
                                    phone={props.phoneToogle} />
                            </div>
                            :
                            <>
                                <div className={"verification-modal__header"}>
                                    подтверждение личности
                                </div>
                                {
                                    props.isForeigner === false &&
                                    <UButton
                                        onClick={() => props.approveIdentityWith(1)}
                                        className={"verification-modal__button"}
                                    >подтвердить через госуслуги</UButton>

                                }
                                <div
                                    style={props.isForeigner ? { fontSize: "21px", marginBottom: "40px" } : null}
                                    onClick={() => props.approveIdentityWith(2)}
                                    className={"verification-modal__phone"}>
                                    Подтвердить через СМС сообщение
                                </div>
                            </>
                        }
                    </>

            }
        </div>
    );
}

export default VerifyingIdentity;

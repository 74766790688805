import { isTerminal } from "../../../constants";

import { ClipLoader } from "react-spinners";
import React from "react";

const CustomLoader = () => {
    return (
        <div
            style={{
                width: isTerminal ? "1080px" : "1160px",
                margin: "20vh auto auto auto",
                textAlign: "center",
                minHeight: "20vh",
            }}
            className={isTerminal && "docs-main-wrapper"}
        >
            <ClipLoader color="#6DCDF6" />
        </div>
    );
};
export default CustomLoader;

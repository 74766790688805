export const resetErrorLoginMessageAction = () => {
    return{
        type:"RESET_ERROR_LOGIN",
        payload:null,
    };
};
export const resetErrorMessageAction = () => {
    return{
        type:"RESET_ERROR",
        payload:null,
    };
};

// SUCCESS WRONG_VERIFICATION WRONG_REGISTRATION

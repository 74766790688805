import {
    getRelativesInformationThunkAction,
} from "../../../../../redux/actions/User/profile/GeneralData/thunk/getRelativesInformationThunkAction";

import BasicLine from "../../../../common/BasicLine/BasicLine";

import CustomLoader from "../../../../common/CustomLoader/CustomLoader";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";

const Relatives = () => {
    const dispatch = useDispatch();

    const relatives = useSelector(state => state.personalData.relatives);
    const [infoRelatives, setInfoRelatives] = useState(relatives);

    useEffect(() => {
        !relatives && dispatch(getRelativesInformationThunkAction());
        // TODO REFACTOR
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setInfoRelatives(relatives);
    }, [relatives]);


    if (!relatives) return (
        <CustomLoader />
    );

    return (
        <>
            {relatives ?
                <div className="fio">
                    {
                        infoRelatives && infoRelatives.map((el, index) => {
                            return (
                                <BasicLine
                                    key={index}
                                    type={el.type}
                                    description={el.description}
                                    styleParent={"fio"}
                                />
                            );
                        })
                    }

                </div>
                :
                <h1>Информация о родственниках отсутствует</h1>
            }
            <br />
        </>
    );
};

export default Relatives;
